<template>
  <v-card>
    <div
      class="title-import"
    >
      <div></div>
      <v-card-title
        primary-title
        class=""
        style=" margin-left: 6em;"
      >
        Nova importação
      </v-card-title>
      <v-icon
        size="30"
        style=" margin-left: 3em;"
        @click="closeModal(), resetForm()"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </div>
    <v-card-text>
      <separator label="Adicionar arquivo"></separator>
      <v-form
        ref="newRegisterForm"
      >
        <v-file-input
          v-model="file"
          :rules="[rules.required]"
          dense
          show-size
          multiple
          class="mt-7"
          outlined
          label="Anexo"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="error"
        :disabled="isLoading"
        @click="closeModal(), resetForm()"
      >
        Cancelar
      </v-btn>

      <v-btn
        color="info"
        :disabled="isLoading"
        @click="upload"
      >
        <v-icon
          class="mr-2"
        >
          {{ icons.mdiCloudUpload }}
        </v-icon>

        <span v-if="!isLoading">Importar</span>

        <v-progress-circular
          v-else
          size="30"
          color="info"
          indeterminate
        >
        </v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mdiClose,
  mdiCloudUpload,
} from '@mdi/js'

import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import Separator from '../../../../components/Separator.vue'

export default {
  name: 'ModalImport',
  components: {
    Separator,
  },
  mixins: [messages, formatters],
  data() {
    return {
      file: [],
      icons: {
        mdiClose,
        mdiCloudUpload,
      },
      isLoading: false,
    }
  },
  methods: {
    async upload() {
      this.isLoading = true
      const formData = new FormData()
      const fileToArray = []
      fileToArray.push(this.file)

      this.file.forEach(async file => {
        await formData.append('files[]', file)
      })

      await axiosIns
        .post('/api/v1/integration/cnh_parameter_goal/import_vocation', formData)
        .then(async () => {
          this.closeModal()
          await this.$swal({
            icon: 'success',
            title: 'Arquivo integradeo!',
            text: 'O arquivo foi integrado com sucesso!',
            showConfirmButton: false,
            timer: 2000,
          })
        })
        .catch(async () => {
          this.isLoading = false
          await this.$swal({
            icon: 'error',
            title: 'ERRO!',
            text: 'Ocorreu um erro durante a integração do arquivo! Tente novamente.',
          })
        })
        .finally(() => {
          this.isLoading = false
          this.updatedTable()
        })
    },
    closeModal() {
      this.$emit('close')
    },
    clearModal() {
      this.file = ''
      this.resetForm()
      this.closeModal()
    },

    resetForm() {
      this.$refs.newRegisterForm.reset()
    },
  },
}
</script>
<style scoped>
  .title-import{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }
</style>
