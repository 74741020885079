<template>
  <div
    class="separator"
    :class="{ 'dark-mode': isDark, 'light-mode': !isDark, 'with-label': label }"
    :style="separatorStyle"
  >
    <div
      v-if="label"
      class="label"
      :style="labelStyle"
    >
      <h4 style="font-size: 15px; font-weight: 600;">
        {{ label }}
      </h4>
    </div>
  </div>
</template>

<script>
import { getVuetify } from '@/@core/utils'

export default {
  name: 'Separator',
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    lightBackgroundColor: {
      type: String,
      required: false,
      default: null, // Cor de fundo personalizada para o modo claro
    },
    darkBackgroundColor: {
      type: String,
      required: false,
      default: null, // Cor de fundo personalizada para o modo escuro
    },
  },
  computed: {
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
    separatorStyle() {
      return {
        backgroundColor: this.isDark
          ? this.darkBackgroundColor || '#312d4b'
          : this.lightBackgroundColor || '#FFF',
      }
    },
    labelStyle() {
      return {
        backgroundColor: this.isDark
          ? this.darkBackgroundColor || '#312d4b'
          : this.lightBackgroundColor || '#FFF',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.separator {
  position: relative;
  border-top: 1px solid #cac9cd96;

  &.with-label {
    margin-top: 20px;
  }
}

.separator .label {
  top: -19px;
  left: 20px;
  position: absolute;
  z-index: 100;
  padding: 8px;
}
.dark-mode {
  color: #fff;
}
.light-mode {
  color: #736c7d;
}
</style>
