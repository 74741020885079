<template>
  <v-card>
    <v-snackbar
      v-model="isRoundedSnackbarVisible"
      top
      :light="$vuetify.theme.dark"
      color="#312d4b"
      width="800px"
    >
      <div class="title-snackbar">
        <v-icon
          medium
          class="me-2"
          color="info"
        >
          {{ icons.mdiFileDocumentEditOutline }}
        </v-icon>

        <span>PROPOSTA ANTERIOR COM COMISSÃO</span>
      </div>

      <div class="container-infos-snackbar">
        <div class="container-one-snackbar">
          <div class="d-flex line-snackbar">
            <span class="title-line-snackbar">PROPOSTA: </span>
            <p>{{ itensToClickStatus.proposal_old ? itensToClickStatus.proposal_old.proposal_number : '' }}</p>
          </div>
          <div class="d-flex line-snackbar">
            <span class="title-line-snackbar">MODELO: </span>
            <p>{{ itensToClickStatus.proposal_old ? itensToClickStatus.proposal_old.vehicle_model : '' }}</p>
          </div>
          <div class="d-flex line-snackbar">
            <span class="title-line-snackbar">VALOR DA VENDA: </span>
            <p>{{ itensToClickStatus.proposal_old ? Number(itensToClickStatus.proposal_old.vehicle_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }): 'R$ 0,00 ' }}</p>
          </div>

          <div class="d-flex line-snackbar">
            <span class="title-line-snackbar">MOTIVO: </span>
            <p>{{ itensToClickStatus.proposal_old ? itensToClickStatus.description_reversal : '' }}</p>
          </div>
        </div>

        <div class="container-one-snackbar">
          <div class="d-flex line-snackbar">
            <span class="title-line-snackbar">PERCENTUAL: </span>
            <p>{{ itensToClickStatus.percentage }}</p>
          </div>
          <div class="d-flex line-snackbar">
            <span class="title-line-snackbar">R$ COMISSÃO: </span>
            <p class="old-commission">
              {{ itensToClickStatus.proposal_old ? Number(itensToClickStatus.proposal_old.commission_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }}
            </p>
          </div>
          <div class="d-flex line-snackbar">
            <span class="title-line-snackbar">STATUS: </span>
            <v-chip
              text
              small
              :class="`v-chip-light-bg ${returnColorStatus(itensToClickStatus.proposal_old ? itensToClickStatus.proposal_old: '')}--text font-weight-semibold`"
            >
              {{ itensToClickStatus.proposal_old ? itensToClickStatus.proposal_old.contract_status: '' }}
            </v-chip>
          </div>
        </div>
      </div>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarInconsistency"
      location="top"
      :color="statusSales === 'INCONSISTENTE' ? 'error' : 'success'"
    >
      {{
        statusSales === 'INCONSISTENTE'
          ? 'Este item já possui um RI aberto. Direcione-se ao menu de RI´s'
          : 'Impossível, este item já foi verificado'
      }}
    </v-snackbar>

    <v-card-title class="justify-center mb-4">
      <v-menu
        offset-y
        location="bottom"
      >
        <template v-slot:activator="{ attrs, on }">
          <div
            outlined
            v-bind="attrs"
            class="container-options"
            v-on="on"
          >
            <v-icon
              size="22"
              class=""
            >
              {{ icons.mdiCogOutline }}
            </v-icon>

            <span class="text-md-subtitle-2">Opções</span>

            <v-img
              class="arrow"
              :width="12"
              src="@/assets/arrow-down-icon.svg"
            ></v-img>
          </div>
        </template>

        <v-list>
          <v-list-item
            v-for="item in listOptions"
            :key="item.value"
            :v-show="item.validation"
            link
          >
            <v-icon
              :color="item.color"
              small
              class="me-2"
            >
              {{ item.icons }}
            </v-icon>
            <v-list-item-title
              @click="item.onAction()"
              v-text="item.title"
            />
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer />

      <span>Relatório detalhado</span>
      <v-spacer />
      <v-icon
        @click="closeModal()"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </v-card-title>

    <div class="container-infos">
      <div>
        <div class="infos-vendor">
          <v-icon
            @click="closeModal()"
          >
            {{ icons.mdiAccountOutline }}
          </v-icon>

          <span>Consultor: {{ vendorName }}</span>

          <div
            v-show="vacationInfo"
            class="bedge-vacation"
          >
            Férias
          </div>
        </div>

        <div class="infos-vendor">
          <v-icon
            @click="closeModal()"
          >
            {{ icons.mdiFileCertificateOutline }}
          </v-icon>

          <span>Cotas: {{ numberSales }}</span>
        </div>
      </div>

      <div class="infos-values">
        <div class="badge-rounded">
          <v-icon color="warning">
            {{ icons.mdiAccountCash }}
          </v-icon>
        </div>

        <div class="infos-cash">
          <span>Total em Vendas</span>
          <span>{{ totalSales ? Number(totalSales).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00 ' }}</span>
        </div>

        <div class="badge-rounded">
          <v-icon color="info">
            {{ icons.mdiCurrencyUsd }}
          </v-icon>
        </div>

        <div class="infos-cash">
          <span>Total em comissão</span>
          <span>{{ commission ? Number(commission).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00' }}</span>
        </div>
      </div>
    </div>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="reports"
        disable-sort
      >
        <template #[`item.percentage`]="{ item }">
          {{ Number(item.percentage) }}
        </template>
        <template #[`item.sales`]="{ item }">
          {{
            item.sales ? Number(item.sales).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'
          }}
        </template>
        <template #[`item.proposal_number`]="{ item }">
          {{ item.proposal_number }} {{ item.proposal_digit }}
        </template>
        <template #[`item.contract_status`]="{ item }">
          <v-chip
            text
            small
            :class="[
              'v-chip-light-bg',
              returnColorStatus(item) + '--text',
              'font-weight-semibold',
              'mt-1',
              { 'status-with-reverse': item.contract_status === 'COM ESTORNO' }
            ]"
            @click="handleItemClick(item)"
          >
            {{ item.contract_status }}

            <v-icon
              v-show="item.contract_status === 'COM ESTORNO'"
              color="warning"
              size="15"
            >
              {{ icons.mdiAlertOutline }}
            </v-icon>
          </v-chip>
        </template>
        <template #[`item.commission`]="{ item }">
          {{
            item.commission
              ? Number(item.commission).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
              : 'R$ 0,00'
          }}
        </template>

        <template #[`item.delete`]="{ item }">
          <v-icon
            v-show="computedUserIsLeader"
            medium
            class="me-2"
            color="error"
            @click="showModalDelete(item.id)"
          >
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>

        <!--  eslint-disable-next-line vue/no-unused-vars -->
        <template v-slot:foot="{ items }">
          <tfoot class="footer">
            <th class="contracts">
              <h3>
                -
              </h3>
            </th>
            <th>-</th>
            <th>-</th>
            <th>-</th>
            <th class="">
              <h4>
                -
              </h4>
            </th>
            <th>-</th>

            <th class="">
              <h4>
                -
              </h4>
            </th>
            <th v-show="computedUserIsLeader">
              -
            </th>
          </tfoot>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog
      v-model="openModal"
      max-width="600px"
      persistent
    >
      <modal-inconsistencies
        :ids-sales="reports"
        :updated-table="updatedTable"
        @close="openModal = false"
      />
    </v-dialog>

    <v-dialog
      :key="itemId"
      v-model="openModalDelete"
      width="600px"
    >
      <v-card>
        <v-card-title class="d-flex justify-center mb-4">
          <span>Excluir contrato</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <h4 class="ml-2 mb-2">
              Descrição:
            </h4>
            <v-textarea
              v-model="descriptionForDeleteContract"
              :rules="[rules.required]"
              label="Descreva o motivo para exclusão o contrato"
              outlined
              dense
            ></v-textarea>
          </v-form>

          <div class="d-flex justify-end mt-4">
            <v-btn
              color="error"
              @click="openModalDelete = false"
            >
              Cancelar
            </v-btn>

            <v-btn
              color="info"
              class="ml-5"
              @click="deleteContract"
            >
              <span v-if="!loadingCircle">Excluir</span>
              <v-progress-circular
                v-else
                color="white"
                indeterminate
              >
              </v-progress-circular>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modalReverse"
      max-width="600px"
    >
      <ModalReverse
        :updated-table="getList"
        @close="modalReverse = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import storeMixin from '@/plugins/axiosMixins/storeMixin'
import myMixin from '@/plugins/mixin'
import messages from '@/plugins/showMessageMixin'
import {
  mdiAccountCash,
  mdiAccountOutline,
  mdiAlertDecagram,
  mdiAlertOutline,
  mdiCheckDecagram, mdiClose,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiDelete,
  mdiDeleteOutline,
  mdiDownload,
  mdiFileCertificateOutline,
  mdiFileDocumentEditOutline,
  mdiMessageProcessingOutline,
  mdiRestore,
  mdiThumbDown,
  mdiThumbUp,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import ModalInconsistencies from './ModalInconsistencies.vue'
import ModalReverse from './ModalReverse.vue'

const ExcelJS = require('exceljs')

export default {
  components: {
    ModalInconsistencies,
    ModalReverse,
  },

  mixins: [myMixin, messages, storeMixin],

  props: {
    reference: { type: String, default: '' },
    vendorName: { type: String, default: '' },
    vendorCpf: { type: String, default: '' },
    statusSales: { type: String, required: true },
    vacationInfo: { type: Boolean, required: true },
    commission: { type: Number, required: true },
    totalSales: { type: Number, required: true },
    numberSales: { type: Number, required: true },
  },

  setup() {
    return {
      icons: {
        mdiClose,
        mdiDelete,
        mdiDownload,
        mdiCheckDecagram,
        mdiAlertDecagram,
        mdiCogOutline,
        mdiThumbUp,
        mdiThumbDown,
        mdiRestore,
        mdiAccountOutline,
        mdiFileCertificateOutline,
        mdiAccountCash,
        mdiCurrencyUsd,
        mdiAlertOutline,
        mdiFileDocumentEditOutline,
        mdiDeleteOutline,
        mdiMessageProcessingOutline,
      },
    }
  },

  data() {
    return {
      isRoundedSnackbarVisible: false,
      expanded: [],
      itemId: '',
      totalSum: [],
      salesListId: [],
      totalCredit: [],
      itemTableSelected: [],
      descriptionForDeleteContract: '',
      listOptions: [
        {
          title: 'Validar',
          value: 'validar',
          icons: mdiThumbUp,
          color: 'success',
          validation: "verifyUser === 'null' && statusSales === 'REGISTRADO'",
          onAction: () => this.validateSales(),
        },
        {
          title: 'Inconsistência',
          value: 'inconsistência',
          icons: mdiThumbDown,
          color: 'error',
          validation: "verifyUser === 'null'",
          onAction: () => this.openInconsistenciesModal(),
        },
        {
          title: 'Estornar comissão',
          value: 'estornar',
          icons: mdiRestore,
          color: 'secondary',
          validation: "verifyUser === 'null'",
          onAction: () => this.handleOpenModalReverse(),
        },
        {
          title: 'Exportar',
          value: 'exportar',
          icons: mdiDownload,
          color: 'secondary',
          validation: "verifyUser === 'null'",
          onAction: () => this.exportCsv(),
        },
      ],

      headers: [
        { text: 'CLIENTE', value: 'client_name' },
        { text: 'STATUS', value: 'contract_status', align: 'left' },
        { text: 'PROPOSTA', value: 'proposal_number', align: 'center' },
        { text: 'MODELO', value: 'vehicle_model', align: 'center' },
        { text: 'VALOR DA VENDA', value: 'sales', align: 'center' },
        { text: 'PERCENTUAL', value: 'percentage', align: 'center' },
        { text: 'VALOR COMISSÃO', value: 'commission', align: 'center' },
      ],

      rules: {
        required: value => !!value || 'Campo obrigatório.',
      },

      footer: [],
      reports: [],
      userProfile: [],
      modalReverse: false,

      openModal: false,
      loadingCircle: false,
      openModalDelete: false,
      loadingValidate: false,
      snackbarInconsistency: false,
      itensToClickStatus: {},
    }
  },

  computed: {
    verifyUser() {
      return localStorage.getItem('userRole')
    },

    computedUserIsLeader() {
      if (this.userProfile.hierarchical_profile === 'nivel_2') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.headers.push({ text: 'Excluir contrato', value: 'delete', align: 'center' })

        return true
      }

      return false
    },
  },

  watch: {
    itemTableSelected(select) {
      const items = []
      select.map(item => {
        items.push(item.id)
      })

      this.salesListId = items
    },
  },

  created() {
    this.getList()
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
  },

  methods: {
    handleItemClick(item) {
      if (item.contract_status === 'COM ESTORNO') {
        console.log('item clicado: ', item)

        this.itensToClickStatus = item

        this.isRoundedSnackbarVisible = true
      }
    },

    handleOpenModalReverse() {
      this.modalReverse = true
    },

    returnColorStatus(item) {
      const status = item.contract_status

      switch (status) {
        case 'PRE-CONTEMPLADO':
          return 'info'
        case 'PRE-CANCELADO':
          return 'purple'
        case 'CANCELADO':
        case 'CANCELADO NO GRUPO':
          return 'error'
        case 'NORMAL':
          return 'success'
        case 'CONTEMPLADO':
          return 'success'
        case 'COM ESTORNO':
          return 'warning'
        default:
          return ''
      }
    },

    openInconsistenciesModal() {
      this.openModal = true
    },

    async validateSales() {
      const ids = []

      this.reports.map(item => {
        ids.push(item.id)
      })

      const body = {
        controlling_status: 'VALIDADO',
        contracts_id: ids,
      }

      axiosIns
        .post('api/v1/integration/contract/update_status', body)
        .then(res => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: `${res.data.data}`,
            timer: 4000,
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao validar vendas!',
            text: error,
          })
        })
    },

    async getList() {
      const body = {
        reference: this.reference,
        vendor_cpf: this.vendorCpf,
      }

      const totalList = []
      const totalCredit = []

      await axiosIns.post('api/v1/integration/contract/commission_detail', body).then(res => {
        this.reports = res.data.data

        res.data.data.map(item => {
          totalList.push(Number(item.commission))
          totalCredit.push(Number(item.sales))
        })
      })

      const sumTotal = totalList.reduce((sum, i) => sum + i)
      const sumCredit = totalCredit.reduce((sum, i) => sum + i)

      this.totalSum = sumTotal
      this.totalCredit = sumCredit
    },

    /**
     * @param {string} itemId
     */
    showModalDelete(itemId) {
      this.descriptionForDeleteContract = ''

      this.openModalDelete = true
      this.itemId = itemId
    },

    async deleteContract() {
      const formIsValid = this.$refs.form.validate()
      const endpoint = 'api/v1/integration/history_deleted_contract/store'

      const body = {
        text: this.descriptionForDeleteContract,
        contract: this.itemId,
      }

      if (formIsValid) {
        this.loadingCircle = true

        await this.postRegister(endpoint, body)

        this.loadingCircle = false

        return
      }

      this.showMessage({
        title: 'Campos vazios!',
        text: 'Verifique se todos os campos estão preenchidos',
      })
    },

    async exportCsv() {
      const file = await this.getCsv()
      if (file) {
        saveAs(file)
      }
    },

    async getCsv() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('sheet')

      worksheet.columns = [
        { header: 'CLIENTE', key: 'client_name' },
        { header: 'STATUS', key: 'contract_status' },
        { header: 'MODELO', key: 'vehicle_model' },
        { header: 'Nº PROPOSTA', key: 'proposal_number' },
        { header: 'DIGITO', key: 'proposal_digit' },
        { header: 'VALOR DA VENDA', key: 'sales' },
        { header: 'PERCENTUAL', key: 'percentage' },
        { header: 'VALOR DA COMISSÃO', key: 'commission' },
      ]

      this.reports.map(item => {
        worksheet.addRow([
          item.client_name,
          item.contract_status,
          item.vehicle_model,
          item.proposal_number,
          item.proposal_digit,
          Number(item.sales),
          Number(item.percentage),
          Number(item.commission),
        ])
      })

      let blob = null
      await workbook.xlsx.writeBuffer().then(data => {
        blob = new File([data], 'dados_comissões_detalhado', {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      })

      this.loaderExport = false

      return blob
    },
  },
}
</script>

<style>
.footer {
  height: 50px;
}
.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  height: 40px;
}
.contracts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  height: 50px;
  width: 100%;
}

.container-infos{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.bedge-vacation{
  width: 60px;
  height: 25px;
  background: #16B1FF1A;
  color: #16B1FF;
  border-radius: 30px;
  font-size: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.infos-vendor{
  display: flex;
  align-items: center;
  gap: 10px;
}

.infos-values{
  border: 1px solid #D1CAE3;
  padding: 15px;
  border-radius: 10px;

  display: flex;
  gap: 15px;
}

.badge-rounded{
  display: flex;
  align-items: center;
  justify-content: center;

  background: #E7E3FC1A;
  opacity: 0.9;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  padding: 20px;
}

.infos-cash{
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.status-with-reverse {
  cursor: pointer;
}

.status-with-reverse:hover {
  text-decoration: underline;
}

.title-snackbar{
  color: #E7E3FC
}

.container-content-snackbar{
  display: flex;
  background: #16B1FF;
  flex-direction: column;
}

.container-infos-snackbar{
  padding: 15px;
  background: #E7E3FC08;
  margin-top: 15px;
  width: 100%;

  display: flex;
}

.title-line-snackbar{
  font-weight: bold;
}

.container-one-snackbar{
  width: 50%;
  color: #E7E3FC;
}

.line-snackbar{
  gap: 10px;
}

.old-commission{
  text-decoration: line-through;
}

.container-options{
  padding: 8px;
  border-radius: 60px;

  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.container-options:hover{
  background: #3B355A;
}
</style>
