<template>
  <div class="container-systems">
    <div class="container-line">
      <span>Sistemas Motoca (Desenvolvidos pela Motoca Systems)</span>
    </div>
    <div class="container">
      <div
        v-show="computedIntegrator"
        class="item"
      >
        <Integrator></Integrator>
      </div>

      <div
        v-show="computedAdmSystem"
        class="item"
      >
        <AdmSystem></AdmSystem>
      </div>

      <div
        v-show="computedPersonalDepartment"
        class="item"
      >
        <PersonalDepartment></PersonalDepartment>
      </div>

      <div
        v-show="computedNexus"
        class="item"
      >
        <Nexus></Nexus>
      </div>

      <div
        v-show="computedSgqSystem"
        class="item"
      >
        <SgqSystem></SgqSystem>
      </div>

      <div
        v-show="computedNexusApp"
        class="item"
      >
        <NexusApp></NexusApp>
      </div>

      <div
        v-show="computedAssomotoca"
        class="item"
      >
        <Assomotoca></Assomotoca>
      </div>
      <div
        v-show="computedSearchClient"
        class="item"
      >
        <SearchClient></SearchClient>
      </div>

      <div
        v-show="computedUtilities"
        class="item"
      >
        <Utilities></Utilities>
      </div>
      <div
        v-show="computedSolicitation"
        class="item"
      >
        <Solicitations></Solicitations>
      </div>
    </div>

    <div class="container-line">
      <span>Sistemas Motoca (Terceirizados)</span>
    </div>
    <div class="container">
      <div
        v-show="computedCloud"
        class="item"
      >
        <Cloud></Cloud>
      </div>

      <div
        v-show="computedHuggy"
        class="item"
      >
        <Huggy></Huggy>
      </div>

      <div
        v-show="computedTsuru"
        class="item"
      >
        <Tsuru></Tsuru>
      </div>

      <div
        v-show="computedSolides"
        class="item"
      >
        <Solids></Solids>
      </div>

      <div
        v-show="computedSecullum"
        class="item"
      >
        <Secullum></Secullum>
      </div>

      <div
        v-show="computedSaphira"
        class="item"
      >
        <Saphira></Saphira>
      </div>

      <div
        v-show="computedUnico"
        class="item"
      >
        <Unico></Unico>
      </div>

      <div
        v-show="computedFollowize"
        class="item"
      >
        <Followize></Followize>
      </div>

      <div
        v-show="computedSecullumAdm"
        class="item"
      >
        <SecullumAdm></SecullumAdm>
      </div>
      <div
        v-show="computedSol"
        class="item"
      >
        <Sol></Sol>
      </div>
    </div>

    <div class="container-line">
      <span>Sistemas Honda</span>
    </div>
    <div class="container">
      <div
        v-show="computedIhs"
        class="item"
      >
        <Ihs></Ihs>
      </div>

      <div
        v-show="computedMyHonda"
        class="item"
      >
        <MyHonda></MyHonda>
      </div>

      <div
        v-show="computedBancoHonda"
        class="item"
      >
        <BancoHonda></BancoHonda>
      </div>

      <div
        v-show="computedAssoParts"
        class="item"
      >
        <AssoParts></AssoParts>
      </div>

      <div
        v-show="computedHondaLms"
        class="item"
      >
        <HondaLms></HondaLms>
      </div>

      <div
        v-show="computedAcademiaVendas"
        class="item"
      >
        <AcademiaVendas></AcademiaVendas>
      </div>

      <div
        v-show="computedPitStop"
        class="item"
      >
        <PitStop></PitStop>
      </div>

      <div
        v-show="computedPosVendas"
        class="item"
      >
        <PosVendas></PosVendas>
      </div>
    </div>
  </div>
</template>

<script>
import AdmSystem from './GridComponents/AdmSystem.vue'
import Assomotoca from './GridComponents/AssomotocaSystems.vue'
import Integrator from './GridComponents/Integrator.vue'
import Nexus from './GridComponents/Nexus.vue'
import NexusApp from './GridComponents/NexusApp.vue'
import PersonalDepartment from './GridComponents/PersonalDepartment.vue'
import Saphira from './GridComponents/SaphiraSystems.vue'
import SgqSystem from './GridComponents/SgqSystem.vue'

import AcademiaVendas from './GridComponents/AcademiaVendasSystems.vue'
import AssoParts from './GridComponents/AssoPartsSystems.vue'
import BancoHonda from './GridComponents/BancoHondaSystems.vue'
import Cloud from './GridComponents/CloudSystems.vue'
import Followize from './GridComponents/FollowizeSystems.vue'
import HondaLms from './GridComponents/HondaLmsSystems.vue'
import Huggy from './GridComponents/HuggySystems.vue'
import Ihs from './GridComponents/IhsSystems.vue'
import MyHonda from './GridComponents/MyHondaSystems.vue'
import PitStop from './GridComponents/PitStopSystems.vue'
import PosVendas from './GridComponents/PosVendasSystems.vue'
import SearchClient from './GridComponents/SearchClient.vue'
import SecullumAdm from './GridComponents/SecullumAdmSystems.vue'
import Secullum from './GridComponents/SecullumSystems.vue'
import Sol from './GridComponents/Sol.vue'
import Solicitations from './GridComponents/Solicitations.vue'
import Solids from './GridComponents/SolidsSystems.vue'
import Tsuru from './GridComponents/TsuruSystems.vue'
import Unico from './GridComponents/UnicoSystems.vue'
import Utilities from './GridComponents/Utilities.vue'

export default {
  components: {
    Utilities,
    Solicitations,
    Sol,
    Integrator,
    AdmSystem,
    SgqSystem,
    NexusApp,
    Nexus,
    PersonalDepartment,
    Cloud,
    Ihs,
    Huggy,
    Tsuru,
    Solids,
    Secullum,
    Assomotoca,
    Saphira,
    Unico,
    Followize,
    SecullumAdm,
    MyHonda,
    BancoHonda,
    AssoParts,
    HondaLms,
    AcademiaVendas,
    PitStop,
    PosVendas,
    SearchClient,
  },

  props: {
    accessSystems: {
      type: Array,
      required: true,
    },
  },

  computed: {
    computedIntegrator() {
      return this.accessSystems.includes('Integrador')
    },
    computedAdmSystem() {
      return this.accessSystems.includes('AdmNexus')
    },
    computedSgqSystem() {
      return this.accessSystems.includes('Sgq')
    },
    computedNexusApp() {
      return this.accessSystems.includes('AppNexus')
    },
    computedPersonalDepartment() {
      return this.accessSystems.includes('Departamento Pessoal')
    },
    computedNexus() {
      return this.accessSystems.includes('Nexus')
    },
    computedCloud() {
      return this.accessSystems.includes('Cloud')
    },
    computedIhs() {
      return this.accessSystems.includes('Ihs')
    },
    computedHuggy() {
      return this.accessSystems.includes('Huggy')
    },
    computedTsuru() {
      return this.accessSystems.includes('Tsuru')
    },
    computedSolides() {
      return this.accessSystems.includes('Sólides')
    },
    computedSecullum() {
      return this.accessSystems.includes('Secullum Ponto')
    },
    computedAssomotoca() {
      return this.accessSystems.includes('Assomotoca')
    },
    computedSelf() {
      return this.accessSystems.includes('Self deal')
    },
    computedVolus() {
      return this.accessSystems.includes('Volus')
    },
    computedSaphira() {
      return this.accessSystems.includes('Saphira')
    },
    computedUnico() {
      return this.accessSystems.includes('Unico')
    },
    computedFollowize() {
      return this.accessSystems.includes('Followize')
    },
    computedSecullumAdm() {
      return this.accessSystems.includes('Secullum Adm')
    },
    computedMyHonda() {
      return this.accessSystems.includes('My Honda')
    },
    computedBancoHonda() {
      return this.accessSystems.includes('Banco Honda')
    },
    computedAssoParts() {
      return this.accessSystems.includes('AssoParts')
    },
    computedSol() {
      return this.accessSystems.includes('Sol')
    },
    computedHondaLms() {
      return this.accessSystems.includes('LMS - Treinamento')
    },
    computedAcademiaVendas() {
      return this.accessSystems.includes('Academia de Vendas')
    },
    computedPitStop() {
      return this.accessSystems.includes('Pitstop')
    },
    computedPosVendas() {
      return this.accessSystems.includes('Pos-Vendas')
    },
    computedSearchClient() {
      return this.accessSystems.includes('Client Connect Explorer')
    },
    computedUtilities() {
      return this.accessSystems.includes('Utilitarios')
    },
    computedSolicitation() {
      return this.accessSystems.includes('Solicitações')
    },
  },

}
</script>

<style scoped>
.container-systems{
  width: 100%;
  margin-top: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-line{
  width: 60%;
  padding: 10px 0px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ffffff48;
}
.container {
  width: 55%;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-gap: 57px;
  justify-content: center;
}

@media (max-width: 1600px) {
  .container {
    width: 60%;
  }

  .container-line{
    width: 65%;
  }
}

@media (max-width: 1400px) {
  .container {
    width: 65%;
  }

  .container-line{
    width: 70%;
  }
}

@media (max-width: 1200px) {
  .container {
    width: 70%;
  }

  .container-line{
    width: 75%;
  }
}

@media (max-width: 1100px) {
  .container {
    width: 75%;
  }

  .container-line{
    width: 80%;
  }
}

@media (max-width: 1000px) {
  .container {
    width: 80%;
  }
}

@media (max-width: 920px) {
  .container {
    width: 90%;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .container-line{
    width: 95%;
  }
}

@media (max-width: 800px) {
  .container {
    width: 90%;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 760px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 540px) {
  .container {
    grid-template-columns: 1fr;
  }
}

.item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
