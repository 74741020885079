<template>
  <div
    class="d-flex flex-column"
    style="gap: 10px"
  >
    <h3
      id="activitiesSection"
      style="font-weight: 600; font-size: 16px;"
    >
      Atividades
    </h3>
    <div class="separator"></div>
    <div
      class="d-flex"
      style="justify-content: space-between;"
    >
      <div
        class="d-flex add-coment mt-4"

        @click="showComment = !showComment"
      >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        <span>Adicionar comentário...</span>
      </div>
      <div
        v-if="showComment"
        class="d-flex"
        style="gap: 20px; margin-bottom: -55px;"
      >
        <div
          v-if="!showLinkInput"
          class="comment-actions"
          @click="showLinkInput = !showLinkInput"
        >
          <v-icon>
            {{ icons.mdiLink }}
          </v-icon>
          <span style="font-size: 13px;">Inserir link</span>
        </div>
        <div
          v-if="!showAttachmentInput"
          class="comment-actions"
          @click="showAttachmentInput = !showAttachmentInput"
        >
          <v-icon size="20">
            {{ icons.mdiPaperclip }}
          </v-icon>
          <span style="font-size: 13px;">Inserir anexo</span>
        </div>
      </div>
    </div>

    <!-- Caixa de comentário -->
    <div v-if="showComment">
      <v-textarea
        v-model="comment"
        class="pt-3"
        label="Faça um comentário"
        outlined
      />

      <!-- Ações de comentário -->

      <div
        v-if="showLinkInput"
        class="d-flex "
        style="gap: 5px"
      >
        <v-text-field
          v-model="link"
          label="Insira o link"
          outlined
          dense
        />
        <v-btn
          icon
          color="error"
          @click="handleClearLink"
        >
          <v-icon>{{ icons.mdiTrashCan }}</v-icon>
        </v-btn>
      </div>

      <div
        v-if="showAttachmentInput"
        class="d-flex flex-column"
      >
        <div
          class="d-flex"
          style="justify-content: end; margin-right: 37px;"
        >
          <v-tooltip
            top
            :color="'#1E1E1EfD'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="16"
                v-bind="attrs"
                class="mb-2"
                v-on="on"
              >
                {{ icons.mdiHelpCircle }}
              </v-icon>
            </template>
            <span
              style="color: white; font-weight: 500; font-size: 13px;"
            >Arquivos aceitos somente nos formatos <br> JPEG, JPG, PNG, SVG e PDF, com <br> tamanho máximo de 15MB.</span>
          </v-tooltip>
        </div>
        <div
          class="d-flex"
          style="gap: 5px;"
        >
          <v-file-input
            v-model="attachment"
            prepend-icon=""
            multiple
            :prepend-inner-icon="icons.mdiPaperclip"
            label="Anexar arquivo"
            small-chips
            clearable
            outlined
            dense
          />
          <v-btn
            icon
            color="error"
            @click="handleClearAttachmentInput"
          >
            <v-icon>{{ icons.mdiTrashCan }}</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        v-if="sectorName === 'MARKETING' && (solicData.status !== 'FINALIZADA' && solicData.status !== 'ENVIADA' && solicData.status !== 'ENVIADA PARA VALIDAÇÃO')"
        class="d-flex"
        style="align-items: center"
      >
        <v-switch
          v-model="needValidation"
          inset
          color="purple"
        >
        </v-switch>
        <span>Necessário validar</span>
      </div>
      <div
        class=" d-flex mb-4"
        style="gap: 20px;"
      >
        <v-btn
          color="success"
          outlined
          :disabled="isPending || !comment"
          @click="handleAddComment"
        >
          <div
            class="d-flex"
            style="align-items: center; justify-items: center;"
          >
            <v-icon
              size="20"
              class="mr-2"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span v-if="!isPending">ADICIONAR</span>
            <v-progress-circular
              v-else
              indeterminate
              color="info"
            ></v-progress-circular>
          </div>
        </v-btn>
        <v-btn
          :disabled="isPending"
          outlined
          color="error"
          @click="handleResetComment"
        >
          <span>Cancelar</span>
        </v-btn>
      </div>
    </div>
    <div style="margin-left: -35px;">
      <v-timeline
        dense
        align-top
        outlined
        class="timeline-custom-dense timeline-custom-dots ml-9"
      >
        <v-timeline-item
          v-for="(item, index) in historyArr"
          :key="index"
          :color="item.need_validation ? 'newOrange' : 'success'"
          outlined
          :icon="icons.mdiCommentTextOutline"
          small
        >
          <RenderTimelineItem
            :solic-data="solicData"
            :item="item"
            :format-date="formatDate"
            @getHistory="emmitGetHistory"
          />
        </v-timeline-item>
      </v-timeline>
    </div>
  </div>
</template>

<script>
import axiosFileApi from '@/plugins/axiosFileApi'
import formatters from '@/plugins/formattersMixin1'
import axiosNewInstance from '@/plugins/newAxiosInstance'
import messages from '@/plugins/showMessageMixin'
import {
  mdiHelpCircle,
  mdiLink, mdiPaperclip, mdiPlus, mdiTrashCan,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import RenderTimelineItem from './RenderTimelineItem.vue'

export default {
  name: 'Activities',
  components: { RenderTimelineItem },
  mixins: [messages, formatters],
  props: {
    solicitationId: {
      required: true,
      type: String,
    },
    historyArr: {
      required: true,
      type: Array,
    },
    solicData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiLink,
        mdiPaperclip,
        mdiTrashCan,
        mdiHelpCircle,
      },
      comment: '',
      link: '',
      attachment: null,
      isPending: false,
      needValidation: false,
      showComment: false,
      showLinkInput: false,
      showAttachmentInput: false,
      profileName: {},
      sectorName: '',
      rules: {
        required: value => !!value || 'Este campo é obrigatório',
      },
    }
  },
  created() {
    const userProfile = localStorageSlim.get('userProfile', { decrypt: true })

    this.profileName = userProfile?.profile?.profile
    this.sectorName = userProfile?.occupation_sector?.sector?.name
  },
  methods: {
    emmitGetHistory() {
      this.$emit('getHistory')
    },
    handleResetComment() {
      this.comment = ''
      this.link = ''
      this.attachment = null
      this.showComment = false
      this.showLinkInput = false
      this.showAttachmentInput = false
      this.needValidation = false
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')

      return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`
    },
    formatPrevisionDate(date) {
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
    handleOpenLink(link) {
      window.open(link, '_blank')
    },
    handleClearLink() {
      this.link = ''
      this.showLinkInput = false
    },
    handleClearAttachmentInput() {
      this.attachment = ''
      this.showAttachmentInput = null
    },
    async handleAddComment() {
      try {
        this.isPending = true

        const commentBody = {
          marketing_solicitations_id: this.solicitationId,
          need_validation: this.needValidation,
          type: 'comment',
          comment: this.comment,
          link: this.link,
        }

        const { data } = await axiosNewInstance.post(
          '/api/v1/solicitations/marketing_solicitation_history/store',
          commentBody,
        )

        const commentId = data.data.id

        if (this.attachment && this.attachment.length > 0) {
          const formData = new FormData()

          this.attachment.forEach(file => {
            formData.append('files[]', file)
          })

          formData.append('id', commentId)

          await axiosFileApi.post(
            '/api/v1/solicitations/marketing_comment_attachment/store',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          )
        }

        if (this.needValidation) {
          this.$emit('updatePanel')
        }
        this.$emit('getHistory')
      } catch (error) {
        this.$swal({
          icon: 'info',
          title: 'Atenção!',
          showConfirmButton: true,
          text: error.message,
          timer: 5000,
        })

        console.error('Erro ao adicionar comentário ou anexos:', error)
      } finally {
        this.handleResetComment()
        this.isPending = false
      }
    },
  },
}
</script>

<style>
.add-coment {
  gap: 20px;
  margin-bottom: 30px;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.add-coment:hover {
  opacity: 0.8;
}
.comment-actions {
  gap: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease;
}
.comment-actions:hover {
  opacity: 0.8;
}
.separator {
  width: 100%;
  height: 0.5px;
  background: #3b3755;
  /* color: #ff9900; */
}
</style>
