<template>
  <div
    class="d-flex flex-column"
  >
    <div>
      <span>Qual o nome completo do colaborador?</span>
      <v-text-field
        v-model="localMailSubscription.employeeFullName"
        dense
        :readonly="isJustForRead"
        :rules="[rules.required]"
        class="pt-3"
        outlined
        label="Nome completo"
        @input="emitChanges"
      />
    </div>
    <div>
      <span>Qual a função do Colaborador?</span>
      <v-text-field
        v-model="localMailSubscription.employeeFunction"
        dense
        :readonly="isJustForRead"
        :rules="[rules.required]"
        class="pt-3"
        outlined
        label="Função"
        @input="emitChanges"
      />
    </div>
    <div>
      <span>Qual o Skype do colaborador?</span>
      <v-text-field
        v-model="localMailSubscription.employeeSkype"
        dense
        :readonly="isJustForRead"
        :rules="[rules.required]"
        class="pt-3"
        outlined
        label="Skype"
        @input="emitChanges"
      />
    </div>
    <div>
      <span>Qual o o ramal do colaborador (caso tenha)</span>
      <v-text-field
        v-model="localMailSubscription.telephoneExtension"
        dense
        :readonly="isJustForRead"
        class="pt-3"
        outlined
        label="Ramal"
        @input="emitChanges"
      />
    </div>
  </div>
</template>

<script>
import { mdiInformationVariant } from '@mdi/js'

export default {
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        employeeFullName: '',
        employeeFunction: '',
        employeeSkype: '',
        telephoneExtension: '',
      }),
    },
    isJustForRead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localMailSubscription: { ...this.value },
      icons: {
        mdiInformationVariant,
      },
      rules: {
        required: value => !!value || 'Este campo é obrigatório',
      },
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.localMailSubscription = { ...newValue }
      },
    },
  },
  methods: {
    emitChanges() {
      this.$emit('input', this.localMailSubscription)
    },
  },
}
</script>
