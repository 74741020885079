const Solicitations = {
  path: '/solicitations',
  component: () => import('@/views/pages/Solicitations/SolicitationsRoute.vue'),
  meta: { layout: 'content' },
  children: [
    {
      path: 'home',
      name: 'solicitations-home',
      component: () => import('@/views/pages/Solicitations/Marketing/Solicitations/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'panel',
      name: 'solicitations-panel',
      component: () => import('@/views/pages/Solicitations/Marketing/SolicitationsPanel/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'tools',
      name: 'solicitations-tools',
      component: () => import('@/views/pages/Solicitations/Marketing/SolicitationsTools/Home.vue'),
      meta: {
        layout: 'content',
      },
    },
    {
      path: 'report',
      name: 'solicitations-report',
      component: () => import('@/views/pages/Solicitations/Marketing/SolicitationsReport/home.vue'),
      meta: {
        layout: 'content',
      },
    },
  ],
}

export default Solicitations
