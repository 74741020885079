import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c('div',{staticStyle:{"width":"100%","position":"relative"}},[_c('h3',{staticStyle:{"font-weight":"600","font-size":"18px","text-align":"center","padding-top":"30px"}},[_vm._v(" Filtrar "+_vm._s(_vm.activeTab.title.toLowerCase())+" ")]),_c(VBtn,{staticStyle:{"position":"absolute","right":"20px","top":"25px"},attrs:{"icon":""},on:{"click":_vm.closeModal}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1),_c('div',{staticClass:"mx-7"},[_c('div',{staticClass:"py-6"},[_c('Separator',{attrs:{"label":"Filtrar dados"}})],1),_c(VForm,{ref:"form"},[_c(VTextField,{attrs:{"outlined":"","dense":"","label":"N° solicitação"},model:{value:(_vm.slmNumber),callback:function ($$v) {_vm.slmNumber=$$v},expression:"slmNumber"}}),_c(VAutocomplete,{attrs:{"items":_vm.requesters,"item-text":"name","item-value":"id","label":"Solicitante","outlined":"","dense":""},model:{value:(_vm.createdBy),callback:function ($$v) {_vm.createdBy=$$v},expression:"createdBy"}}),_c(VAutocomplete,{attrs:{"items":_vm.companies,"item-text":"fantasy_name","item-value":"id","label":"Loja","outlined":"","dense":""},model:{value:(_vm.companyId),callback:function ($$v) {_vm.companyId=$$v},expression:"companyId"}}),_c(VAutocomplete,{attrs:{"items":_vm.listCategoriesItem,"label":"Assunto","outlined":"","dense":""},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_c('span',[_vm._v("Por período da solicitação:")]),_c('div',{staticClass:"d-flex mt-3",staticStyle:{"gap":"20px","width":"100%"}},[_c(VMenu,{ref:"menu1",attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Data inicial","append-icon":_vm.icons.mdiCalendar,"readonly":"","outlined":"","dense":""},on:{"blur":function($event){_vm.date = _vm.parseDate(_vm.initialDateFormatted)}},model:{value:(_vm.initialDateFormatted),callback:function ($$v) {_vm.initialDateFormatted=$$v},expression:"initialDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c(VDatePicker,{on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.initialDate),callback:function ($$v) {_vm.initialDate=$$v},expression:"initialDate"}})],1),_c(VMenu,{ref:"menu2",attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Data final","append-icon":_vm.icons.mdiCalendar,"readonly":"","outlined":"","dense":""},on:{"blur":function($event){_vm.date = _vm.parseDate(_vm.finalDateFormatted)}},model:{value:(_vm.finalDateFormatted),callback:function ($$v) {_vm.finalDateFormatted=$$v},expression:"finalDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c(VDatePicker,{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.finalDate),callback:function ($$v) {_vm.finalDate=$$v},expression:"finalDate"}})],1)],1),_c('span',[_vm._v("Por prazo esperado pelo líder:")]),_c('div',{staticClass:"d-flex mt-3",staticStyle:{"gap":"20px","width":"100%"}},[_c(VMenu,{ref:"menu3",attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Data inicial","append-icon":_vm.icons.mdiCalendar,"readonly":"","outlined":"","dense":""},on:{"blur":function($event){_vm.date = _vm.parseDate(_vm.expectedInitialDateFormatted)}},model:{value:(_vm.expectedInitialDateFormatted),callback:function ($$v) {_vm.expectedInitialDateFormatted=$$v},expression:"expectedInitialDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c(VDatePicker,{on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.expectedInitialDate),callback:function ($$v) {_vm.expectedInitialDate=$$v},expression:"expectedInitialDate"}})],1),_c(VMenu,{ref:"menu4",attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Data final","append-icon":_vm.icons.mdiCalendar,"readonly":"","outlined":"","dense":""},on:{"blur":function($event){_vm.date = _vm.parseDate(_vm.expectedFinalDateFormatted)}},model:{value:(_vm.expectedFinalDateFormatted),callback:function ($$v) {_vm.expectedFinalDateFormatted=$$v},expression:"expectedFinalDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c(VDatePicker,{on:{"input":function($event){_vm.menu4 = false}},model:{value:(_vm.expectedFinalDate),callback:function ($$v) {_vm.expectedFinalDate=$$v},expression:"expectedFinalDate"}})],1)],1),_c('div',{staticClass:" d-flex justify-between pb-4"},[(_vm.isAnyFieldFilled)?_c(VBtn,{attrs:{"color":"error"},on:{"click":_vm.handleClearFilter}},[_c('span',[_vm._v("LIMPAR")])]):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"color":"info","disabled":!_vm.isFilterButtonEnabled},on:{"click":function($event){return _vm.handleFilter(true)}}},[_c('span',[_vm._v("FILTRAR")])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }