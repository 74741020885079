<template>
  <v-card style="padding: 0px 30px 30px 30px;">
    <div class="head-modal">
      <div></div>
      <span>Editar terceirizado</span>

      <v-icon
        medium
        class="me-1"
        @click="handleCloseModal"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </div>
    <v-form
      ref="form"
      class="d-flex flex-column mt-5"
      style="gap: 5px;"
    >
      <div
        class="d-flex pb-8"
        style="width: 100%; align-items: center; justify-content: center;"
      >
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="true"
          :nudge-right="85"
          :nudge-bottom="60"
          transition="scale-transition"
          bottom
          min-width="auto"
        >
          <template
            v-slot:activator="{ on, attrs }"
            bottom
          >
            <div>
              <v-avatar
                :color="returnCharBasedOnCharCode(editedChar).bgColor"
                class="add-people"
                v-bind="attrs"
                size="80"
                v-on="on"
              >
                <v-icon
                  size="50"
                  :color="returnCharBasedOnCharCode(editedChar).avatarColor"
                >
                  {{ editedChar ? `${icons[returnCharBasedOnCharCode(editedChar).icon]}` : '-' }}
                </v-icon>
              </v-avatar>
              <div
                class="add-people"
                v-bind="attrs"
                style="position: absolute; border-radius: 50px; background: #E7E3FC; z-index: 10; border: 4px solid #312D4B; margin-top: -25px; margin-left: -5px;"
                v-on="on"
              >
                <v-icon
                  color="#312D4B"
                  class="pa-1"
                  size="25"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
              </div>
            </div>
          </template>
          <v-card class="pa-5">
            <div
              style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 16px;"
            >
              <v-avatar
                v-for="char in charMap"
                :key="char.bgColor"
                class="add-people"
                size="40"
                :color="char.bgColor"
                @click="handleSelectChar(char)"
              >
                <v-icon
                  size="20"
                  :color="char.avatarColor"
                >
                  {{ char.icon }}
                </v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-menu>
        <!-- <div
          style="width: 80px; height: 80px; border-radius: 50px; background: red;"
          class="mb-3"
        ></div> -->
      </div>
      <v-text-field
        v-model="editedName"
        :rules="[rules.required]"
        outlined
        dense
        label="Nome"
      />
      <v-text-field
        v-model="editedCpf_cnpj"
        :rules="[rules.required]"
        :error="!!cpfCnpjError"
        :error-messages="cpfCnpjError"
        outlined
        dense
        label="CPF/CNPJ"
        @input="(value) => applyCpfCnpjMask(value, 'editedCpf_cnpj')"
      />

      <v-autocomplete
        v-model="editedActivity"
        :items="listCategoriesItem"
        label="Atividade"
        :rules="[rules.required]"
        item-text="name"
        item-value="id"
        outlined
        dense
      />
      <div
        class="d-flex justify-end"
        style="width: 100%;"
      >
        <v-btn
          color="info"
          :disabled="isLoading"
          @click="handleCreateThirdParty()"
        >
          <span
            v-if="!isLoading"
          >Atualizar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiAirballoonOutline,
  mdiAlienOutline, mdiAnchor, mdiClose, mdiHandshakeOutline, mdiHomeOutline, mdiMovieOpenOutline, mdiMusic,
  mdiPencil,
  mdiPrinter, mdiPuzzleOutline, mdiStarFourPointsOutline, mdiStarOutline, mdiWeatherSunny,
} from '@mdi/js'

export default {

  name: 'EditThirdPartyModal',
  mixins: [messages, formatters],
  props: {
    name: {
      type: String,
      required: true,
    },
    cpfCnpj: {
      type: String,
      required: true,
    },
    activity: {
      type: String,
      required: true,
    },
    charCode: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiClose, mdiPencil, mdiHomeOutline, mdiHandshakeOutline, mdiAirballoonOutline, mdiAlienOutline, mdiAnchor, mdiMovieOpenOutline, mdiMusic, mdiPrinter, mdiPuzzleOutline, mdiStarFourPointsOutline, mdiStarOutline, mdiWeatherSunny,
      },
      isLoading: false,
      menu1: false,

      listCategoriesItem: [],

      editedName: '',
      editedCpf_cnpj: '',
      editedActivity: '',
      editedChar: '',
      charMap: [
        {
          charCode: 'CH1', icon: mdiHandshakeOutline, avatarColor: '#fff', bgColor: '#0085FF',
        },
        {
          charCode: 'CH2', icon: mdiHomeOutline, avatarColor: '#fff', bgColor: '#9A62FD',
        },
        {
          charCode: 'CH3', icon: mdiStarOutline, avatarColor: '#fff', bgColor: '#FFB400',
        },
        {
          charCode: 'CH4', icon: mdiStarFourPointsOutline, avatarColor: '#fff', bgColor: '#FF821A',
        },
        {
          charCode: 'CH5', icon: mdiPuzzleOutline, avatarColor: '#fff', bgColor: '#56CA00',
        },
        {
          charCode: 'CH6', icon: mdiAirballoonOutline, avatarColor: '#fff', bgColor: '#16B1FF',
        },
        {
          charCode: 'CH7', icon: mdiWeatherSunny, avatarColor: '#fff', bgColor: '#C7B200',
        },
        {
          charCode: 'CH8', icon: mdiAlienOutline, avatarColor: '#fff', bgColor: '#FF4C51',
        },
        {
          charCode: 'CH9', icon: mdiAnchor, avatarColor: '#fff', bgColor: '#87ADBD',
        },
        {
          charCode: 'CH10', icon: mdiPrinter, avatarColor: '#fff', bgColor: '#28243D',
        },
        {
          charCode: 'CH11', icon: mdiMusic, avatarColor: '#fff', bgColor: '#0051E9',
        },
        {
          charCode: 'CH12', icon: mdiMovieOpenOutline, avatarColor: '#fff', bgColor: '#00AF7E',
        },
      ],
      cpfCnpjError: '',
    }
  },
  created() {
    this.editedActivity = this.activity
    this.editedCpf_cnpj = this.cpfCnpj
    this.editedName = this.name
    this.editedChar = this.charCode
    this.handleGetActivities()
  },
  methods: {

    handleSelectChar(char) {
      this.editedChar = char.charCode
    },
    returnCharBasedOnCharCode(charCode) {
      const charMap = {
        CH1: { icon: 'mdiHandshakeOutline', avatarColor: '#fff', bgColor: '#0085FF' },
        CH2: { icon: 'mdiHomeOutline', avatarColor: '#fff', bgColor: '#9A62FD' },
        CH3: { icon: 'mdiStarOutline', avatarColor: '#fff', bgColor: '#FFB400' },
        CH4: { icon: 'mdiStarFourPointsOutline', avatarColor: '#fff', bgColor: '#FF821A' },
        CH5: { icon: 'mdiPuzzleOutline', avatarColor: '#fff', bgColor: '#56CA00' },
        CH6: { icon: 'mdiAirballoonOutline', avatarColor: '#fff', bgColor: '#16B1FF' },
        CH7: { icon: 'mdiWeatherSunny', avatarColor: '#fff', bgColor: '#C7B200' },
        CH8: { icon: 'mdiAlienOutline', avatarColor: '#fff', bgColor: '#FF4C51' },
        CH9: { icon: 'mdiAnchor', avatarColor: '#fff', bgColor: '#87ADBD' },
        CH10: { icon: 'mdiPrinter', avatarColor: '#fff', bgColor: '#28243D' },
        CH11: { icon: 'mdiMusic', avatarColor: '#fff', bgColor: '#0051E9' },
        CH12: { icon: 'mdiMovieOpenOutline', avatarColor: '#fff', bgColor: '#00AF7E' },
      }
      const selectedChar = charMap[charCode]

      return selectedChar
    },
    async handleGetActivities() {
      try {
        const res = await axiosIns.get('/api/v1/solicitations/marketing_third_parties_activity/index')
        this.listCategoriesItem = res.data.data
      } catch (error) {
        console.error('Erro ao buscar atividades', error)
      }
    },
    extractNumbers(input) {
      if (!input) return ''

      return input.replace(/\D/g, '')
    },
    handleCloseModal() {
      this.$emit('close')
    },
    async handleCreateThirdParty() {
      const isValidForm = this.$refs.form.validate()
      if (!isValidForm) {
        return
      }
      const cpfCnpjNumbers = this.extractNumbers(this.editedCpf_cnpj)

      if (cpfCnpjNumbers.length !== 11 && cpfCnpjNumbers.length !== 14) {
        this.cpfCnpjError = 'CPF deve ter 11 números ou CNPJ 14 números.'

        return
      }
      this.cpfCnpjError = ''

      this.isLoading = true

      const body = {
        cpf_cnpj: this.editedCpf_cnpj,
        name: this.editedName.toUpperCase(),
        third_party_activity_id: this.editedActivity.toUpperCase(),
        avatar_code: this.editedChar,
      }
      try {
        await axiosIns.put(`/api/v1/solicitations/marketing_third_parties/update/${this.id}`, body)
        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Terceirizado atualizado.',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        console.error('Erro ao atualizar terceirizado:', error)
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro ao atualizar o terceirizado!',
          showConfirmButton: true,
          timer: 3000,
        })
      } finally {
        this.isLoading = false
        this.handleCloseModal()
        this.$emit('updatedTable')
        this.$refs.form.reset()
      }
    },
  },
}
</script>

<style scoped>
.head-modal{
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.add-people {
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.add-people:hover{
  opacity: 0.8;
}
</style>
