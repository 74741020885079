<template>
  <div
    class="d-flex flex-column"
    style="gap: 15px;"
  >
    <div>
      <span>
        Briefing
      </span>
      <v-textarea
        v-model="localPrintedMaterial.briefing"
        :readonly="isJustForRead"
        :rules="[rules.required]"
        class="pt-3"
        outlined
        label="Descreva as informações"
        @input="emitChanges"
      />
      <InfoTag
        :icon="icons.mdiInformationVariant"
        text="Descreva sobre a sua solicitação, contextualizando o problema/situação e trazendo todas as informações necessárias."
        icon-color="#fff"
        icon-background-color="#16B1FF"
      />
    </div>
    <div>
      <span>
        Medidas
      </span>
      <div
        class="d-flex align-center mt-3"
        style="gap: 12px;"
      >
        <v-text-field
          v-model="localPrintedMaterial.length"
          :readonly="isJustForRead"
          outlined
          dense
          label="Comprimento"
          @input="emitChanges"
        />
        <span class="mb-6">X</span>
        <v-text-field
          v-model="localPrintedMaterial.width"
          :readonly="isJustForRead"
          outlined
          dense
          label="Largura"
          @input="emitChanges"
        />
        <span class="mb-6">X</span>
        <v-text-field
          v-model="localPrintedMaterial.height"
          :readonly="isJustForRead"
          outlined
          dense
          label="Altura"
          @input="emitChanges"
        />
      </div>
      <div
        class="d-flex my-10"
        style="width: 100%; align-items: center; justify-content: space-evenly;"
      >
        <img
          :src="require('@/assets/images/svg/imagemQuadradoSolicitacoes.svg')"
          alt="Imagem do comprimento largura e altura"
        />
        <img
          :src="require('@/assets/images/svg/imagemQuadradoSolicitacoesMKT.svg')"
          alt="Imagem da largura e altura"
        />
      </div>
      <div>
        <span>Onde será utilizado?</span>
        <v-text-field
          v-model="localPrintedMaterial.locationOfUse"
          :readonly="isJustForRead"
          :rules="[rules.required]"
          outlined
          dense
          class="mt-3"
          label="Descreva o local"
          @input="emitChanges"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InfoTag from '@/components/InfoTag.vue'
import {
  mdiDownloadOutline,
  mdiEyeOutline,
  mdiInformationVariant, mdiPaperclip,
} from '@mdi/js'

export default {
  components: {
    InfoTag,
  },

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        height: '',
        length: '',
        width: '',
        locationOfUse: '',
        localPhoto: null,
        briefing: '',
      }),
    },
    isJustForRead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localPrintedMaterial: { ...this.value },
      icons: {
        mdiInformationVariant, mdiPaperclip, mdiEyeOutline, mdiDownloadOutline,
      },
      rules: {
        required: value => !!value || 'Este campo é obrigatório',
      },
      image: '',
      isLoadingFile: '',
      isLoading: false,
    }
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.localPrintedMaterial = { ...newValue }
      },
    },
  },
  methods: {
    emitChanges() {
      this.$emit('input', this.localPrintedMaterial)
    },
  },
}
</script>

<style>

</style>
