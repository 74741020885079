<template>
  <div
    class="d-flex flex-column"
    style="gap: 15px;"
  >
    <div>
      <span>
        Briefing
      </span>
      <v-textarea
        v-model="localBannerCreation.briefing"
        :rules="[rules.required]"
        :readonly="isJustForRead"
        class="pt-3"
        outlined
        label="Descreva as informações"
        @input="emitChanges"
      />
      <InfoTag
        :icon="icons.mdiInformationVariant"
        text="Discorra o que deve ser comunicado."
        icon-color="#fff"
        icon-background-color="#16B1FF"
      />
    </div>
    <div>
      <span>
        Onde esse Banner/Material Digital será divulgado?
      </span>
      <v-textarea
        v-model="localBannerCreation.materialDissemination"
        :rules="[rules.required]"
        :readonly="isJustForRead"
        class="pt-3"
        outlined
        label="Descreva as informações"
        @input="emitChanges"
      />
    </div>
  </div>
</template>

<script>
import InfoTag from '@/components/InfoTag.vue'
import { mdiCalendar, mdiInformationVariant } from '@mdi/js'

export default {
  name: 'BannerCreationOrDigitalMaterialForm',
  components: {
    InfoTag,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        briefing: '',
        materialDissemination: '',
      }),
    },
    isJustForRead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localBannerCreation: { ...this.value },
      icons: {
        mdiCalendar,
        mdiInformationVariant,
      },
      rules: {
        required: value => !!value || 'Este campo é obrigatório',
      },
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.localBannerCreation = { ...newValue }
      },
    },
  },
  methods: {
    emitChanges() {
      this.$emit('input', this.localBannerCreation)
    },
  },
}
</script>
