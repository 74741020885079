import {
  mdiBullseyeArrow,
} from '@mdi/js'

import localStorageSlim from 'localstorage-slim'

const userProfile = localStorageSlim.get('userProfile', { decrypt: true })
const profileName = userProfile?.profile?.profile
const profileSector = userProfile?.occupation_sector?.sector?.name

const solicitations = [
  {
    subheader: 'Solicitações',
  },
]

if ((userProfile?.occupation_sector?.is_leader || userProfile?.occupation_sector?.is_manager) && profileName === 'Marketing') {
  const marketingExists = solicitations.some(item => item.title === 'Marketing')

  if (!marketingExists) {
    solicitations.push({
      title: 'MARKETING',
      icon: mdiBullseyeArrow,
      children: [
        {
          title: 'Minhas solicitações',
          to: 'solicitations-home',
          ActiveTabColor: '#05AEFF',
          ActiveTabColor2: '#05AEFF',
          hidden: false,
        },
        {
          title: 'Painel',
          to: 'solicitations-panel',
          ActiveTabColor: '#05AEFF',
          ActiveTabColor2: '#05AEFF',
          hidden: false,
        },
        {
          title: 'Relatórios',
          to: 'solicitations-report',
          ActiveTabColor: '#05AEFF',
          ActiveTabColor2: '#05AEFF',
          hidden: false,
        },
        {
          title: 'Ferramentas',
          to: 'solicitations-tools',
          ActiveTabColor: '#05AEFF',
          ActiveTabColor2: '#05AEFF',
          hidden: false,
        },
      ],
    })
  }
} else if (profileSector === 'MARKETING') {
  const marketingExists = solicitations.some(item => item.title === 'Marketing')

  if (!marketingExists) {
    solicitations.push({
      title: 'MARKETING',
      icon: mdiBullseyeArrow,
      children: [
        {
          title: 'Painel',
          to: 'solicitations-panel',
          ActiveTabColor: '#05AEFF',
          ActiveTabColor2: '#05AEFF',
          hidden: false,
        },
        {
          title: 'Ferramentas',
          to: 'solicitations-tools',
          ActiveTabColor: '#05AEFF',
          ActiveTabColor2: '#05AEFF',
          hidden: false,
        },
      ],
    })
  }
} else {
  const marketingExists = solicitations.some(item => item.title === 'Marketing')

  if (!marketingExists) {
    solicitations.push({
      title: 'MARKETING',
      icon: mdiBullseyeArrow,
      children: [
        {
          title: 'Minhas solicitações',
          to: 'solicitations-home',
          ActiveTabColor: '#05AEFF',
          ActiveTabColor2: '#05AEFF',
          hidden: false,
        },
      ],
    })
  }
}

export default solicitations
