<template>
  <div
    class="d-flex flex-column pb-5"
    style="gap: 15px;"
  >
    <div>
      <span>Qual o problema atual?</span>
      <v-textarea
        v-model="localSalesAction.actualProblem"
        class="pt-3"
        :rules="[rules.required]"
        label="Descreva o problema"
        :readonly="isJustForRead"
        outlined
        @input="emitChanges"
      />
      <span>Qual o período da ação</span>
      <div
        class="d-flex mt-3"
        style="gap: 20px; width: 100%"
      >
        <v-menu
          ref="menu1"
          v-model="menu1"
          :disabled="isJustForRead"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="localSalesAction.initialDateFormatted"
              :rules="[rules.required]"
              style="max-width: 180px;"
              label="Data inicial"
              :append-icon="icons.mdiCalendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="localSalesAction.initialDate"
            @input="handleDateChange('initialDate')"
          ></v-date-picker>
        </v-menu>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :disabled="isJustForRead"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="localSalesAction.finalDateFormatted"
              :rules="[rules.required]"
              style="max-width: 180px;"
              label="Data final"
              :append-icon="icons.mdiCalendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="localSalesAction.finalDate"
            @input="handleDateChange('finalDate')"
          ></v-date-picker>
        </v-menu>
      </div>
      <div>
        <span>Considerando a realidade da sua cidade, quais são os melhores canais de comunicação do local?</span>
        <v-text-field
          v-model="localSalesAction.communicationChannel"
          :rules="[rules.required]"
          class="pt-3"
          :readonly="isJustForRead"
          outlined
          dense
          label="Descreva o canal de comunicação"
          @input="emitChanges"
        />
        <InfoTag
          :icon="icons.mdiInformationVariant"
          text="Exemplo: Carro de som, WhatsApp, Rádio etc."
          icon-color="#fff"
          icon-background-color="#16B1FF"
        />
      </div>
      <div class="pt-3">
        <span>Informações adicionais</span>
        <v-textarea
          v-model="localSalesAction.additionalInfo"
          :rules="[rules.required]"
          class="pt-3"
          :readonly="isJustForRead"
          outlined
          label="Descreva as informações"
          @input="emitChanges"
        />
        <InfoTag
          :icon="icons.mdiInformationVariant"
          text="Exemplo: Comparativos de resultados, comparativos entre lojas, dados de mercado ou internos para análise e elaboração de estratégias, ideias que pode ser feito etc."
          icon-color="#fff"
          icon-background-color="#16B1FF"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InfoTag from '@/components/InfoTag.vue'
import { mdiCalendar, mdiInformationVariant } from '@mdi/js'

export default {
  name: 'SalesActionForm',
  components: {
    InfoTag,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        actualProblem: '',
        communicationChannel: '',
        additionalInfo: '',
        initialDate: null,
        finalDate: null,
        initialDateFormatted: '',
        finalDateFormatted: '',
      }),
    },
    isJustForRead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      localSalesAction: { ...this.value },
      icons: {
        mdiCalendar,
        mdiInformationVariant,
      },
      rules: {
        required: value => !!value || 'Este campo é obrigatório',
      },
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.localSalesAction = {
          ...newValue,
          initialDateFormatted: this.formatDate(newValue.initialDate),
          finalDateFormatted: this.formatDate(newValue.finalDate),
        }
      },
    },
  },
  methods: {
    emitChanges() {
      this.$emit('input', this.localSalesAction)
    },
    handleDateChange(field) {
      if (field === 'initialDate') {
        this.localSalesAction.initialDateFormatted = this.formatDate(this.localSalesAction.initialDate)
      } else if (field === 'finalDate') {
        this.localSalesAction.finalDateFormatted = this.formatDate(this.localSalesAction.finalDate)
      }
      this.emitChanges()
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      return `${year}-${month}-${day}`
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
  },
}
</script>
