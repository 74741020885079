import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userProfile.occupation_sector.sector.name === 'MARKETING')?_c(VCard,[_c('div',{staticClass:"d-flex align-center justify-space-between px-5 mb-8 pt-8",staticStyle:{"width":"100%"}},[_c('h3',{staticClass:"pl-0",staticStyle:{"font-weight":"600"}},[_vm._v(" Relatórios das solicitações ")]),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"20px"}},[_c(VBtn,{staticClass:"me-3",attrs:{"outlined":"","color":"warning","disabled":_vm.isLoadingExport},on:{"click":function($event){return _vm.exportCsv()}}},[(!_vm.isLoadingExport)?_c('div',{staticClass:" d-flex items-center align-center",staticStyle:{"gap":"10px"}},[_c('span',{staticClass:"button-text-logs"},[_vm._v(" EXPORTAR ")]),_c(VImg,{attrs:{"height":"17","width":"20","src":_vm.mdiTrayArrowDown}})],1):_c(VProgressCircular,{attrs:{"color":"warning","indeterminate":""}})],1),_c(VBtn,{attrs:{"outlined":"","color":_vm.filterButtonColor},on:{"click":_vm.openModalFilterSolicitationsReport}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariant)+" ")]),_c('span',{staticClass:"button-text-actions"},[_vm._v("FILTRAR")])],1),(_vm.isFiltered)?_c(VBtn,{attrs:{"outlined":"","color":"error"},on:{"click":_vm.clearFilters}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariant)+" ")]),_c('span',{staticClass:"button-text-actions"},[_vm._v("LIMPAR")])],1):_vm._e()],1)]),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.listOfFilteredItems,"loading":_vm.loadingTable,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"item.slm",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"color":"#9A62FD","font-weight":"500","font-size":"14px"}},[_vm._v(" 0"+_vm._s(item.slm ? item.slm : '')+" ")])]}},{key:"item.expected_delivery_date",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'toBeDefined': !item.expected_delivery_date }},[_vm._v(" "+_vm._s(item.expected_delivery_date ? _vm.formatDate(item.expected_delivery_date) : 'A definir')+" ")])]}},{key:"item.completion_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.completion_date) || '-')+" ")])]}},{key:"item.requesters_expected_delivery_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.requesters_expected_delivery_date) || '-')+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatToDayMonthYear(item.created_at) || '-')+" ")])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.category ? item.category : '-')+" ")])]}},{key:"item.days_between_dates",fn:function(ref){
var item = ref.item;
return [(item.status === 'FINALIZADA')?_c('span',[_vm._v(" "+_vm._s(item.days_between_dates && item.status === 'FINALIZADA' ? ((item.days_between_dates) + " DIAS") : 'RESOLVIDA NO MESMO DIA')+" ")]):_c('span',[_vm._v(" "+_vm._s(!item.days_between_dates ? 'Ainda não finalizada' : '-')+" ")])]}},{key:"item.assigned_to_user.name",fn:function(ref){
var item = ref.item;
return [(item && item.assigned_to_user && item.assigned_to_user.name)?_c(VTooltip,{attrs:{"top":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"v-avatar-light-bg success--text",attrs:{"color":"error","size":"30"}},'v-avatar',attrs,false),on),[(item && item.assigned_to_user && item.assigned_to_user.url_photo)?_c(VImg,{attrs:{"src":item.assigned_to_user.base64}}):_c('span',{staticClass:"v-avatar-light-bg success--text",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.getInitials(item.assigned_to_user))+" ")])],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(item && item.assigned_to_user && item.assigned_to_user.name ? item.assigned_to_user.name : '-')+" ")])]):_c('span',[_vm._v("-")])]}},{key:"item.third_party.name",fn:function(ref){
var item = ref.item;
return [(item && item.third_party && item.third_party.name)?_c(VTooltip,{attrs:{"top":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({attrs:{"color":item.third_party.avatar_code ? _vm.returnCharBasedOnCharCode(item.third_party.avatar_code).bgColor: '#E7E3FC',"size":"30"}},'v-avatar',attrs,false),on),[(item && item.third_party && item.third_party.avatar_code)?_c(VIcon,{attrs:{"size":"20","color":item.third_party.avatar_code ? _vm.returnCharBasedOnCharCode(item.third_party.avatar_code).avatarColor: '#E7E3FC'}},[_vm._v(" "+_vm._s(item.third_party.avatar_code ? ("" + (_vm.icons[_vm.returnCharBasedOnCharCode(item.third_party.avatar_code).icon])) : '-')+" ")]):_c('span',{staticClass:"v-avatar-light-bg success--text",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.getInitials(item.third_party))+" ")])],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(item && item.third_party && item.third_party.name ? ("" + (item.third_party.name)) : '-')+" ")])]):_c('span',[_vm._v("-")])]}},{key:"item.third_party.third_party_activity.name",fn:function(ref){
var item = ref.item;
return [(item.third_party && item.third_party.third_party_activity)?_c('span',[_vm._v(_vm._s(item.third_party.third_party_activity.name))]):_c('span',[_vm._v("-")])]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center","gap":"10px"}},[_c(VIcon,{attrs:{"color":_vm.returnIconOrColorBasedOnSubject('color', item.subject)}},[_vm._v(" "+_vm._s(_vm.returnIconOrColorBasedOnSubject('icon', item.subject))+" ")]),_c('span',[_vm._v(" "+_vm._s(item.subject ? item.subject : '-')+" ")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VAlert,{staticClass:"badge px-5",staticStyle:{"border-radius":"50px"},attrs:{"color":_vm.getStatusColor(item),"text":""}},[_vm._v(" "+_vm._s(item.status ? item.status : '')+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"my-10"},[_c('NoDataInterfaceEditable',{attrs:{"title":"Nenhum  registro encontrado!","subtitle":"Por favor, verifique os filtros de pesquisa / Contacte a equipe de desenvolvimento."}})],1)]},proxy:true}],null,false,4143014837)}),_c(VDialog,{attrs:{"width":"600px","transition":"dialog-transition"},model:{value:(_vm.showModalFilterReports),callback:function ($$v) {_vm.showModalFilterReports=$$v},expression:"showModalFilterReports"}},[_c('ModalFilterReports',{ref:"ModalFilterReports",attrs:{"companies":_vm.companies,"requesters":_vm.requesters,"responsibles":_vm.responsibles,"sectors":_vm.sectors,"third-parties":_vm.thirdParties,"third-parties-activities":_vm.thirdPartiesActivities},on:{"close":function($event){_vm.showModalFilterReports = false},"filterData":_vm.handleFilterData}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }