<template>
  <v-card>
    <div style="width: 100%; position: relative;">
      <v-btn
        icon
        style="position: absolute; right: 20px; top: 15px;"
        @click="closeModal"
      >
        <v-icon size="30">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <div class="py-14  px-4">
      <v-img
        :src="image"
        style="border-radius: 5px;"
      >
      </v-img>
    </div>
  </v-card>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  name: 'ModalViewImage',
  props: {
    image: {
      type: String,
      required: true,
    },
  },
  data() {
    return {

      icons: {
        mdiClose,
      },
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
  },
}

</script>

<style>

</style>
