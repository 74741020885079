<template>
  <v-card>
    <div style="width: 100%; position: relative;">
      <h3 style=" font-weight: 600; font-size: 18px; text-align: center; padding-top: 30px;">
        Filtrar minhas solicitações
      </h3>
      <v-btn
        icon
        style="position: absolute; right: 20px; top: 25px;"
        @click="closeModal"
      >
        <v-icon size="30">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <div class="mx-7">
      <div class="py-6">
        <Separator label="Filtrar dados" />
      </div>
      <v-form ref="form">
        <v-text-field
          v-model="slmNumber"
          outlined
          dense
          label="N° solicitação"
        />
        <v-autocomplete
          v-model="subject"
          :items="listSubjectItems"
          label="Assunto"
          outlined
          dense
        />
        <v-autocomplete
          v-model="category"
          :disabled="!subject || subject === 'ASSINATURA DE E-MAIL'"
          :items="computedListcategory"
          label="Categoria"
          outlined
          dense
        />
        <v-autocomplete
          v-model="status"
          :items="allStatusList"
          label="Status"
          outlined
          dense
        />
        <span>Por período da solicitação:</span>
        <div
          class="d-flex mt-3"
          style="gap: 20px; width: 100%"
        >
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="initialDateFormatted"
                label="Data inicial"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(initialDateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="initialDate"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="finalDateFormatted"
                label="Data final"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(finalDateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="finalDate"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <span>Por prazo esperado pelo líder:</span>
        <div
          class="d-flex mt-3"
          style="gap: 20px; width: 100%"
        >
          <v-menu
            ref="menu3"
            v-model="menu3"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="expectedInitialDateFormatted"
                label="Data inicial"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(expectedInitialDateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="expectedInitialDate"
              @input="menu3 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="menu4"
            v-model="menu4"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="expectedFinalDateFormatted"
                label="Data final"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(expectedFinalDateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="expectedFinalDate"
              @input="menu4 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div
          class=" d-flex justify-between pb-4"
        >
          <v-btn
            v-if="isAnyFieldFilled"
            color="error"
            @click="handleClearFilter"
          >
            <span>LIMPAR</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            :disabled="!isFilterButtonEnabled"
            @click="handleFilter(true)"
          >
            <span>FILTRAR</span>
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import { mdiCalendar, mdiClose } from '@mdi/js'

export default {
  name: 'ModalFilterMySolicitations',
  components: { Separator },
  data() {
    return {
      icons: {
        mdiClose, mdiCalendar,
      },
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,

      emmitedStates: {
        data: {},
        isFiltered: false,
      },

      slmNumber: '',
      subject: '',
      category: '',
      status: '',
      finalDate: '',
      initialDate: '',

      expectedFinalDate: '',
      expectedInitialDate: '',

      salesAction: [
        'LANÇAMENTOS',
        'PROMOÇÃO',
        'QUEIMA DE ESTOQUE',
        'OUTROS',
      ],
      bannerCreation: [
        'REDES SOCIAIS',
        'OUTROS',
      ],
      printedMaterial: [
        'CAMISA',
        'CUPOM',
        'FACHADA',
        'FLYER',
        'PANFLETO',
        'PLACAS',
        'VOUCHER',
        'OUTROS',
      ],
      videoProduction: [
        'EVENTOS',
        'REDE SOCIAL',
        'TV INTERNA',
        'OUTROS',
      ],
      internComunication: [
        'PLACAS',
        'GRUPO DO WHATSAPP',
        'MURAIS',
        'OUTROS',
      ],
      templateMessage: [
        'DISPAROS BOLD',
        'MENSAGENS PARA USO NO WHATSAPP',
        'OUTROS',
      ],

      allStatusList: [
        'ENVIADAS PARA VALIDAÇÃO',
        'EM ANDAMENTO',
        'EM EXECUÇÃO COM TERCEIRO',
        'ENVIADA AO SETOR DE COMPRAS',
        'FINALIZADA',
        'FINALIZADA',
        'NÃO INICIADA',
      ],
      listSubjectItems: [
        'AÇÃO DE VENDAS',
        'ASSINATURA DE E-MAIL',
        'BANNER/MATERIAL DIGITAL',
        'COMUNICAÇÃO INTERNA',
        'PRODUÇÃO DE MATERIAL',
        'MENSAGEM TEMPLATE',
        'PRODUÇÃO DE VÍDEO',
      ],
      initialDateFormatted: '',
      finalDateFormatted: '',
      expectedInitialDateFormatted: '',
      expectedFinalDateFormatted: '',

    }
  },
  computed: {
    computedListcategory() {
      const subjectMap = {
        'AÇÃO DE VENDAS': this.salesAction,
        'BANNER/MATERIAL DIGITAL': this.bannerCreation,
        'PRODUÇÃO DE MATERIAL': this.printedMaterial,
        'PRODUÇÃO DE VÍDEO': this.videoProduction,
        'COMUNICAÇÃO INTERNA': this.internComunication,
        'MENSAGEM TEMPLATE': this.templateMessage,
      }

      return subjectMap[this.subject] || null
    },
    isAnyFieldFilled() {
      return (
        this.slmNumber
        || this.subject
        || this.initialDate
        || this.finalDate
        || this.category
        || this.status
        || this.expectedInitialDate
        || this.expectedFinalDate
      )
    },
    isFilterButtonEnabled() {
      return this.isAnyFieldFilled
    },
  },

  watch: {
    finalDate() {
      this.finalDateFormatted = this.formatDate(this.finalDate)
    },
    initialDate() {
      this.initialDateFormatted = this.formatDate(this.initialDate)
    },
    expectedFinalDate() {
      this.expectedFinalDateFormatted = this.formatDate(this.expectedFinalDate)
    },
    expectedInitialDate() {
      this.expectedInitialDateFormatted = this.formatDate(this.expectedInitialDate)
    },
  },
  methods: {
    convertStatusName(status) {
      const statusMap = {
        'NÃO INICIADA': 'new_solicitations',
        'EM ANDAMENTO': 'in_progress',
        'EM EXECUÇÃO COM TERCEIRO': 'in_execution',
        'ENVIADAS PARA VALIDAÇÃO': 'awaiting_approval',
        'ENVIADA AO SETOR DE COMPRAS': 'sent',
        FINALIZADA: 'completed',
      }

      return statusMap[status]
    },
    async handleFilter(isResetFilter = false) {
      const body = {
        status: this.convertStatusName(this.status),
        slm: this.slmNumber,
        initial_date: this.initialDate,
        final_date: this.finalDate,
        requesters_start_date: this.expectedInitialDate,
        requesters_end_date: this.expectedFinalDate,
        subject: this.subject,
        category: this.category,
      }
      try {
        const { data } = await axiosIns.post('/api/v1/solicitations/marketing/filter_created_by', body)
        this.emmitedStates.data = data
        this.emmitedStates.isFiltered = isResetFilter
        this.$emit('filterData', this.emmitedStates)
        if (isResetFilter) {
          this.closeModal()
        }
      } catch (error) {
        console.error('Erro ao buscar dados do filtro solicitado pelo usuário', error)
      }
    },
    closeModal() {
      this.$emit('close')
    },
    handleClearFilter() {
      if (!this.emmitedStates.isFiltered) {
        this.handleClearFilterFields()

        return
      }
      this.$refs.form.reset()
      this.initialDate = ''
      this.finalDate = ''
      this.expectedFinalDate = ''
      this.expectedInitialDate = ''
      this.handleFilter(false)
    },
    handleClearFilterFields() {
      this.$refs.form.reset()
      this.expectedFinalDate = ''
      this.expectedInitialDate = ''
      this.initialDate = ''
      this.finalDate = ''
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      return `${year}-${month}-${day}`
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
  },

}
</script>

<style>

</style>
