<template>
  <div>
    <div
      class="d-flex"
      style="gap: 11px;"
    >
      <v-tooltip
        v-if="item && item.user"
        top
        color="white"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            v-bind="attrs"
            size="30"
            color="error"
            class="v-avatar-light-bg success--text"
            v-on="on"
          >
            <v-img
              v-if="item && item.user.url_photo"
              :src="item.user.base64"
            />
            <span
              v-else
              class="v-avatar-light-bg success--text"
              style="font-size: 13px;"
            >
              {{ getInitials(item.user) }}
            </span>
          </v-avatar>
        </template>
        <span style="color: black;">
          {{ item && item.user && item.user.name ? item.user.name : '-' }}
        </span>
      </v-tooltip>
      <span v-else>-</span>
      <div
        class="d-flex flex-column"
        style="font-size: 12px; font-weight: 500;"
      >
        <span v-if="item.type === 'comment' && item.need_validation">
          {{ item.user.name }} fez um comentário que necessita de validação
        </span>
        <span v-else-if="item.type === 'comment' && !item.need_validation">
          {{ item.user.name }} fez um comentário
        </span>
        <span v-if="item.type === 'assigned_to'">
          {{ item.user.name }} definiu o/a responsável {{ item.content }}
        </span>
        <span v-if="item.type === 'status'">
          {{ item.user.name }} definiu o status {{ item.content }}
        </span>
        <span v-if="item.type === 'expected_delivery_date'">
          {{ item.user.name }} definiu a data de previsão para {{ formatPrevisionDate(item.content) }}
        </span>
        <span v-if="item.type === 'third_parties_id'">
          {{ item.user.name }}{{ item.first_third_party ? ' adicionou o terceiro ' : ' modificou o terceiro para ' }}{{ item.content }}
        </span>
        <span>{{ formatDate(item.created_at) }}</span>
      </div>
    </div>
    <div
      v-if="item.type === 'comment'"
      class="d-flex flex-column"
      style="padding-left: 40px; margin-top: 13px; gap: 10px;"
    >
      <span style="font-size: 14px; font-weight: 500;">{{ item.comment }}</span>
      <div
        class="d-flex"
        style="gap: 6px; width: 100%; flex-wrap: wrap;"
      >
        <v-chip
          v-if="!!item.link"
          small
          color="#E7E3FC"
          outlined
          dense
          @click="handleOpenLink(item.link)"
        >
          <span>Link</span>
        </v-chip>
        <v-chip
          v-for="file in item.attachments"
          v-show="computedViewFileContainer"
          :key="file.id"
          small
          color="#E7E3FC"
          outlined
          dense
          :disabled="isLoading"
          @click="downloadFile(file.id, file.name)"
        >
          <span v-if="file.id === isLoadingFile ? false : true">{{ file.name }}</span>

          <v-progress-circular
            v-else
            :key="file.id"
            :size="25"
            color="white"
            indeterminate
          />
        </v-chip>
      </div>
      <div v-if="item.validation">
        <v-tooltip
          top
          :color="getColorBasedOnValidation(item.validation)"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              outlined
              :color="getColorBasedOnValidation(item.validation)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="20">
                {{ getIconBasedOnValidation(item.validation) }}
              </v-icon>
            </v-btn>
          </template>
          <span style="color: white;">{{ item.validation === 'APPROVED' ? 'Aprovado!' : 'Requer alterações' }}</span>
        </v-tooltip>
      </div>
      <div
        v-if="item.need_validation && item.solicitation_creator === LoggedUserId && solicData.status === 'ENVIADA PARA VALIDAÇÃO'"
        class="d-flex"
      >
        <v-btn
          color="purple"
          @click="openValidationModal"
        >
          <div
            class="d-flex"
            style="align-items: center; gap: 10px; color: #fff;"
          >
            <v-icon size="20">
              {{ icons.mdiStickerCheckOutline }}
            </v-icon>
            <span>
              Validar
            </span>
          </div>
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="showModalRequesterValidation"
      width="700px"
      transition="dialog-transition"
    >
      <ModalRequesterValidation
        :item="item"
        @close="showModalRequesterValidation = false"
        @get-table-data="emmitGetTableData"
        @getHistory="emmitGetHistory"
      />
    </v-dialog>
  </div>
</template>

<script>
import axiosFileApi from '@/plugins/axiosFileApi'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiHeart, mdiPencil, mdiStickerCheckOutline } from '@mdi/js'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import ModalRequesterValidation from './modais/ModalRequesterValidation.vue'

export default {
  name: 'RenderTimelineItem',
  components: {
    ModalRequesterValidation,
  },
  mixins: [messages, formatters],
  props: {
    item: {
      type: Object,
      required: true,
    },
    solicData: {
      type: Object,
      required: true,
    },
    formatDate: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isLoadingGetFile: false,
      isViewingFile: false,
      isLoadingFile: false,
      LoggedUserId: '',
      isLoading: false,
      showModalRequesterValidation: false,
      icons: {
        mdiHeart,
        mdiPencil,
        mdiStickerCheckOutline,
      },
    }
  },
  computed: {
    computedViewFileContainer() {
      return !this.isLoadingGetFile
    },
  },
  created() {
    const userProfile = localStorageSlim.get('userProfile', { decrypt: true })
    this.LoggedUserId = userProfile.id
  },
  methods: {
    getIconBasedOnValidation(validation) {
      return validation === 'APPROVED' ? this.icons.mdiHeart : this.icons.mdiPencil
    },
    getColorBasedOnValidation(validation) {
      return validation === 'APPROVED' ? 'success' : 'info'
    },
    emmitGetHistory() {
      this.$emit('getHistory')
    },
    emmitGetTableData() {
      this.$emit('get-table-data')
    },
    openValidationModal() {
      this.showModalRequesterValidation = true
    },
    getInitials(user) {
      if (!user || !user.name) {
        return '-'
      }
      const names = user.name.split(' ')
      if (names.length === 1) {
        return names[0].charAt(0).toUpperCase()
      }
      const firstInitial = names[0].charAt(0).toUpperCase()
      const lastInitial = names[names.length - 1].charAt(0).toUpperCase()

      return firstInitial + lastInitial
    },
    handleOpenLink(link) {
      window.open(link, '_blank')
    },
    async downloadFile(fileId, fileName) {
      this.isLoading = true
      this.isLoadingFile = fileId

      const body = { id: fileId }

      await axiosFileApi
        .post('/api/v1/solicitations/marketing_comment_attachment/view_files', body, { responseType: 'arraybuffer' })
        .then(response => {
          const contentType = response.headers['content-type']
          const file = new File([response.data], fileName, {
            type: contentType,
          })

          saveAs(file)
        })
        .catch(error => {
          console.error('Erro na hora de bucar o arquivo', error)
          this.$swal({
            icon: 'error',
            title: 'Erro ao buscar arquivo!',
            text: 'Ocorreu um erro ao buscar o seu arquivo, contate a equipe de desenvolvimento.',
          })
        })
        .finally(() => {
          this.isLoading = false
          this.isLoadingFile = ''
        })
    },
    formatPrevisionDate(date) {
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
  },
}
</script>
