<template>
  <div
    class="infoTag"
    :style="{ backgroundColor: tagBackground }"
  >
    <div
      class="infoTagIcon"
      :style="{ backgroundColor: iconBackgroundColor }"
    >
      <v-icon :color="iconColor">
        {{ icon }}
      </v-icon>
    </div>
    <span class="infoTagText">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'InfoTag',
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      default: '#FFFFFF', // cor padrão do ícone
    },
    iconBackgroundColor: {
      type: String,
      default: '#1E1E1E4D',
    },
    tagBackground: {
      type: String,
      default: '#1E1E1E4D',
    },
  },
}
</script>

<style scoped>
  .infoTag {
    background: #1E1E1E4D;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .infoTagIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 3px;
  }
  .infoTagText {
    font-weight: 400;
    font-size: 13px;
    color: #fff;
    text-align: justify;
  }
</style>
