<template>
  <v-tabs
    v-if="userProfile.occupation_sector.sector.name === 'MARKETING'"
    v-model="activeTab"
    style="border-radius: 10px 10px 0px 0px"
  >
    <v-tab>
      <div style="display: flex; align-items: center; gap: 5px">
        <v-icon>{{ icons.mdiAccountSupervisor }}</v-icon>
        <span>TERCEIRIZADOS</span>
      </div>
    </v-tab>
    <v-tab-item>
      <div class="mt-4">
        <v-data-table
          :headers="headersThirdParties"
          :items="listOfFilteredThirdParties"
          :loading="loadingTableThird"
          loading-text="Carregando dados..."
          class="text-no-wrap"
        >
          <template v-slot:item.third_party_activity.name="{ item }">
            <span>
              {{ item.third_party_activity.name.toUpperCase() }}
            </span>
          </template>
          <template v-slot:item.name="{ item }">
            <span>
              {{ item.name.toUpperCase() }}
            </span>
          </template>
          <template v-slot:item.options="{ item }">
            <div
              class="d-flex"
              style="align-items: center; gap: 10px; justify-content: center;"
            >
              <v-btn
                icon
                color="error"
                @click="deleteRegisterSol(thirdPartyDeleteEndpoint, item.id)"
              >
                <v-icon>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
              <v-btn
                icon
                @click="openThirdPartyEditModal(item)"
              >
                <v-icon>
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template
            v-slot:no-data
          >
            <div class="my-10">
              <NoDataInterfaceEditable
                title="Nenhum  registro encontrado!"
                subtitle="Por favor, verifique os filtros de pesquisa / Contacte a equipe de desenvolvimento."
              />
            </div>
          </template>
        </v-data-table>
      </div>
    </v-tab-item>
    <v-tab>
      <div style="display: flex; align-items: center; gap: 5px">
        <v-icon>{{ icons.mdiTextBoxOutline }}</v-icon>
        <span>ATIVIDADES</span>
      </div>
    </v-tab>
    <v-tab-item>
      <div class="mt-4">
        <v-data-table
          :headers="headersActivities"
          :items="listOfFilteredActivities"
          :loading="loadingTableActivities"
          loading-text="Carregando dados..."
          class="text-no-wrap"
        >
          <template v-slot:item.name="{ item }">
            <span>{{ item.name.toUpperCase() }}</span>
          </template>
          <template v-slot:item.options="{ item }">
            <div
              class="d-flex"
              style="align-items: center; gap: 10px; justify-content: center;"
            >
              <v-btn
                icon
                color="error"
                @click="deleteRegisterSol(activitieDeleteEndpoint, item.id)"
              >
                <v-icon>
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
              <v-btn
                icon
                @click="openActivityEditModal(item)"
              >
                <v-icon>
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template
            v-slot:no-data
          >
            <div class="my-10">
              <NoDataInterfaceEditable
                title="Nenhum  registro encontrado!"
                subtitle="Por favor, verifique os filtros de pesquisa / Contacte a equipe de desenvolvimento."
              />
            </div>
          </template>
        </v-data-table>
      </div>
    </v-tab-item>
    <v-spacer />

    <div
      class="d-flex px-4 "
      style="padding: 10px; gap: 20px;"
    >
      <v-text-field
        v-model="searchTerm"
        :prepend-inner-icon="icons.mdiMagnify"
        style="margin-top: -2px;"
        :label="`Buscar ${getActiveTabText()}`"
        outlined
        dense
      />

      <v-btn
        style="margin-top: -1px;"
        color="info"
        outlined
        @click="openModalCreate()"
      >
        <v-icon
          size="25"
          class="mr-2"
        >
          {{ icons.mdiPlaylistPlus }}
        </v-icon>

        <span> Novo </span>
      </v-btn>
    </div>
    <v-dialog
      v-model="showModalNewThirdParty"
      width="700px"
    >
      <NewThirdPartyModal
        :key="activeTab"
        @updatedTable="getAllData()"
        @close="showModalNewThirdParty = false"
      />
    </v-dialog>
    <v-dialog
      v-model="showModalNewActivity"
      width="700px"
    >
      <NewActivityModal
        :key="activeTab"
        @updatedTable="getAllData()"
        @close="showModalNewActivity = false"
      />
    </v-dialog>
    <v-dialog
      v-model="showModalEditActivity"
      width="700px"
    >
      <EditActivityModal
        :id="activity.id"
        :key="EditActivityModalKey"
        :name="activity.name"
        @updatedTable="getAllData()"
        @regenerateKey="generateNewModalEditKey()"
        @close="showModalEditActivity = false"
      />
    </v-dialog>
    <v-dialog
      v-model="showModalEditThirdParty"
      width="700px"
    >
      <EditThirdPartyModal
        :id="thirdParty.id"
        :key="EditThirdPartyModalKey"
        :name="thirdParty.name"
        :activity="thirdParty.third_party_activity_id"
        :cpf-cnpj="thirdParty.cpf_cnpj"
        :char-code="thirdParty.avatar_code"
        @updatedTable="getAllData()"
        @regenerateKey="generateNewModalEditKey()"
        @close="showModalEditThirdParty = false"
      />
    </v-dialog>
  </v-tabs>
</template>

<script>
import NoDataInterfaceEditable from '@/components/charts/NoDataInterfaceEditable.vue'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/plugins/axios'
import localStorageSlim from 'localstorage-slim'
// eslint-disable-next-line import/no-cycle
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiAccountSupervisor,
  mdiEyeOutline,
  mdiMagnify, mdiPlaylistPlus,
  mdiTextBoxOutline, mdiTrashCanOutline,
} from '@mdi/js'
import EditActivityModal from './modals/EditActivityModal.vue'
import EditThirdPartyModal from './modals/EditThirdPartyModal.vue'
import NewActivityModal from './modals/NewActivityModal.vue'
import NewThirdPartyModal from './modals/NewThirdPartyModal.vue'

export default {
  name: 'SolicitationsTools',
  components: {
    NewThirdPartyModal, NewActivityModal, NoDataInterfaceEditable, EditActivityModal, EditThirdPartyModal,
  },
  mixins: [messages, formatters],
  data() {
    return {
      icons: {
        mdiAccountSupervisor, mdiTextBoxOutline, mdiMagnify, mdiPlaylistPlus, mdiTrashCanOutline, mdiEyeOutline,
      },
      headersThirdParties: [
        {
          text: 'NOME', value: 'name', sortable: false, align: 'left',
        },
        {
          text: 'CPF/CNPJ', value: 'cpf_cnpj', sortable: false, align: 'left',
        },
        {
          text: 'ATIVIDADE', value: 'third_party_activity.name', sortable: false, align: 'left',
        },
        {
          text: 'OPÇÕES', value: 'options', sortable: false, align: 'center',
        },
      ],
      headersActivities: [
        {
          text: 'ATIVIDADE', value: 'name', sortable: false, align: 'left',
        },
        {
          text: 'OPÇÕES', value: 'options', sortable: false, align: 'center',
        },
      ],
      activitieDeleteEndpoint: '/api/v1/solicitations/marketing_third_parties_activity/delete',
      thirdPartyDeleteEndpoint: '/api/v1/solicitations/marketing_third_parties/delete',
      searchTerm: '',
      activeTab: null,
      showModalNewThirdParty: false,
      showModalNewActivity: false,
      showModalEditActivity: false,
      showModalEditThirdParty: false,

      userProfile: {},

      thirdParty: {},

      activity: {},

      listOfFilteredThirdParties: [],
      listOfThirdParties: [],
      loadingTableThird: false,

      EditActivityModalKey: Math.random(),
      EditThirdPartyModalKey: Math.random(),

      listOfFilteredActivities: [],
      listOfActivities: [],
      loadingTableActivities: false,
    }
  },
  watch: {
    searchTerm() {
      this.findRegister()
    },
  },
  created() {
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
    this.getAllData()
  },
  methods: {
    findRegister() {
      const searchTerm = this.searchTerm.toLowerCase()

      if (this.activeTab === 0) {
        this.listOfFilteredThirdParties = this.listOfThirdParties.filter(thirdParty => {
          const thirdPartyName = thirdParty?.name?.toLowerCase() || ''
          const marketingName = thirdParty?.marketing_third_parties_activities?.name?.toLowerCase() || ''

          return thirdPartyName.includes(searchTerm) || marketingName.includes(searchTerm)
        })
      }

      if (this.activeTab === 1) {
        this.listOfFilteredActivities = this.listOfActivities.filter(activitie => activitie?.name?.toLowerCase().includes(searchTerm))
      }
    },

    generateNewModalEditKey() {
      this.EditActivityModalKey = Math.random()
      this.EditThirdPartyModalKey = Math.random()
    },

    getActiveTabText() {
      const tabMap = {
        0: 'tercerizados',
        1: 'atividades',
      }

      return tabMap[this.activeTab]
    },
    async deleteRegisterSol(endpoint, id, title, text) {
      const confirmed = await this.confirmDeletion(title, text)
      if (!confirmed) {
        this.showCancelledMessage()

        return
      }

      try {
        this.showLoadingMessage()
        await axiosIns.put(`${endpoint}/${id}`)
        this.$swal.close()

        this.showSuccessMessage()
        this.getAllData()
      } catch (error) {
        this.showErrorMessage()
      } finally {
        this.closeModal()
      }
    },
    async getAllData() {
      try {
        const [listOfThirdParties, listOfActivities] = await Promise.all([this.getThirdParties(), this.getActivities()])

        this.listOfFilteredThirdParties = listOfThirdParties
        this.listOfThirdParties = listOfThirdParties

        this.listOfActivities = listOfActivities
        this.listOfFilteredActivities = listOfActivities
      } catch (error) {
        console.error('Erro ao buscar todos os dados, ou do terceirizado ou das atividades', error)
      }
    },
    async getThirdParties() {
      this.loadingTableThird = true
      try {
        const res = await axiosIns.get('/api/v1/solicitations/marketing_third_parties/index')

        return res.data.data
      } catch (error) {
        console.error('erro ao buscar dados dos terceirizados', error)

        return []
      } finally {
        this.loadingTableThird = false
      }
    },
    async getActivities() {
      this.loadingTableActivities = true
      try {
        const res = await axiosIns.get('/api/v1/solicitations/marketing_third_parties_activity/index')

        return res.data.data
      } catch (error) {
        console.error('erro ao buscar dados das atividades', error)

        return []
      } finally {
        this.loadingTableActivities = false
      }
    },
    openModalCreate() {
      if (this.activeTab === 0) {
        this.showModalNewThirdParty = true
      }
      if (this.activeTab === 1) {
        this.showModalNewActivity = true
      }
    },
    openActivityEditModal(activity) {
      this.generateNewModalEditKey()
      this.activity = activity
      this.showModalEditActivity = true
    },
    openThirdPartyEditModal(thirdParty) {
      this.generateNewModalEditKey()
      this.thirdParty = thirdParty
      this.showModalEditThirdParty = true
    },
  },
}
</script>

<style scoped>

</style>
