<template>
  <div
    class="d-flex flex-column"
    style="gap: 15px;"
  >
    <div>
      <span>
        Briefing
      </span>
      <v-textarea
        v-model="localVideoProduction.briefing"
        :readonly="isJustForRead"
        :rules="[rules.required]"
        class="pt-3"
        outlined
        label="Descreva as informações"
        @input="emitChanges"
      />
      <InfoTag
        :icon="icons.mdiInformationVariant"
        text="Discorra sobre a sua solicitação do seu vídeo, informando o que de ser comunicado, e caso já tenha alguma ideia em mente, favor descrevê-la."
        icon-color="#fff"
        icon-background-color="#16B1FF"
      />
    </div>
    <div>
      <span>
        Onde será utilizado?
      </span>
      <v-textarea
        v-model="localVideoProduction.placeOfUse"
        :readonly="isJustForRead"
        :rules="[rules.required]"
        class="pt-3"
        outlined
        label="Descreva onde"
        @input="emitChanges"
      />
    </div>
    <div>
      <span>Formato</span>
      <v-autocomplete
        v-model="localVideoProduction.videoFormat"
        :readonly="isJustForRead"
        :items="videoFormatItems"
        label="Escolha o formato"
        outlined
        dense
        class="pt-3"
        @input="emitChanges"
      />
    </div>
  </div>
</template>

<script>
import InfoTag from '@/components/InfoTag.vue'
import { mdiInformationVariant } from '@mdi/js'

export default {
  name: 'VideoProductionForm',
  components: {
    InfoTag,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        placeOfUse: '',
        videoFormat: '',
        briefing: '',
      }),
    },
    isJustForRead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localVideoProduction: { ...this.value },
      icons: {
        mdiInformationVariant,
      },
      rules: {
        required: value => !!value || 'Este campo é obrigatório',
      },
      videoFormatItems: [
        'REELS INSTAGRAM (FORMATO VERTICAL)',
        'VÍDEO INSTAGRAM (FORMATO HORIZONTAL)',
        'VÍDEO PARA APRESENTAÇÃO DE SLIDES',
        'VÍDEO PARA TV',
      ],
    }
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.localVideoProduction = { ...newValue }
      },
    },
  },
  methods: {
    emitChanges() {
      this.$emit('input', this.localVideoProduction)
    },
  },
}
</script>

<style>

</style>
