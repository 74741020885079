<template>
  <v-card>
    <div style="width: 100%; position: relative;">
      <h3 style=" font-weight: 600; font-size: 18px; text-align: center; padding-top: 30px;">
        Filtrar solicitações
      </h3>
      <v-btn
        icon
        style="position: absolute; right: 20px; top: 25px;"
        @click="closeModal"
      >
        <v-icon size="30">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <div class="mx-7">
      <div class="py-6">
        <Separator label="Filtrar dados" />
      </div>
      <v-form
        ref="form"
        @submit.prevent
      >
        <div
          class="d-flex"
          style="gap: 20px; width: 100%;"
        >
          <v-text-field
            v-model="slmNumber"
            style="width: 50%;"
            outlined
            dense
            label="N° solicitação"
          />
          <v-autocomplete
            v-model="subject"
            style="width: 50%;"
            :items="listSubjectItems"
            label="Assunto"
            outlined
            dense
          />
        </div>
        <div
          class="d-flex"
          style="gap: 20px"
        >
          <v-autocomplete
            v-model="category"
            style="width: 50%;"
            :disabled="!subject || subject === 'ASSINATURA DE E-MAIL'"
            :items="computedListcategory"
            label="Categoria"
            outlined
            dense
          />
          <v-autocomplete
            v-model="companyId"
            style="width: 50%;"
            :items="companies"
            item-text="fantasy_name"
            item-value="id"
            label="Loja"
            outlined
            dense
          />
        </div>
        <div
          class="d-flex"
          style="gap: 20px"
        >
          <v-autocomplete
            v-model="sectorId"
            style="width: 50%;"
            :items="sectors"
            label="Departamento"
            item-text="name"
            item-value="id"
            outlined
            dense
          />
          <v-autocomplete
            v-model="status"
            style="width: 50%;"
            :items="allStatusList"
            label="Status"
            outlined
            dense
          />
        </div>
        <v-autocomplete
          v-model="createdById"
          :items="requesters"
          item-text="name"
          item-value="id"
          label="Nome do solicitante"
          outlined
          dense
        />
        <v-autocomplete
          v-model="responsibleId"
          :items="responsibles"
          label="Nome do responsavel"
          item-text="name"
          item-value="id"
          outlined
          dense
        />
        <div
          class="d-flex"
          style="gap: 20px"
        >
          <v-autocomplete
            v-model="thirdPartyId"
            style="width: 50%;"
            :items="thirdParties"
            label="Nome do terceiro"
            item-text="name"
            item-value="id"
            outlined
            dense
          />
          <v-autocomplete
            v-model="thirdPartyActivityId"
            style="width: 50%;"
            :items="thirdPartiesActivities"
            item-text="name"
            item-value="id"
            label="Função do terceiro"
            outlined
            dense
          />
        </div>

        <span>Por data da solicitação:</span>
        <div
          class="d-flex mt-3"
          style="gap: 20px; width: 100%"
        >
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="solicitationStartFormatted"
                label="Data inicial"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(solicitationStartFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="solicitationStartDate"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="solicitationEndFormatted"
                label="Data final"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(solicitationEndFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="solicitationEndDate"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <span>Por finalizada em:</span>
        <div
          class="d-flex mt-3"
          style="gap: 20px; width: 100%"
        >
          <v-menu
            ref="menu3"
            v-model="menu3"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="finishedStartFormatted"
                label="Data inicial"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(finishedStartFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="finishedStartDate"
              @input="menu3 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="menu4"
            v-model="menu4"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="finishedEndFormatted"
                label="Data final"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(finishedEndFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="finishedEndDate"
              @input="menu4 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <span>Por previsão de entrega:</span>
        <div
          class="d-flex mt-3"
          style="gap: 20px; width: 100%"
        >
          <v-menu
            ref="menu5"
            v-model="menu5"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="deliveryStartFormatted"
                label="Data inicial"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(deliveryStartFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="deliveryStartDate"
              @input="menu5 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="menu6"
            v-model="menu6"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="deliveryEndFormatted"
                label="Data final"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(deliveryEndFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="deliveryEndDate"
              @input="menu6 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <span>Por prazo esperado pelo líder:</span>
        <div
          class="d-flex mt-3"
          style="gap: 20px; width: 100%"
        >
          <v-menu
            ref="menu7"
            v-model="menu7"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="expectedInitialDateFormatted"
                label="Data inicial"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(expectedInitialDateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="expectedInitialDate"
              @input="menu7 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            ref="menu8"
            v-model="menu8"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="expectedFinalDateFormatted"
                label="Data final"
                :append-icon="icons.mdiCalendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                @blur="date = parseDate(expectedFinalDateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="expectedFinalDate"
              @input="menu8 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div
          class=" d-flex justify-between pb-4"
        >
          <v-btn
            v-if="isAnyFieldFilled"
            color="error"
            @click="handleClearFilter"
          >
            <span>LIMPAR</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            color="info"
            :disabled="!isFilterButtonEnabled"
            @click="handleFilter(true)"
          >
            <span>FILTRAR</span>
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import { mdiCalendar, mdiClose } from '@mdi/js'

export default {
  name: 'ModalFilterMySolicitations',
  components: { Separator },
  props: {
    companies: {
      type: Array,
      required: true,
    },
    requesters: {
      type: Array,
      required: true,
    },
    thirdParties: {
      type: Array,
      required: true,
    },
    responsibles: {
      type: Array,
      required: true,
    },
    thirdPartiesActivities: {
      type: Array,
      required: true,
    },
    sectors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiClose, mdiCalendar,
      },
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,

      emmitedStates: {
        data: {},
        isFiltered: false,
      },

      slmNumber: '',
      subject: '',
      category: '',
      createdById: '',
      companyId: '',
      sectorId: '',
      thirdPartyId: '',
      thirdPartyActivityId: '',
      responsibleId: '',
      status: '',
      expectedFinalDate: '',
      expectedInitialDate: '',
      expectedInitialDateFormatted: '',
      expectedFinalDateFormatted: '',

      salesAction: [
        'LANÇAMENTOS',
        'PROMOÇÃO',
        'QUEIMA DE ESTOQUE',
        'OUTROS',
      ],
      bannerCreation: [
        'REDES SOCIAIS',
        'OUTROS',
      ],
      printedMaterial: [
        'CAMISA',
        'CUPOM',
        'FACHADA',
        'FLYER',
        'PANFLETO',
        'PLACAS',
        'VOUCHER',
        'OUTROS',
      ],
      videoProduction: [
        'EVENTOS',
        'REDE SOCIAL',
        'TV INTERNA',
        'OUTROS',
      ],
      internComunication: [
        'PLACAS',
        'GRUPO DO WHATSAPP',
        'MURAIS',
        'OUTROS',
      ],
      templateMessage: [
        'DISPAROS BOLD',
        'MENSAGENS PARA USO NO WHATSAPP',
        'OUTROS',
      ],

      allStatusList: [
        'ENVIADAS PARA VALIDAÇÃO',
        'EM ANDAMENTO',
        'EM EXECUÇÃO COM TERCEIRO',
        'ENVIADA AO SETOR DE COMPRAS',
        'FINALIZADA',
        'FINALIZADA',
        'NÃO INICIADA',
      ],
      listSubjectItems: [
        'AÇÃO DE VENDAS',
        'ASSINATURA DE E-MAIL',
        'BANNER/MATERIAL DIGITAL',
        'COMUNICAÇÃO INTERNA',
        'PRODUÇÃO DE MATERIAL',
        'MENSAGEM TEMPLATE',
        'PRODUÇÃO DE VÍDEO',
      ],
      solicitationEndDate: '',
      solicitationStartDate: '',
      solicitationStartFormatted: '',
      solicitationEndFormatted: '',

      finishedStartDate: '',
      finishedEndDate: '',
      finishedStartFormatted: '',
      finishedEndFormatted: '',

      deliveryStartDate: '',
      deliveryEndDate: '',
      deliveryStartFormatted: '',
      deliveryEndFormatted: '',

    }
  },
  computed: {
    computedListcategory() {
      const subjectMap = {
        'AÇÃO DE VENDAS': this.salesAction,
        'BANNER/MATERIAL DIGITAL': this.bannerCreation,
        'PRODUÇÃO DE MATERIAL': this.printedMaterial,
        'PRODUÇÃO DE VÍDEO': this.videoProduction,
        'COMUNICAÇÃO INTERNA': this.internComunication,
        'MENSAGEM TEMPLATE': this.templateMessage,
      }

      return subjectMap[this.subject] || null
    },
    isAnyFieldFilled() {
      return (
        this.slmNumber
        || this.subject
        || this.finishedEndDate
        || this.finishedStartDate
        || this.solicitationEndDate
        || this.solicitationStartDate
        || this.deliveryStartDate
        || this.deliveryEndDate
        || this.category
        || this.status
        || this.createdById
        || this.companyId
        || this.sectorId
        || this.thirdPartyId
        || this.thirdPartyActivityId
        || this.responsibleId
        || this.expectedInitialDate
        || this.expectedFinalDate
      )
    },
    isFilterButtonEnabled() {
      return this.isAnyFieldFilled
    },
  },

  watch: {
    expectedFinalDate() {
      this.expectedFinalDateFormatted = this.formatDate(this.expectedFinalDate)
    },
    expectedInitialDate() {
      this.expectedInitialDateFormatted = this.formatDate(this.expectedInitialDate)
    },
    finishedEndDate() {
      this.finishedEndFormatted = this.formatDate(this.finishedEndDate)
    },
    finishedStartDate() {
      this.finishedStartFormatted = this.formatDate(this.finishedStartDate)
    },
    solicitationEndDate() {
      this.solicitationEndFormatted = this.formatDate(this.solicitationEndDate)
    },
    solicitationStartDate() {
      this.solicitationStartFormatted = this.formatDate(this.solicitationStartDate)
    },
    deliveryStartDate() {
      this.deliveryStartFormatted = this.formatDate(this.deliveryStartDate)
    },
    deliveryEndDate() {
      this.deliveryEndFormatted = this.formatDate(this.deliveryEndDate)
    },
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === 'Enter') {
        this.handleFilter(true)
      }
    },
    convertStatusName(status) {
      const statusMap = {
        'NÃO INICIADA': 'new_solicitations',
        'EM ANDAMENTO': 'in_progress',
        'EM EXECUÇÃO COM TERCEIRO': 'in_execution',
        'ENVIADAS PARA VALIDAÇÃO': 'awaiting_approval',
        'ENVIADA AO SETOR DE COMPRAS': 'sent',
        FINALIZADA: 'completed',
      }

      return statusMap[status]
    },
    async handleFilter(isResetFilter = false) {
      const body = {
        status: this.convertStatusName(this.status),
        slm: this.slmNumber,
        requesters_start_date: this.expectedInitialDate,
        requesters_end_date: this.expectedFinalDate,
        subject: this.subject,
        category: this.category,
        created_by: this.createdById,
        company_id: this.companyId,
        sector_id: this.sectorId,
        third_party_id: this.thirdPartyId,
        third_party_activity_id: this.thirdPartyActivityId,
        assigned_to: this.responsibleId,
        final_date: this.solicitationEndDate,
        initial_date: this.solicitationStartDate,
        finished_start_date: this.finishedStartDate,
        finished_end_date: this.finishedEndDate,
        delivery_start_date: this.deliveryStartDate,
        delivery_end_date: this.deliveryEndDate,
      }
      try {
        const { data } = await axiosIns.post('/api/v1/solicitations/marketing/filter', body)

        this.emmitedStates.data = data.data
        this.emmitedStates.isFiltered = isResetFilter
        this.$emit('filterData', this.emmitedStates)
        if (isResetFilter) {
          this.closeModal()
        }
      } catch (error) {
        console.error('Erro ao buscar dados do filtro solicitado pelo usuário', error)
      }
    },
    closeModal() {
      this.$emit('close')
    },
    handleClearFilter() {
      if (!this.emmitedStates.isFiltered) {
        this.handleClearFilterFields()

        return
      }
      this.$refs.form.reset()
      this.solicitationEndDate = ''
      this.solicitationStartDate = ''
      this.finishedStartDate = ''
      this.finishedEndDate = ''
      this.deliveryStartDate = ''
      this.deliveryEndDate = ''
      this.expectedFinalDate = ''
      this.expectedInitialDate = ''
      this.handleFilter(false)
    },
    handleClearFilterFields() {
      this.$refs.form.reset()
      this.solicitationEndDate = ''
      this.solicitationStartDate = ''
      this.finishedStartDate = ''
      this.expectedFinalDate = ''
      this.expectedInitialDate = ''
      this.finishedEndDate = ''
      this.deliveryStartDate = ''
      this.deliveryEndDate = ''
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      return `${year}-${month}-${day}`
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
  },

}
</script>

<style>

</style>
