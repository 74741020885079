<template>
  <v-card>
    <div style="width: 100%; position: relative;">
      <h3 style=" font-weight: 600; font-size: 18px; text-align: center; padding-top: 30px;">
        Solicitação marketing
      </h3>
      <v-btn
        icon
        style="position: absolute; right: 20px; top: 25px;"
        @click="closeModal"
      >
        <v-icon size="30">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <div
      v-if="lateralMenuVal === 'solicitation'"
      class="d-flex justify-end"
      style="margin-top: -15px; margin-right: 75px;"
    >
      <h3 class="SolTitle">
        N° SLM: <span style="font-weight: 500; font-size: 14px; color: #9A62FD;">0{{ item.slm }}</span>
      </h3>
    </div>
    <div
      class="mx-10 d-flex mt-12 align-start pb-12"
      style="gap: 10px; height: 100%;"
    >
      <div
        style="width: 30%; gap: 15px"
        class="d-flex flex-column"
      >
        <BadgeDt
          :icon="icons.mdiFileDocumentOutline"
          title="Detalhes"
          subtitle="Defina os assuntos"
          :lateral-menu-val="lateralMenuVal"
          menu-value="details"
          @update-lateral-menu="updateLateralMenu"
        />
        <BadgeDt
          :icon="icons.mdiMessageArrowRightOutline"
          title="Solicitação"
          subtitle="Insira os dados"
          :lateral-menu-val="lateralMenuVal"
          menu-value="solicitation"
          @update-lateral-menu="updateLateralMenu"
        />
      </div>
      <div style="width:70%;">
        <div v-if="lateralMenuVal === 'details'">
          <div
            class="d-flex flex-column mb-6  "
            style="gap: 13px;"
          >
            <h3 class="SolTitle">
              N° SLM: <span style="font-weight: 500; font-size: 14px; color: #9A62FD;">0{{ item.slm }}</span>
            </h3>
            <div
              class="d-flex"
              style="gap: 11px;"
            >
              <v-icon>
                {{ icons.mdiInformationOutline }}
              </v-icon>
              <h3 style="font-weight: 400; font-size: 14px; padding-top: 4px;">
                <strong style="font-weight: 600; font-size: 14px">Solicitado por</strong> {{ item.created_by_user.name }} <strong style="font-weight: 600; font-size: 14px">no dia</strong> {{ formatDateTime(item.created_at) }}
              </h3>
            </div>
            <div
              class="d-flex"
              style="gap: 11px;"
            >
              <v-icon>
                {{ icons.mdiOfficeBuildingMarkerOutline }}
              </v-icon>
              <h3 style="font-weight: 500; font-size: 14px; padding-top: 4px;">
                <strong style="font-weight: 600; font-size: 14px">Loja:</strong> {{ item.created_by_user.company.fantasy_name }}
              </h3>
            </div>

            <div
              class="d-flex"
              style="gap: 11px;"
            >
              <v-icon>
                {{ icons.mdiDoorClosed }}
              </v-icon>
              <h3 style="font-weight: 500; font-size: 14px; padding-top: 4px;">
                <strong style="font-weight: 600; font-size: 14px">Setor:</strong> {{ item.created_by_user.occupation_sector.sector.name }}
              </h3>
            </div>
          </div>
          <v-text-field
            v-model="subject"
            label="Assunto"
            outlined
            readonly
            dense
          />
          <h3 style="font-size: 15px; margin-bottom: 15px; font-weight: 600;">
            Defina um <strong style="color: #9A62FD;">prazo</strong> para a sua solicitação:
          </h3>
          <v-text-field
            v-model="requestersExpectedDeliveryDate"
            label="Prazo"
            style="max-width: 140px;"
            outlined
            readonly
            dense
          />
          <v-text-field
            v-if="subject !== 'ASSINATURA DE E-MAIL' && subject !== 'OUTROS'"
            v-model="category"
            readonly
            label="Categoria"
            outlined
            dense
          />
          <v-text-field
            v-if="category === 'OUTROS'"
            v-model="others"
            readonly
            outlined
            dense
            label="Qual?"
          />
        </div>
        <section v-if="lateralMenuVal === 'solicitation'">
          <v-form
            v-if="subject === 'AÇÃO DE VENDAS'"
            ref="form"
          >
            <SalesActionForm
              v-model="salesActionData"
              :is-just-for-read="true"
            />
          </v-form>

          <v-form
            v-if="subject === 'BANNER/MATERIAL DIGITAL'"
            ref="form"
          >
            <BannerCreationOrDigitalMaterialForm
              v-model="bannerCreationOrDigitalMaterialData"
              :is-just-for-read="true"
            />
          </v-form>

          <v-form
            v-if="subject === 'PRODUÇÃO DE MATERIAL'"
            ref="form"
          >
            <PrintedMaterialForm
              v-model="printedMaterialData"
              :is-just-for-read="true"
            />
          </v-form>

          <v-form
            v-if="subject === 'PRODUÇÃO DE VÍDEO'"
            ref="form"
          >
            <VideoProductionForm
              v-model="videoProductionData"
              :is-just-for-read="true"
            />
          </v-form>

          <v-form
            v-if="subject === 'COMUNICAÇÃO INTERNA'"
            ref="form"
          >
            <InternComunicationForm
              v-model="internComunicationData"
              :is-just-for-read="true"
            />
          </v-form>

          <v-form
            v-if="subject === 'MENSAGEM TEMPLATE'"
            ref="form"
          >
            <TemplateMessageForm
              v-model="templateMessageData"
              :is-just-for-read="true"
            />
          </v-form>

          <v-form
            v-if="subject === 'ASSINATURA DE E-MAIL'"
            ref="form"
          >
            <MailSubscriptionForm
              v-model="mailSubscriptionData"
              :is-just-for-read="true"
            />
          </v-form>
          <span>Inserir anexos:</span>
          <AttachmentsList :solicitation-id="item.id" />
          <Activities
            :solic-data="item"
            :solicitation-id="item.id"
            :history-arr="historyArr"
            @updatePanel="changeStatus"
            @getHistory="getHistory"
          />
        </section>
      </div>
      <div
        v-if="lateralMenuVal === 'solicitation'"
        style="width: 450px;"
        class="ml-6"
      >
        <h2 style="font-size: 16px">
          Ações
        </h2>
        <h3
          style="font-size: 14px;"
          class="mt-3"
        >
          Atribuir responsável
        </h3>
        <div
          class="d-flex my-4"
          style="gap: 5px;"
        >
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="true"
            :nudge-right="30"
            transition="scale-transition"
            bottom
            min-width="auto"
          >
            <template
              v-slot:activator="{ on, attrs }"
              bottom
            >
              <v-avatar
                color="#E7E3FC1A"
                class="add-people"
                v-bind="attrs"
                size="30"
                v-on="on"
              >
                <v-icon
                  size="18"
                  color="#E7E3FC"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-avatar>
            </template>
            <v-card>
              <v-list>
                <v-list-item
                  v-for="(responsible, index) in responsibles"
                  :key="index"
                  @click="handleSelectResponsible(responsible)"
                >
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 12px;">
                      {{ responsible.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <div>
            <div v-if="selectedResponsible ? selectedResponsible.name : false">
              <v-tooltip
                top
                color="white"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-bind="attrs"
                    size="30"
                    color="error"
                    class="v-avatar-light-bg success--text"
                    v-on="on"
                  >
                    <v-img
                      v-if="selectedResponsible && selectedResponsible.url_photo"
                      :src="selectedResponsible.base64"
                    />
                    <span
                      v-else
                      class="v-avatar-light-bg success--text"
                      style="font-size: 13px;"
                    >
                      {{ getInitials(selectedResponsible) }}
                    </span>
                  </v-avatar>
                </template>
                <span style="color: black;">
                  {{ selectedResponsible ? selectedResponsible.name : '' }}
                </span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <v-autocomplete
          v-if="selectedResponsible ? selectedResponsible.name : false"
          v-model="status"
          :items="StatusList"
          small-chips
          label="Status"
          :rules="[rules.required]"
          outlined
          dense
          @input="checkUserChange"
        />
        <h3
          v-if="status === 'EM EXECUÇÃO COM TERCEIRO' || (selectedThirdParty ? selectedThirdParty.name : false)"
          style="font-size: 14px;"
          class="mt-3"
        >
          Atribuir terceiros
        </h3>
        <div
          v-if="status === 'EM EXECUÇÃO COM TERCEIRO' || (selectedThirdParty ? selectedThirdParty.name : false)"
          class="d-flex my-4"
          style="gap: 5px;"
        >
          <v-menu
            ref="menu3"
            v-model="menu3"
            :close-on-content-click="true"
            :nudge-right="30"
            transition="scale-transition"
            bottom
            min-width="auto"
          >
            <template
              v-slot:activator="{ on, attrs }"
              bottom
            >
              <v-avatar
                color="#E7E3FC1A"
                class="add-people"
                v-bind="attrs"
                size="30"
                v-on="on"
              >
                <v-icon
                  size="18"
                  color="#E7E3FC"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-avatar>
            </template>
            <v-card>
              <v-list>
                <v-list-item
                  v-for="(third, index) in thirdParties"
                  :key="index"
                  @click="handleSelectThird(third)"
                >
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 12px;">
                      {{ third.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <div>
            <div v-if="selectedThirdParty ? selectedThirdParty.name : false">
              <v-tooltip
                top
                color="white"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-bind="attrs"
                    size="30"
                    :color="selectedThirdParty.charCode ? returnCharBasedOnCharCode(selectedThirdParty.charCode).bgColor: '#E7E3FC'"
                    v-on="on"
                  >
                    <v-icon
                      class="pa-1"
                      :color="selectedThirdParty.charCode ? returnCharBasedOnCharCode(selectedThirdParty.charCode).avatarColor: '#E7E3FC'"
                    >
                      {{ selectedThirdParty.charCode ? `${icons[returnCharBasedOnCharCode(selectedThirdParty.charCode).icon]}` : '-' }}
                    </v-icon>
                  </v-avatar>
                </template>
                <span style="color: black;">
                  {{ selectedThirdParty ? selectedThirdParty.name : '' }} /
                  {{ selectedThirdParty ? selectedThirdParty.function : '' }}
                </span>
              </v-tooltip>
            </div>
          </div>
          <v-tooltip
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="selectedThirdParty ? selectedThirdParty.name : false"
                v-bind="attrs"
                icon
                style="height: 30px !important; width: 30px !important;"
                color="error"
                v-on="on"
                @click="handleClearThirdParty"
              >
                <v-icon
                  color="error"
                  size="20"
                >
                  {{ icons.mdiTrashCan }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              Remover terceiro
            </span>
          </v-tooltip>
        </div>

        <h3
          v-if="(selectedResponsible ? selectedResponsible.name : false) && status !== 'NÃO INICIADA'"
          style="font-size: 14px;"
          class="mt-3 mb-5"
        >
          Previsão de entrega
        </h3>
        <v-menu
          v-if="(selectedResponsible ? selectedResponsible.name : false) && status !== 'NÃO INICIADA'"
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              ref="expectedStartDateRef"
              v-model="expectedStartDateFormatted"
              style="max-width: 180px;"
              :rules="[rules.required]"
              label="Previsão de entrega"
              :append-icon="icons.mdiCalendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              @blur="expectedStartDate = parseDate(expectedStartDateFormatted)"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="expectedStartDate"
            @input="checkUserChange"
          ></v-date-picker>
        </v-menu>
        <div class="d-flex justify-end">
          <v-btn
            color="info"
            class="d-flex align-center justify-center mt-5"
            style="gap: 10px;"
            :disabled="!hasChanges || isLoading"
            @click="handleSaveSolData"
          >
            <div
              v-if="!isLoading"
              class="d-flex align-center justify-center"
            >
              <v-icon>{{ icons.mdiContentSaveOutline }}</v-icon>
              <span>SALVAR</span>
            </div>
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import axiosNewInstance from '@/plugins/newAxiosInstance'
import messages from '@/plugins/showMessageMixin'
import {
  mdiAirballoonOutline, mdiAlienOutline, mdiAnchor,
  mdiArrowLeft,
  mdiArrowRight,
  mdiCalendar,
  mdiClose,
  mdiContentSaveOutline,
  mdiDoorClosed,
  mdiFileDocumentOutline,
  mdiHandshakeOutline,
  mdiHomeOutline,
  mdiInformationOutline,
  mdiInformationVariant,
  mdiMessageArrowRightOutline,
  mdiMovieOpenOutline, mdiMusic,
  mdiOfficeBuildingMarkerOutline,
  mdiPencil,
  mdiPlus,
  mdiPrinter,
  mdiPuzzleOutline,
  mdiStarFourPointsOutline, mdiStarOutline,
  mdiTrashCan,
  mdiWeatherSunny,
} from '@mdi/js'
import Activities from '../../Solicitations/components/Activities.vue'
import BadgeDt from '../../Solicitations/components/BadgeDt.vue'
import BannerCreationOrDigitalMaterialForm from '../../Solicitations/components/Forms/BannerCreationOrDigitalMaterialForm.vue'
import InternComunicationForm from '../../Solicitations/components/Forms/InternComunicationForm.vue'
import MailSubscriptionForm from '../../Solicitations/components/Forms/MailSubscriptionForm.vue'
import PrintedMaterialForm from '../../Solicitations/components/Forms/PrintedMaterialForm.vue'
import SalesActionForm from '../../Solicitations/components/Forms/SalesActionForm.vue'
import TemplateMessageForm from '../../Solicitations/components/Forms/TemplateMessageForm.vue'
import VideoProductionForm from '../../Solicitations/components/Forms/VideoProductionForm.vue'
import AttachmentsList from '../../Solicitations/components/attachmentsList.vue'

export default {
  name: 'ModalViewSolicitation',
  components: {
    BadgeDt, AttachmentsList, SalesActionForm, Activities, BannerCreationOrDigitalMaterialForm, MailSubscriptionForm, PrintedMaterialForm, VideoProductionForm, InternComunicationForm, TemplateMessageForm,
  },
  mixins: [messages, formatters],
  props: {
    item: {
      type: Object,
      required: true,
    },
    responsibles: {
      type: Array,
      required: true,
    },
    thirdParties: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      originalData: {},
      icons: {
        mdiClose, mdiTrashCan, mdiFileDocumentOutline, mdiHandshakeOutline, mdiContentSaveOutline, mdiInformationOutline, mdiOfficeBuildingMarkerOutline, mdiPlus, mdiDoorClosed, mdiCalendar, mdiMessageArrowRightOutline, mdiArrowRight, mdiArrowLeft, mdiInformationVariant, mdiPuzzleOutline, mdiPencil, mdiWeatherSunny, mdiStarFourPointsOutline, mdiStarOutline, mdiPrinter, mdiAirballoonOutline, mdiAlienOutline, mdiAnchor, mdiHomeOutline, mdiMovieOpenOutline, mdiMusic,
      },

      expectedStartDate: '',
      expectedStartDateFormatted: '',

      allStatusList: [
        'ENVIADA PARA VALIDAÇÃO',
        'EM ANDAMENTO',
        'EM EXECUÇÃO COM TERCEIRO',
        'ENVIADA AO SETOR DE COMPRAS',
        'FINALIZADA',
        'FINALIZADA',
        'NÃO INICIADA',
      ],

      StatusList: [],
      historyArr: [],
      isPending: false,
      initialDate: '',
      others: '',
      finalDate: '',
      subject: '',
      category: '',
      status: '',
      requestersExpectedDeliveryDate: '',
      lateralMenuVal: 'details',

      salesActionData: {
        actualProblem: '',
        communicationChannel: '',
        additionalInfo: '',
        initialDate: '',
        finalDate: '',
        initialDateFormatted: '',
        finalDateFormatted: '',
      },

      bannerCreationOrDigitalMaterialData: {
        briefing: '',
        materialDissemination: '',
      },

      videoProductionData: {
        briefing: '',
        placeOfUse: '',
        videoFormat: '',
      },

      printedMaterialData: {
        id: '',
        length: '',
        briefing: '',
        width: '',
        height: '',
        localPhoto: '',
        locationOfUse: '',
      },

      internComunicationData: {
        briefing: '',
        materialDissemination: '',
      },

      templateMessageData: {
        briefing: '',
        messageObjective: '',
        targetAudience: '',
      },

      selectedResponsible: {
        id: '',
        name: '',
        base64: '',
        url_photo: '',
      },
      selectedThirdParty: {
        id: '',
        name: '',
        function: '',
        charCode: '',
      },

      mailSubscriptionData: {
        employeeFullName: '',
        employeeFunction: '',
        employeeSkype: '',
        telephoneExtension: '',
      },
      menu1: false,
      menu2: false,
      menu3: false,

      hasChanges: false,

      isLoading: false,

    }
  },
  computed: {
    computedExpectedStartDate() {
      return this.formatDate(this.expectedStartDate)
    },
  },
  watch: {
    expectedStartDate() {
      this.expectedStartDateFormatted = this.formatDate(this.expectedStartDate)
    },
    status(newStatus) {
      this.updateStatusList(newStatus)
    },
  },
  created() {
    this.getHistory()
    this.updateStatusList(this.status || '')

    if (this.item.assigned_to_user) {
      this.handleSelectResponsible(this.item.assigned_to_user)
    }
    if (this.item.third_party) {
      this.handleSelectThird(this.item.third_party)
    }
    this.expectedStartDate = this.item.expected_delivery_date || ''

    this.subject = this.item.subject || ''
    this.category = this.item.category || ''
    this.others = this.item.others || ''
    this.requestersExpectedDeliveryDate = this.formatDate(this.item.requesters_expected_delivery_date)

    this.status = this.item.status || ''

    this.templateMessageData.briefing = this.item.briefing || ''
    this.templateMessageData.messageObjective = this.item.message_objective || ''
    this.templateMessageData.targetAudience = this.item.message_target_audience || ''

    this.salesActionData.actualProblem = this.item.actual_problem || ''
    this.salesActionData.communicationChannel = this.item.communication_channel || ''
    this.salesActionData.additionalInfo = this.item.additional_info || ''
    this.salesActionData.initialDate = this.item.initial_date || ''
    this.salesActionData.finalDate = this.item.final_date || ''
    this.salesActionData.initialDateFormatted = this.formatDate(this.item.initial_date || '')
    this.salesActionData.finalDateFormatted = this.formatDate(this.item.final_date || '')

    this.mailSubscriptionData.employeeFullName = this.item.employee_full_name || ''
    this.mailSubscriptionData.employeeFunction = this.item.employee_function || ''
    this.mailSubscriptionData.employeeSkype = this.item.employee_skype || ''
    this.mailSubscriptionData.telephoneExtension = this.item.telephone_extension || ''

    this.bannerCreationOrDigitalMaterialData.briefing = this.item.briefing || ''
    this.bannerCreationOrDigitalMaterialData.materialDissemination = this.item.location_of_use || ''

    this.internComunicationData.briefing = this.item.briefing || ''
    this.internComunicationData.materialDissemination = this.item.location_of_use || ''

    this.printedMaterialData.length = this.item.length || ''
    this.printedMaterialData.id = this.item.id
    this.printedMaterialData.briefing = this.item.briefing || ''
    this.printedMaterialData.width = this.item.width || ''
    this.printedMaterialData.height = this.item.height || ''
    this.printedMaterialData.localPhoto = this.item.local_photo || ''
    this.printedMaterialData.locationOfUse = this.item.location_of_use || ''

    this.videoProductionData.briefing = this.item.briefing || ''
    this.videoProductionData.placeOfUse = this.item.location_of_use || ''
    this.videoProductionData.videoFormat = this.item.video_format || ''
  },
  methods: {
    returnCharBasedOnCharCode(charCode) {
      const charMap = {
        CH1: { icon: 'mdiHandshakeOutline', avatarColor: '#fff', bgColor: '#0085FF' },
        CH2: { icon: 'mdiHomeOutline', avatarColor: '#fff', bgColor: '#9A62FD' },
        CH3: { icon: 'mdiStarOutline', avatarColor: '#fff', bgColor: '#FFB400' },
        CH4: { icon: 'mdiStarFourPointsOutline', avatarColor: '#fff', bgColor: '#FF821A' },
        CH5: { icon: 'mdiPuzzleOutline', avatarColor: '#fff', bgColor: '#56CA00' },
        CH6: { icon: 'mdiAirballoonOutline', avatarColor: '#fff', bgColor: '#16B1FF' },
        CH7: { icon: 'mdiWeatherSunny', avatarColor: '#fff', bgColor: '#C7B200' },
        CH8: { icon: 'mdiAlienOutline', avatarColor: '#fff', bgColor: '#FF4C51' },
        CH9: { icon: 'mdiAnchor', avatarColor: '#fff', bgColor: '#87ADBD' },
        CH10: { icon: 'mdiPrinter', avatarColor: '#fff', bgColor: '#28243D' },
        CH11: { icon: 'mdiMusic', avatarColor: '#fff', bgColor: '#0051E9' },
        CH12: { icon: 'mdiMovieOpenOutline', avatarColor: '#fff', bgColor: '#00AF7E' },
      }
      const selectedChar = charMap[charCode]

      return selectedChar
    },
    handleClearThirdParty() {
      this.selectedThirdParty = {
        id: '',
        name: '',
        function: '',
        charCode: '',
      }
      this.checkUserChange()
    },
    getInitials(user) {
      if (!user || !user.name) {
        return '-'
      }
      const names = user.name.split(' ')
      if (names.length === 1) {
        return names[0].charAt(0).toUpperCase()
      }
      const firstInitial = names[0].charAt(0).toUpperCase()
      const lastInitial = names[names.length - 1].charAt(0).toUpperCase()

      return firstInitial + lastInitial
    },
    checkUserChange() {
      const normalizeDate = date => (date ? new Date(date).toISOString() : '')

      const isStatusChanged = (this.item.status ?? '') !== (this.status ?? '')
      const isDateChanged = normalizeDate(this.item.expected_delivery_date) !== normalizeDate(this.expectedStartDate)
      const isResponsibleChanged = (this.item.assigned_to_user?.id ?? '') !== (this.selectedResponsible?.id ?? '')
      const isThirdPartyChanged = (this.item.third_party ? this.item.third_party.id : '') !== (this.selectedThirdParty.id)

      this.hasChanges = isStatusChanged || isDateChanged || isResponsibleChanged || isThirdPartyChanged
    },
    updateStatusList(currentStatus) {
      if (currentStatus === 'NÃO INICIADA') {
        this.StatusList = [...this.allStatusList]
      } else {
        this.StatusList = this.allStatusList.filter(
          status => status !== 'NÃO INICIADA',
        )
      }
    },
    async getHistory() {
      try {
        const { data } = await axiosIns.get(`/api/v1/solicitations/marketing_solicitation_history/find_by_solic_id/${this.item.id}`)

        this.historyArr = data.data
      } catch (error) {
        console.error(error)
      }
    },
    async handleSaveSolData() {
      const { expectedStartDateRef } = this.$refs
      if ((!this.expectedStartDate || !expectedStartDateRef.validate()) && (this.selectedResponsible ? this.selectedResponsible.name : false) && this.status !== 'NÃO INICIADA') {
        this.$swal({
          icon: 'info',
          title: 'Atenção!',
          showConfirmButton: true,
          text: 'Você deve adicionar uma data de previsão de entrega!',
          timer: 5000,
        })

        return
      }

      if (this.status === 'EM EXECUÇÃO COM TERCEIRO' && !this.selectedThirdParty.id) {
        this.$swal({
          icon: 'info',
          title: 'Atenção!',
          showConfirmButton: true,
          text: 'É obrigatório adicionar um terceiro ao atribuir o status "EM EXECUÇÃO COM TERCEIRO".',
          timer: 5000,
        })

        return
      }

      if (this.status === 'FINALIZADA') {
        const confirmAction = await this.confirmAction()
        if (!confirmAction) return
      }

      const body = {
        assigned_to: this.selectedResponsible.id || null,
        third_parties_id: this.selectedThirdParty.id || null,
        status: this.status || null,
        expected_delivery_date: this.expectedStartDate || null,
      }
      this.isLoading = true
      try {
        await axiosNewInstance.put(`/api/v1/solicitations/marketing/update/${this.item.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          showConfirmButton: false,
          text: 'As informações foram salvas.',
          timer: 5000,
        })
        this.$emit('get-table-data')
        this.getHistory()
        this.hasChanges = false
        this.item.status = this.status
        this.item.expected_delivery_date = this.expectedStartDate

        if (!this.item.assigned_to_user) {
          this.item.assigned_to_user = {}
        }
        this.item.assigned_to_user.id = this.selectedResponsible?.id || ''

        if (!this.item.third_party) {
          this.item.third_party = {}
        }
        this.item.third_party.id = this.selectedThirdParty?.id || ''
      } catch (error) {
        this.$swal({
          icon: 'info',
          title: 'Atenção!',
          showConfirmButton: true,
          text: error.message,
          timer: 5000,
        })
      } finally {
        this.isLoading = false
      }
    },
    formatDateTime(input) {
      const date = new Date(input)

      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()

      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')

      return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`
    },
    updateLateralMenu(newVal) {
      this.lateralMenuVal = newVal
    },
    async confirmAction() {
      const result = await this.$swal({
        title: 'Atenção!',
        text: 'Ao passar para o status "FINALIZADA" a solicitação não poderá ser alterada. Deseja continuar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })

      return result.isConfirmed
    },
    handleSelectResponsible(selectedResponsible) {
      this.selectedResponsible.name = selectedResponsible.name
      this.selectedResponsible.base64 = selectedResponsible.base64
      this.selectedResponsible.id = selectedResponsible.id
      this.selectedResponsible.url_photo = selectedResponsible.url_photo
      if (this.item.assigned_to_user !== selectedResponsible) {
        this.checkUserChange()
      }
    },
    handleSelectThird(selectedThirdParty) {
      this.selectedThirdParty.name = selectedThirdParty.name
      this.selectedThirdParty.id = selectedThirdParty.id
      this.selectedThirdParty.charCode = selectedThirdParty.avatar_code

      this.selectedThirdParty.function = selectedThirdParty.third_party_activity.name
      if (this.item.third_party !== selectedThirdParty) {
        this.checkUserChange()
      }
    },
    closeModal() {
      this.$emit('close')
    },
    closeAndRefresh() {
      this.$emit('close')
      this.$emit('get-table-data')
    },
    changeStatus() {
      this.status = 'ENVIADA PARA VALIDAÇÃO'
      this.$emit('get-table-data')
    },
    lateralMenu(newVal) {
      this.lateralMenuVal = newVal
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      return `${year}-${month}-${day}`
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
  },
}
</script>

<style scoped>
  .SolTitle{
    font-size: 14px;
    font-weight: 600;
  }
  .add-people {
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  .add-people:hover{
    opacity: 0.8;
  }
</style>
