import Vue from 'vue'
import VueRouter from 'vue-router'
import searchClient from './SeachClient'
import Sgq from './Sgq'
import admin from './admin'
import integrator from './integrator'
import nexusapp from './nexusApp'
import personalDepartment from './personalDepartment'
import sigmaSupervisor from './sigmaSupervisor'
import sigmaVendor from './sigmaVendor'
import Solicitations from './solicitations'
import Utilities from './utilities'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/GridApp.vue'),
    meta: { layout: 'blank' },
  },
  {
    path: '/perfil',
    name: 'settings-profile',
    component: () => import('@/views/pages/profiles/ProfileSettings.vue'),
    meta: { layout: 'blank' },
  },
  {
    path: '/external-systems',
    name: 'external-systems',
    component: () => import('@/views/ExternalSystems.vue'),
    meta: { layout: 'blank' },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: { layout: 'blank' },
  },
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('@/views/SecondPage.vue'),
    meta: { layout: 'content' },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: { layout: 'blank' },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/under-development',
    name: 'under-development',
    component: () => import('@/views/UnderDevelopment.vue'),
    meta: { layout: 'content' },
  },
  {
    path: '/documents',
    name: 'documents-profile',
    component: () => import('@/views/pages/profiles/Documents/Home.vue'),
    meta: { layout: 'blank' },
  },
  {
    path: '/director-channel',
    name: 'director-channel',
    component: () => import('@/views/pages/DirectorChannel/Home.vue'),
    meta: { layout: 'blank' },
  },
]

const userRole = localStorage.getItem('userRole')
if (userRole === 'true') {
  routes.push(Utilities)
  routes.push(Solicitations)
  routes.push(Sgq)
  routes.push(admin)
  routes.push(nexusapp)
  routes.push(integrator)
  routes.push(sigmaSupervisor)
  routes.push(personalDepartment)
  routes.push(searchClient)
} else if (userRole === 'false') {
  routes.push(Utilities)
  routes.push(Sgq)
  routes.push(Solicitations)
  routes.push(integrator)
  routes.push(sigmaVendor)
  routes.push(personalDepartment)
  routes.push(searchClient)
} else if (userRole === 'null') {
  routes.push(Utilities)
  routes.push(Solicitations)
  routes.push(Sgq)
  routes.push(integrator)
  routes.push(personalDepartment)
  routes.push(searchClient)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.name === 'director-channel') {
    // Permite o acesso à rota "director-channel" sem autenticação
    next()
  } else if (to.name !== 'auth-login' && !localStorage.accessToken) {
    // Redireciona para a página de login se não houver autenticação
    next({ name: 'auth-login' })
  } else {
    next()
  }
})

export default router
