<template>
  <v-card>
    <div
      class=" d-flex align-center mb-10 mb-md-0 pt-4 pt-md-0"
      style="height: 98px;"
    >
      <v-card-title
        color="#E7E3FC"
        style=" word-break: keep-all"
        class=""
      >
        Metas
      </v-card-title>
      <v-card-text class="d-flex align-center justify-end flex-wrap pb-0 pl-0">
        <v-btn
          :color="filterButtonColor"
          class="me-3"
          outlined
          @click="openModalFilter()"
        >
          <v-icon
            size="20"
            class="me-1"
          >
            {{ icons.mdiFilterVariant }}
          </v-icon>
          <span>
            FILTRO
          </span>
        </v-btn>
        <!-- <v-btn
          color="orange"
          outlined
          class="me-3"
          @click="openModalTargetGlobal()"
        >
          <v-icon
            size="20"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          Global
        </v-btn> -->
        <!-- <v-btn
          color="primary"
          class="me-3"
          outlined
          @click="openModalNewRegister()"
        >
          <v-icon
            size="20"
            class="me-1"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          <span>
            NOVO REGISTRO
          </span>
        </v-btn> -->

        <v-menu
          offset-y
          location="bottom"
        >
          <template v-slot:activator="{ attrs, on }">
            <div
              outlined
              v-bind="attrs"
              class="container-options"
              v-on="on"
            >
              <v-icon
                size="22"
                color="info"
                class=""
              >
                {{ icons.mdiPlaylistPlus }}
              </v-icon>

              <span class="text-md-subtitle-2">ADICIONAR</span>

              <!-- <v-img
                class="arrow-select"
                :width="12"
                src="@/assets/arrow-down-icon.svg"
              ></v-img> -->
            </div>
          </template>
          <v-list>
            <v-list-item
              v-for="item in listOptions"
              :key="item.value"
              link
            >
              <v-icon
                :color="item.color"
                small
                class="me-2"
              >
                {{ item.icons }}
              </v-icon>
              <v-list-item-title
                @click="item.onAction()"
                v-text="item.title"
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-text>
    </div>

    <v-data-table
      :items="items"
      :headers="headers"
      :loading="loading"
      loading-text="Carregando dados..."
      class="text-no-wrap"
      disable-sort
      :page.sync="pageOptions.page"
      :items-per-page="pageOptions.itemsPerPage"
      @page-count="$event => (pageCount = $event)"
    >
      <template v-slot:item.name="{item}">
        <div
          class=" d-flex ga-3"
          style=" gap: 10px;"
        >
          <span>
            {{ item.user ? item.user.name : item.name }}
          </span>
          <div
            v-if="(item.cnh_parameter_goal && item.cnh_parameter_goal[0] && item.cnh_parameter_goal[0].vacation) || item.vacation"
            class="chip-vacations rounded-xl text-sm px-2"
          >
            <span class="d-flex">Férias</span>
          </div>
        </div>
      </template>

      <template v-slot:item.cpf="{ item }">
        <span>{{ item.user ? item.user.cpf : item.vendor_cpf }}</span>
      </template>

      <template v-slot:item.goal="{ item }">
        <span>
          {{ item.cnh_parameter_goal && item.cnh_parameter_goal[0] ? item.cnh_parameter_goal[0].goal : item.goal }}
        </span>
      </template>

      <template v-slot:item.level="{ item }">
        <span>
          {{ item.cnh_parameter_goal && item.cnh_parameter_goal[0] ? item.cnh_parameter_goal[0].level : item.level }}
        </span>
      </template>

      <template v-slot:item.updated_at2="{ item }">
        <span>
          {{ item.cnh_parameter_goal && item.cnh_parameter_goal[0] ? dateFormat(item.cnh_parameter_goal[0].updated_at, true) : dateFormat(item.updated_at, true) }}
        </span>
      </template>

      <template v-slot:item.action="{item}">
        <div class="d-flex align-center justify-center">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="deleteItem(endpointDelete, item.id)"
              >
                <v-icon
                  size="22"
                  color="error"
                >
                  {{ icons.mdiProgressClose }}
                </v-icon>
              </v-btn>
            </template>

            <span>Desativar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="getItemsById(item.id)"
              >
                <v-icon
                  v-if="item.id === loadingSpinner ? false : true"
                  size="22"
                  color="primary"
                >
                  {{ icons.mdiPencil }}
                </v-icon>

                <v-progress-circular
                  v-if="item.id === loadingSpinner ? true : false"
                  indeterminate
                  :size="25"
                  color="info"
                >
                </v-progress-circular>
              </v-btn>
            </template>

            <span>Editar meta</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <!-- <v-card-text class="pt-2 pb-1">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-center"
        >
          <v-pagination
            v-model="pageOptions.page"
            total-visible="6"
            :length="pageOptions.pageCount"
            @input="updatePage()"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text> -->

    <v-dialog
      v-model="openModal"
      :width="computedWidth"
      persistent
    >
      <modal-register-target
        :key="dataDetails.vendor_cpf"
        :mode.sync="mode"
        :data="dataDetails"
        :page-options="pageOptions"
        :vendor-list="vendorList"
        @updatedTable="updatedTable()"
        @close="openModal = false"
      ></modal-register-target>
    </v-dialog>
    <v-dialog
      v-model="openModalFilterTarget"
      width="512"
      persistent
    >
      <modal-filter-target
        :page-options="pageOptions"
        :vendor-list="vendorList"
        @updatedTable="updatedTable()"
        @filteredData="$event => setTableData($event)"
        @isFilterTrue="$event => isFiltered = $event"
        @close="openModalFilterTarget = false"
      ></modal-filter-target>
    </v-dialog>

    <v-dialog
      v-model="openModalGlobal"
      width="650"
    >
      <modal-register-target-global
        @close="openModalGlobal = false"
      ></modal-register-target-global>
    </v-dialog>
    <v-dialog
      v-model="openNewRegister"
      width="446"
    >
      <modal-new-register @close="openNewRegister = false"></modal-new-register>
    </v-dialog>
    <v-dialog
      v-model="openModalImport"
      width="600"
    >
      <modal-import
        @close="openModalImport = false"
        @updatedTable="updatedTable()"
      ></modal-import>
    </v-dialog>
  </v-card>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/plugins/axios'
// eslint-disable-next-line import/no-cycle
import formatters from '@/plugins/formattersMixin1'
import {
  mdiChevronDown,
  mdiDownload,
  mdiFilterVariant,
  mdiMagnify, mdiPencil,
  mdiPenPlus,
  mdiPlaylistPlus,
  mdiPlus,
  mdiProgressClose,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import ModalFilterTarget from './ModalFilterTarget.vue'
import ModalImport from './ModalImport.vue'
import ModalNewRegister from './ModalNewRegister.vue'
import ModalRegisterTarget from './ModalRegisterTarget.vue'
import ModalRegisterTargetGlobal from './ModalRegisterTargetGlobal.vue'

export default {
  components: {
    ModalRegisterTarget,
    ModalRegisterTargetGlobal,
    ModalNewRegister,
    ModalFilterTarget,
    ModalImport,
  },

  mixins: [formatters],

  data() {
    return {
      listOptions: [
        {
          title: 'Novo registro',
          value: 'novo',
          icons: mdiPenPlus,
          color: 'success',
          onAction: () => this.openModalNewRegister(),

          // validation: "verifyUser === 'null' && statusSales === 'REGISTRADO'",
        },
        {
          title: 'Global',
          value: 'global',
          icons: mdiPlus,
          color: 'error',
          onAction: () => this.openModalTargetGlobal(),

          // validation: "verifyUser === 'null'",
        },
        {
          title: 'Importar',
          value: 'importar',
          icons: mdiDownload,
          color: 'warning',
          onAction: () => this.handleOpenModalImport(),

          // validation: "verifyUser === 'null'",
        },
      ],
      openModalImport: false,
      vendorFilter: '',
      isOnVacation: false,
      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      isFiltered: false,
      mode: 'edit',
      loadingSpinner: '',
      loading: false,
      openModal: false,
      openModalFilterTarget: false,
      openModalGlobal: false,
      openNewRegister: false,
      icons: {
        mdiFilterVariant,
        mdiPlaylistPlus,
        mdiMagnify,
        mdiPlus,
        mdiProgressClose,
        mdiPencil,
        mdiChevronDown,
      },
      headers: [
        { text: 'NOME', value: 'name', align: '' },
        { text: 'CPF', value: 'cpf', align: '' },
        { text: 'META', value: 'goal', align: 'center' },
        { text: 'NÍVEL', value: 'level', align: 'center' },
        { text: 'ULT. MODIFICAÇÃO', value: 'updated_at2', align: '' },
        { text: 'AÇÕES', value: 'action', align: 'center' },
      ],
      items: [],
      vendorList: [],
      dataDetails: {},
      endpointDelete: 'api/v1/integration/cnh_parameter/destroy',
      filterApplied: false,
    }
  },
  computed: {
    filterButtonColor() {
      return this.isFiltered ? 'success' : 'white'
    },
    computedWidth() {
      return this.mode === 'edit' ? 650 : 1000
    },
  },

  created() {
    this.vendorList = localStorageSlim.get('listEmployees', { decrypt: true })
    this.getItemsTable()
  },

  methods: {
    handleOpenModalImport() {
      this.openModalImport = true
    },
    setTableData(data) {
      this.items = data.data.data
      this.pageOptions.itemsPerPage = data.data.meta.per_page
      this.pageOptions.pageCount = data.data.meta.last_page
      this.isFiltered = true
    },
    async getItemsTable() {
      this.loading = true
      this.filterApplied = false
      await axiosIns.get('api/v1/integration/cnh_parameter/index').then(res => {
        this.items = res.data.data
        this.loading = false
        this.pageOptions.itemsPerPage = res.data.meta.per_page
        this.pageOptions.pageCount = res.data.meta.last_page
      })
    },

    async searchVendor() {
      this.loading = true
      this.filterApplied = true
      const body = {
        vendor_filter: this.vendorFilter,
        vendor_vacation: this.isOnVacation,
        page: this.pageOptions.page,
      }
      await axiosIns.post('/api/v1/integration/cnh_parameter/filter_parameter', body).then(res => {
        this.items = res.data.data
        this.pageOptions.itemsPerPage = res.data.meta.per_page
        this.pageOptions.pageCount = res.data.meta.last_page
        this.loading = false
      })
    },

    async updatePage() {
      this.loading = true
      const endpoint = this.filterApplied ? '/api/v1/integration/cnh_parameter/filter_parameter' : 'api/v1/integration/cnh_parameter/index'
      const body = this.filterApplied ? { vendor_filter: this.vendorFilter, vendor_vacation: this.isOnVacation, page: this.pageOptions.page } : {}
      const request = this.filterApplied ? axiosIns.post(endpoint, body) : axiosIns.get(`${endpoint}?page=${this.pageOptions.page}`)

      await request.then(res => {
        this.items = res.data.data
        this.pageOptions.itemsPerPage = res.data.meta.per_page
        this.pageOptions.pageCount = res.data.meta.last_page
        this.loading = false
      })
    },

    async getItemsById(id) {
      this.loadingSpinner = id
      await axiosIns.get(`api/v1/integration/cnh_parameter/edit/${id}`).then(res => {
        this.dataDetails = res.data.data
      })
      this.loadingSpinner = ''
      this.openModal = true
      this.mode = 'edit'
    },

    openModalTargetGlobal() {
      this.openModalGlobal = true
    },

    openModalFilter() {
      this.openModalFilterTarget = true
    },
    openModalNewRegister() {
      this.openNewRegister = true
    },

    async filterReset() {
      this.items = []
      this.vendorFilter = ''
      this.isOnVacation = false
      this.pageOptions.page = 1
      this.isFiltered = false
      this.getItemsTable()
    },

    async deleteItem(endpointDelete, id) {
      await this.deleteRegister(endpointDelete, id, 'Aviso!', 'Ao clicar em SIM, as informações do vendedor serão apagadas.')
      this.isFiltered = false
    },

    updatedTable() {
      this.getItemsTable()
    },
  },
}
</script>

<style>
.color-cpf-vendor {
  color: #85819c;
}
.chip-vacations{
  background: #56CA001A;
  color: #56CA00;
  font-weight: 600;
  padding: 2px 0px 2px 0px;
}
.container-options{
  padding: 8px;
  border-radius: 8px;
  color: #16B1FF;
  border: 2px solid #16b1ff91;

  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.container-options:hover{
  background: #407d9c2c;
}

.arrow-select{
  stroke: #16B1FF;
}
</style>
