<template>
  <div
    class="d-flex flex-column"
    style="gap: 15px;"
  >
    <div>
      <span>
        Briefing
      </span>
      <v-textarea
        v-model="localTemplateMessage.briefing"
        :readonly="isJustForRead"
        :rules="[rules.required]"
        class="pt-3"
        outlined
        label="Descrição"
        @input="emitChanges"
      />
      <InfoTag
        :icon="icons.mdiInformationVariant"
        text="Discorra o que deve ser comunicado."
        icon-color="#fff"
        icon-background-color="#16B1FF"
      />
    </div>
    <div>
      <span>
        Qual o objetivo da mensagem?
      </span>
      <v-textarea
        v-model="localTemplateMessage.messageObjective"
        :readonly="isJustForRead"
        :rules="[rules.required]"
        class="pt-3"
        outlined
        label="Descreva o objetivo"
        @input="emitChanges"
      />
    </div>
    <div>
      <span>
        Qual o público-alvo da mensagem?
      </span>
      <v-textarea
        v-model="localTemplateMessage.targetAudience"
        :readonly="isJustForRead"
        :rules="[rules.required]"
        class="pt-3"
        outlined
        label="Descreva"
        @input="emitChanges"
      />
    </div>
  </div>
</template>

<script>
import InfoTag from '@/components/InfoTag.vue'
import { mdiInformationVariant } from '@mdi/js'

export default {
  name: 'TemplateMessageForm',
  components: {
    InfoTag,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        briefing: '',
        messageObjective: '',
        targetAudience: '',
      }),
    },
    isJustForRead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localTemplateMessage: { ...this.value },
      icons: {
        mdiInformationVariant,
      },
      rules: {
        required: value => !!value || 'Este campo é obrigatório',
      },
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.localTemplateMessage = { ...newValue }
      },
    },
  },
  methods: {
    emitChanges() {
      this.$emit('input', this.localTemplateMessage)
    },
  },
}
</script>
