<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="mb-4">Filtrar dados</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-text-field
          v-model="codeSelf"
          label="Código Self"
          type="number"
          outlined
          dense
        />

        <v-autocomplete
          v-model="selectedEmployeeName"
          :items="employees"
          item-text="name"
          item-value="name"
          label="Colaborador"
          outlined
          clearable
          dense
        />

        <v-autocomplete
          v-model="selectedCompanyName"
          :items="listOfCompanies"
          item-text="fantasy_name"
          item-value="fantasy_name"
          label="Selecionar Filial"
          clearable
          outlined
          dense
        />

        <v-autocomplete
          v-model="selectedSectorName"
          :items="occupationAndSectors"
          item-text="sector.name"
          item-value="sector.name"
          label="Selecionar Setor"
          clearable
          outlined
          dense
        />

        <v-autocomplete
          v-model="selectedOccupationName"
          :items="occupationAndSectors"
          item-text="occupation.name"
          item-value="occupation.name"
          label="Função do colaborador"
          clearable
          outlined
          dense
        />

        <v-autocomplete
          v-model="stabilityType"
          :items="listTypeStability"
          label="Situação"
          class=""
          clearable
          outlined
          dense
        >
        </v-autocomplete>
      </v-form>

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="error"
          @click="closeModal(), resetForm()"
        >
          Fechar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="getFilterData()"
        >
          <span v-if="!isLoading">Filtrar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiCalendar } from '@mdi/js'

export default {
  mixins: [formatters, messages, utilsMixin],

  props: {
    employees: {
      type: Array,
      required: true,
    },

    listOfCompanies: {
      type: Array,
      required: true,
    },

    occupationAndSectors: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      stabilityType: '',
      listTypeStability: ['ATIVO', 'INATIVO', 'ATIVO COM ESTABILIDADE'],
      codeSelf: '',
      typistUser: '',
      referenceDate: '',
      eventIdSelected: '',

      selectedSectorName: '',
      selectedCompanyName: '',
      selectedEmployeeName: '',
      selectedOccupationName: '',

      isLoading: false,

      icons: {
        mdiCalendar,
      },
    }
  },

  methods: {
    async getFilterData() {
      const {
        selectedEmployeeName, selectedCompanyName, selectedSectorName, selectedOccupationName, codeSelf, stabilityType,
      } = this

      const captalizeStatus = stabilityType.charAt(0).toUpperCase() + stabilityType.slice(1).toLowerCase()

      const body = {
        user_name: selectedEmployeeName,
        cod_self: codeSelf,
        fantasy_name: selectedCompanyName,
        occupation_name: selectedOccupationName,
        sector_name: selectedSectorName,
        status: captalizeStatus,
      }

      this.isLoading = true

      await axiosIns
        .post('api/v1/records/user/filter', body)
        .then(response => {
          const dataFiltered = response.data

          if (dataFiltered.length === 0) {
            this.showMessage({ title: 'Nenhum registro encontrado!', text: 'Tente novamente' })
            this.isLoading = false

            return
          }

          this.showMessage({ title: 'Sucesso', text: 'Dados obtidos com sucesso', icon: 'success' })

          this.sendFilterData(dataFiltered)
        })
        .catch(error => {
          this.showMessage({ title: 'Erro ao enviar dados', text: error, icon: 'error' })
        }).finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },

    sendFilterData(data) {
      if (typeof data !== 'object') {
        return
      }

      this.$emit('setDataFromFilterInTable', data)
    },

    resetForm() {
      this.$refs.form.reset()
    },
  },
}
</script>
