<template>
  <div
    class="d-flex align-center"
    style="gap: 12px; cursor: pointer;"
    :class="{'fullOp': isSelected, 'lessOp': !isSelected}"
    @click="handleClick"
  >
    <div
      class="badgeDt"
      :class="{'selected': isSelected, 'notSelected': !isSelected}"
    >
      <v-icon :color="iconColor">
        {{ icon }}
      </v-icon>
    </div>
    <div
      class="d-flex flex-column"
      style="gap: 10px;"
    >
      <span style="font-weight: 600; font-size: 16px">{{ title }}</span>
      <span style="font-weight: 400; font-size: 13px;">{{ subtitle }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BadgeDt',
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    lateralMenuVal: {
      type: String,
      required: true,
    },
    menuValue: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      default: '#E7E3FC',
    },
  },
  computed: {
    isSelected() {
      return this.lateralMenuVal === this.menuValue
    },
  },
  methods: {
    handleClick() {
      this.$emit('update-lateral-menu', this.menuValue)
    },
  },
}
</script>

<style scoped>
.badgeDt {
  height: 65px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.badgeDt:hover{
  opacity: 0.95;
}
.selected {
  background: #9A62FD;
}
.notSelected {
  background: #9A62FD1A;
}
.notSelected:hover{
  background: #9b62fd69;
}
.fullOp {
  opacity: 1;
}
.lessOp {
  opacity: 0.5;
}
.lessOp:hover{
  opacity: 0.8;
}
</style>
