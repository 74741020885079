<template>
  <v-app>
    <div class="container-general">
      <div
        class="container-header"
        :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
      >
        <v-card class="ml-10 mr-10 px-5 py-5 d-flex justify-content-between">
          <v-col cols="4">
            <v-text-field
              v-model="filterWrite"
              :append-icon="icons.mdiMagnify"
              label="Buscar sistema"
              outlined
              hide-details
              dense
              class=""
            >
            </v-text-field>
          </v-col>

          <v-col
            cols="8"
            class="d-flex justify-content-end"
          >
            <div class="container-profile">
              <app-bar-theme-switcher></app-bar-theme-switcher>
              <app-bar-user-menu></app-bar-user-menu>
            </div>
          </v-col>
        </v-card>
      </div>

      <div
        class="container-title"
        :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
      >
        <div
          class="container-left"
          :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
        >
        </div>
        <div
          class="container-box-shadow"
          :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
        >
          <span class="title-large">Bem-vindo ao Motoca Systems!</span>
          <span class="title-small">Para continuar, escolha uma das opções de sistema disponíveis.</span>
        </div>
        <div
          class="container-right"
          :class="{ 'dark-mode': isDark, 'light-mode': !isDark }"
        >
        </div>
      </div>

      <GridController :access-systems="accessSystemsFiltered"></GridController>

      <div class="container-nextpage">
        <!-- <div
          class="nextpage"
          @click="$router.push({ name: 'external-systems' })"
        >
          <img src="../assets/arrow-down-icon.svg" />
        </div>
        <span>Ver mais</span> -->
      </div>

      <v-card class="d-flex justify-center align-items-center py-4 mb-10">
        <img src="../assets/new-motoca-logo.png" />

        <div class="pipe"></div>

        <img src="../assets/fuji.svg" />
      </v-card>

      <v-dialog
        v-model="loadingProfileAccess"
        persistent
        class="d-flex justify-content-center"
      >
        <div class="d-flex justify-content-center">
          <v-spacer />
          <v-card
            color="info"
            width="300"
          >
            <v-card-text class="pt-3">
              <p class="mb-2">
                Por favor, aguarde!
              </p>

              <v-progress-linear
                indeterminate
                color="white"
              />
            </v-card-text>
          </v-card>

          <v-spacer />
        </div>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import axiosIns from '@/plugins/axios'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import { getVuetify } from '@core/utils'
import { mdiExitToApp, mdiMagnify } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import GridController from './Grids/GridController.vue'

export default {
  components: {
    GridController,
    AppBarUserMenu,
    AppBarThemeSwitcher,
  },

  data() {
    return {
      typeUser: '',
      userProfile: [],
      accessSystems: [],
      externalSystems: [
        'Cloud', 'Ihs', 'Huggy', 'Tsuru',
        'Sólides', 'Secullum Ponto', 'Assomotoca',
        'Self deal', 'Volus', 'Saphira', 'Unico',
        'Followize', 'Secullum Adm', 'My Honda',
        'Banco Honda', 'AssoParts', 'LMS - Treinamento',
        'Academia de Vendas', 'Pitstop', 'Pos-Vendas',
        'Client Connect Explorer', 'Sol',
      ],
      accessSystemsFiltered: [],
      filterWrite: '',
      loadingProfileAccess: false,

      icons: {
        mdiExitToApp,
        mdiMagnify,
      },
    }
  },
  computed: {
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },

  watch: {
    filterWrite(value) {
      const filtered = this.accessSystems.filter(system => system.toUpperCase().includes(value.toUpperCase()))

      this.accessSystemsFiltered = filtered
    },
  },

  async created() {
    this.verifyUserIsAdministrator()

    await this.getLoggedInUser()
    await this.getListCompanies()

    this.getProfilePhoto()
  },

  methods: {
    verifyUserIsAdministrator() {
      this.typeUser = localStorage.getItem('userRole')
    },

    logout() {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('username')
      this.$router.push({ name: 'auth-login' })
    },

    async getLoggedInUser() {
      this.loadingProfileAccess = true

      await axiosIns
        .get('api/v1/records/user/logged_in_user')
        .then(res => {
          this.userProfile = res.data.data
          const { data } = res.data

          localStorageSlim.set('userProfile', data, { encrypt: true })
          localStorageSlim.set('accessSystems', data.access_systems, { encrypt: true })
          localStorageSlim.set('userPermissions', data.permissions, { encrypt: true })

          this.typeUser = data.profile.profile
          this.accessSystems = data.access_systems.concat(this.externalSystems)

          this.accessSystemsFiltered = this.accessSystems
        })
        .catch(error => console.error(error.message))
        .finally(async () => {
          await this.getListEmployees()
          await this.getLeaderList()

          this.loadingProfileAccess = false
        })
    },

    async getProfilePhoto() {
      const idUser = this.userProfile.id

      await axiosIns
        .get(`/api/v1/records/user/display_photo/${idUser}`)
        .then(res => {
          this.imageProfile = res.data
          localStorageSlim.set('profilePhoto', res.data, { encrypt: true })
        })
        .catch(error => console.error(error.message))
    },

    async getListEmployees() {
      await axiosIns.get('/api/v1/records/user/index').then(res => {
        localStorageSlim.set('listEmployees', res.data.data, { encrypt: true })
      }).catch(error => console.error(error.message))
    },

    async getLeaderList() {
      await axiosIns.get('api/v1/records/user/user_leader').then(res => {
        localStorageSlim.set('leaderList', res.data.data, { encrypt: true })
      }).catch(error => console.error(error.message))
    },

    async getListCompanies() {
      await axiosIns
        .get('/api/v1/records/company/index')
        .then(res => {
          localStorageSlim.set('listCompanies', res.data.data, { encrypt: true })
        })
        .catch(error => console.error(error.message))
    },
  },
}
</script>
<style scoped>
.container-general{
  width: 100%;
  height: auto;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-header{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background: #28243d;
}

.container-profile{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.container-box-shadow{
  width: 80%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  padding: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-title{
  width: 100%;
  position: fixed;
  top: 104px;
  z-index: 100;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.container-left{
  width: 20px;
  height: 170px;
  margin-right: -10px;
  z-index: 120;
}

.container-right{
  width: 20px;
  height: 170px;
  margin-left: -10px;
  z-index: 120;
}
.dark-mode {
  background: #28243D;
}

.light-mode {
  background: #f4f5fa;
}

.title-large{
  color: #BE77FF;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 183.523%;
}

.title-small{
  color: #CAC9CD;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 183.523%;
}

.container-nextpage{
  width: 100%;
  height: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nextpage{
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  background: #3C3857;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%
}

.pipe{
  width: 1px;
  background: #fff;
  height: 80%;
  margin-left: 20px;
}
.container-exit {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}
</style>
