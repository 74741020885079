<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container d-flex flex-row-reverse bd-highlight"
        :class="{ 'expanded-search': shallShowFullSearch }"
      >
        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <!-- <app-bar-integration-upload></app-bar-integration-upload> -->
          <v-icon
            medium
            class="me-6"
            @click="$router.push({ path: '/' })"
          >
            {{ icons.mdiHome }}
          </v-icon>
          <app-bar-theme-switcher></app-bar-theme-switcher>
          <app-bar-user-menu></app-bar-user-menu>
        </div>

        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
        </div>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'

// import AppBarIntegrationUpload from '@/@core/layouts/components/app-bar/AppBarIntegrationUpload.vue'

// import navMenuItems from '@/navigation/vertical'

// App Bar Components
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'

import { mdiHeartOutline, mdiHome, mdiMenu } from '@mdi/js'

import { getVuetify } from '@core/utils'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'

import SearchClient from '@/navigation/vertical/SearchClient'
import Sgq from '@/navigation/vertical/Sgq'
import Utilities from '@/navigation/vertical/Utilities'
import admin from '@/navigation/vertical/admin'
import integrator from '@/navigation/vertical/integrator'
import nexusapp from '@/navigation/vertical/nexusApp'
import personalDepartment from '@/navigation/vertical/personalDepartment'
import sigma from '@/navigation/vertical/sigma'
import sigmaVendor from '@/navigation/vertical/sigmaVendor'
import solicitations from '@/navigation/vertical/solicitations'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    // AppBarSearch,
    AppBarUserMenu,
    AppBarThemeSwitcher,

    // AppBarIntegrationUpload,
  },
  setup() {
    const $vuetify = getVuetify()

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    // NOTE: Update search function according to your usage
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    return {
      // navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      icons: {
        mdiMenu,
        mdiHeartOutline,
        mdiHome,
      },
    }
  },
  data() {
    return {
      navMenuItems: [],
      goHome: '',
    }
  },
  created() {
    this.selectMenu()
    this.goHome = localStorage.getItem('userRole')
  },
  methods: {
    selectMenu() {
      const location = window.location.href

      if (location.includes('supervisor')) {
        this.navMenuItems = sigma
      } else if (location.includes('vendedor')) {
        this.navMenuItems = sigmaVendor
      } else if (location.includes('adm')) {
        this.navMenuItems = admin
      } else if (location.includes('integrator')) {
        this.navMenuItems = integrator
      } else if (location.includes('personalDepartment')) {
        this.navMenuItems = personalDepartment
      } else if (location.includes('nexusapp')) {
        this.navMenuItems = nexusapp
      } else if (location.includes('sgq')) {
        this.navMenuItems = Sgq
      } else if (location.includes('explorer')) {
        this.navMenuItems = SearchClient
      } else if (location.includes('utilities')) {
        this.navMenuItems = Utilities
      } else if (location.includes('solicitations')) {
        this.navMenuItems = solicitations
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
