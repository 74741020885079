import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticStyle:{"padding":"0px 30px 30px 30px"}},[_c('div',{staticClass:"head-modal"},[_c('div'),_c('span',[_vm._v("Nova atividade")]),_c(VIcon,{staticClass:"me-1",attrs:{"medium":""},on:{"click":_vm.handleCloseModal}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VForm,{ref:"form",staticClass:"d-flex flex-column mt-5",staticStyle:{"gap":"5px"}},[_c(VTextField,{attrs:{"rules":[_vm.rules.required],"outlined":"","dense":"","label":"Nome da atividade"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('div',{staticClass:"d-flex justify-end",staticStyle:{"width":"100%"}},[_c(VBtn,{attrs:{"color":"info","disabled":_vm.isLoading},on:{"click":function($event){return _vm.handleCreateThirdParty()}}},[(!_vm.isLoading)?_c('span',[_vm._v("Cadastrar")]):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }