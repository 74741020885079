<template>
  <v-card>
    <v-card-title>
      <span class="mr-4">Listagem geral de funcionários</span>

      <v-spacer />

      <v-btn
        class="mr-4"
        :color="filterIsActive ? 'success' : 'gray'"
        outlined
        @click="openModal('filter')"
      >
        <v-icon
          size="25"
          class="mr-2"
        >
          {{ icons.mdiFilterVariant }}
        </v-icon>
        Filtro
      </v-btn>

      <v-btn
        v-show="filterIsActive"
        class="mr-4"
        outlined
        color="error"
        @click="clearFilter()"
      >
        <v-icon
          size="30"
          class="mr-2"
        >
          {{ icons.mdiFilterVariantRemove }}
        </v-icon>
        Limpar
      </v-btn>

      <!-- <v-btn
        color="warning"
        class="mr-4 ml-4"
        outlined
        @click="openModal('history')"
      >
        <v-icon
          size="25"
          class="mr-2"
        >
          {{ icons.mdiAccountRemove }}
        </v-icon>
        Usuários inativos
      </v-btn> -->

      <v-btn
        color="info"
        class="mr-4"
        outlined
        @click="updateUsers()"
      >
        <div v-if="!isLoadingUpdate">
          <v-icon
            size="25"
            class="mr-2"
          >
            {{ icons.mdiAutorenew }}
          </v-icon>
          <span>Atualizar lista</span>
        </div>

        <v-progress-circular
          v-else
          color="white"
          indeterminate
        >
        </v-progress-circular>
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :loading="isLoading"
      :items="itemsTable"
      loading-text="Carregando dados..."
    >
      <template v-slot:no-data>
        <span>Sem informações para exibir</span>
      </template>

      <template v-slot:item.stabilities="{ item }">
        <div v-if="item.stabilities">
          <div
            v-for="(icon, index) in renderIconsStabilitiesOnGrid(item.stabilities)"
            :key="index"
            class="icons-container"
          >
            <v-icon

              :color="icon.icon === 'mdiPlusCircleOutline'.trim() ? 'success' : icon.icon === 'mdiHospital'.trim() ? 'error' : 'purple'"
              class="me-2"
            >
              {{ icons[icon.icon.trim()] }}
            </v-icon>
            {{ (icon.name) }}
          </div>
        </div>

        <span v-else>PADRÂO</span>

        <!-- {{ item.stabilities ? modifyTypeStabilities(item.stabilities) : 'PADRÃO' }} -->
      </template>

      <template v-slot:item.situation="{ item }">
        <v-alert
          :class="{
            'badge': item.situation === 'ATIVO COM ESTABILIDADE',
            'badgeActive': item.situation === 'ATIVO',
            'badgeInactive': item.situation === 'INATIVO'
          }"
          :color="getStatusColor(item.stabilities, item.active)"
          text
          style="border-radius: 50px;"
        >
          {{ item.situation }}
        </v-alert>
      </template>

      <template v-slot:item.edit="{ item }">
        <v-icon
          medium
          color="warning"
          class="me-2"
          @click="openModal('details', item)"
        >
          {{ icons.mdiEye }}
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="showDetails"
      width="750px"
    >
      <Details
        :key="userDetails.id"
        :data="userDetails"
        :occupation-and-sectors="occupationAndSectors"
        @updatedTable="updatedTable()"
        @close="showDetails = false"
      ></Details>
    </v-dialog>

    <v-dialog
      v-model="showFilter"
      width="750px"
    >
      <FilterModal
        :employees="users"
        :list-of-companies="listOfCompanies"
        :occupation-and-sectors="occupationAndSectors"
        @setDataFromFilterInTable="(data) => setDataFromFilterInTable(data)"
        @close="showFilter = false"
      ></FilterModal>
    </v-dialog>

    <v-dialog
      v-model="showHistory"
      width="600px"
    >
      <History
        :occupation-and-sectors="occupationAndSectors"
        @updatedTable="updatedTable()"
        @close="showHistory = false"
      ></History>
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiAccountRemove,
  mdiAutorenew,
  mdiEye,
  mdiFilterPlusOutline,
  mdiFilterRemoveOutline,
  mdiFilterVariant,
  mdiFilterVariantRemove,
  mdiHospital,
  mdiMotherHeart,
  mdiPlusCircleOutline,
  mdiUpdate,
} from '@mdi/js'
import Details from './Details.vue'
import FilterModal from './FilterModal.vue'
import History from './History.vue'

export default {
  components: { History, Details, FilterModal },

  mixins: [formatters, messages],

  data() {
    return {
      filterIsActive: false,
      headers: [
        { text: 'NOME', value: 'name', align: 'left' },
        { text: 'LOJA', value: 'company.fantasy_name', align: 'left' },
        { text: 'CODSELF', value: 'cod_self', align: 'left' },
        { text: 'CLOUD ID', value: 'cloud_id', align: 'left' },
        { text: 'FUNÇÃO', value: 'occupation_sector.occupation.name', align: 'left' },
        { text: 'TIPO DE ESTABILIDADE', value: 'stabilities', align: 'left' },
        { text: 'SETOR', value: 'occupation_sector.sector.name', align: 'left' },
        { text: 'SITUAÇÃO', value: 'situation', align: 'left' },
        { text: 'VIZUALIZAR', value: 'edit', align: 'center' },
      ],

      users: [],
      itemsTable: [],
      newItemsTableWithKeySituation: [],
      userDetails: {},
      listOfCompanies: [],
      listOfFilteredItems: [],
      occupationAndSectors: [],

      isLoading: false,
      isLoadingUpdate: false,

      showFilter: false,
      showHistory: false,
      showDetails: false,

      icons: {
        mdiEye,
        mdiUpdate,
        mdiAccountRemove,
        mdiFilterRemoveOutline,
        mdiFilterPlusOutline,
        mdiAutorenew,
        mdiFilterVariant,
        mdiHospital,
        mdiMotherHeart,
        mdiPlusCircleOutline,
        mdiFilterVariantRemove,
      },
    }
  },

  created() {
    this.getUsers()
    this.getSectors()
    this.getListOfCompanies()
  },

  methods: {
    async clearFilter() {
      await this.getUsers()
      await this.getSectors()
      await this.getListOfCompanies()

      this.filterIsActive = false
    },
    getStatusColor(stabilities = [], active) {
      const resultToString = stabilities.map(item => item.type).join(', ')

      if (active && resultToString) {
        return 'info'
      }

      if (active && !resultToString) {
        return 'success'
      }

      return 'error'
    },

    modifyTypeStabilities(stabilities) {
      const resultToString = stabilities.map(item => item.type).join(', ')

      return resultToString
    },

    renderIconsStabilitiesOnGrid(stabilities) {
      const arrayStabilities = stabilities.map(item => (
        item.type === 'CIPA' ? { icon: 'mdiPlusCircleOutline', name: item.type } : item.type === 'ACIDENTE DE TRABALHO' ? { icon: 'mdiHospital', name: item.type } : { icon: 'mdiMotherHeart', name: item.type }
      ))

      return arrayStabilities
    },

    async getUsers() {
      this.isLoading = true

      await axiosIns
        .get('api/v1/records/user/index')
        .then(response => {
          const { data } = response.data

          this.users = data

          const newListEmployees = data.map(employee => {
            let situation

            if (employee.active && employee.stabilities) {
              situation = 'ATIVO COM ESTABILIDADE'
            }

            if (employee.active && !employee.stabilities) {
              situation = 'ATIVO'
            }

            if (!employee.active) {
              situation = 'INATIVO'
            }

            return { ...employee, situation }
          })

          this.itemsTable = newListEmployees
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => { this.isLoading = false })
    },

    async getSectors() {
      await axiosIns
        .get('api/v1/records/occupation/index')
        .then(response => {
          const { data } = response.data

          data.forEach(({ id, sector, occupation }) => this.occupationAndSectors.push({
            id,
            sector: {
              id: sector.id,
              name: sector.name,
            },
            occupation: {
              id: occupation.id,
              name: occupation.name,
              nameWithSector: `${occupation.name} - ${sector.name}`,
            },
          }))
        })
        .catch(error => this.showErrorMessage(error))
    },

    async getListOfCompanies() {
      await axiosIns.get('api/v1/records/company/index').then(response => {
        this.listOfCompanies = response.data.data
      }).catch(error => this.showErrorMessage(error))
    },

    async updateUsers() {
      this.isLoadingUpdate = true

      await axiosIns
        .get('api/v1/records/user/update_cloud_users')
        .then(response => this.showMessage({
          title: 'Dados atualizados com sucesso',
          text: response.data.data[0],
          icon: 'success',
          timer: 5000,
        }))
        .catch(error => this.showErrorMessage(error))
        .finally(() => {
          this.isLoadingUpdate = false
          this.updatedTable()
        })
    },

    openModal(type, data = {}) {
      const modal = {
        history: () => {
          this.showHistory = true
        },
        filter: () => {
          this.showFilter = true
        },
        details: () => {
          this.showDetails = true
          this.userDetails = data
        },
      }

      if (type in modal) {
        modal[type]()

        return
      }

      console.error('Modal type does not exist in openModal function')
    },

    setDataFromFilterInTable(data) {
      this.filterIsActive = true

      const newListEmployees = data.map(employee => {
        let situation

        if (employee.active && employee.stabilities) {
          situation = 'ATIVO COM ESTABILIDADE'
        }

        if (employee.active && !employee.stabilities) {
          situation = 'ATIVO'
        }

        if (!employee.active) {
          situation = 'INATIVO'
        }

        return { ...employee, situation }
      })

      this.itemsTable = newListEmployees
    },

    updatedTable() {
      this.getUsers()
    },
  },
}
</script>
<style>
.badge {
  margin-top: 16px;
  font-size: 12px;
  width: 175px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 0px 2px 8px;
}

.badgeActive {
  margin-top: 16px;
  font-size: 12px;
  width: 53px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 0px 2px 8px;
}

.badgeInactive {
  margin-top: 16px;
  font-size: 12px;
  width: 63px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 0px 2px 8px;
}
.icons-container{
  display: flex;
  margin: 10px 0px 10px 0px;
  width: 250px;
}
</style>
