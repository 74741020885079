<template>
  <div
    v-if="!!solicitationId"
    class="d-flex flex-column mt-6 mb-10"
    style="gap: 22px;"
  >
    <div
      v-for="item in ArrImages"
      :key="item.id"
    >
      <div
        v-if="item.type === 'image'"
        class="d-flex"
        style="gap: 20px;"
      >
        <v-img
          max-height="64"
          max-width="64"
          style="border-radius: 5px;"
          :src="item.base64"
        />
        <div
          style="gap: 10px;"
          class="d-flex flex-column"
        >
          <h3 style="font-weight: 700; font-size: 14px;">
            {{ item.name }}
          </h3>
          <div
            class="d-flex"
            style="gap: 3px;"
          >
            <v-btn
              icon
              @click="handleViewImage(item.base64)"
            >
              <v-icon>{{ icons.mdiEyeOutline }}</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="downloadImage(item.id, item.name)"
            >
              <v-icon v-if="!loadingStates[item.id]">
                {{ icons.mdiDownloadOutline }}
              </v-icon>
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex"
        style="gap: 20px; "
      >
        <div
          class=" d-flex"
          style="width: 65px; height: 65px; background: #D9D9D90D; align-items: center; justify-content: center; border: 1px solid #E7E3FC; border-radius: 5px;"
        >
          <div>
            <v-img
              width="24"
              height="32"
              src="../../../../../../assets/images/svg/mdiFile.svg"
            >
            </v-img>
          </div>
        </div>
        <div
          style="gap: 10px;"
          class="d-flex flex-column"
        >
          <h3 style="font-weight: 700; font-size: 14px;">
            {{ item.name }}
          </h3>
          <div
            class="d-flex"
            style="gap: 3px;"
          >
            <v-btn
              icon
              @click="downloadImage(item.id, item.name)"
            >
              <v-icon v-if="!loadingStates[item.id]">
                {{ icons.mdiDownloadOutline }}
              </v-icon>
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showModalViewImage"
      width="1200px"
      transition="dialog-transition"
    >
      <ModalViewImage
        :image="image"
        @close="showModalViewImage = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import axiosFileApi from '@/plugins/axiosFileApi'
import { mdiDownloadOutline, mdiEyeOutline } from '@mdi/js'
import { saveAs } from 'file-saver'
import ModalViewImage from './modais/ModalViewImage.vue'

export default {
  name: 'AttachmentsList',
  components: {
    ModalViewImage,
  },
  props: {
    solicitationId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      icons: {
        mdiEyeOutline,
        mdiDownloadOutline,
      },
      loadingStates: {},
      ArrImages: [],
      showModalViewImage: false,
      image: '',
    }
  },
  created() {
    if (this.solicitationId) {
      this.getImages()
    }
  },
  methods: {
    async getImages() {
      try {
        const { data } = await axiosFileApi.get(`/api/v1/solicitations/marketing_attachment/find_by_solic_id/${this.solicitationId}`)
        this.ArrImages = data.data
        console.log('ArrImages', this.ArrImages)
      } catch (error) {
        console.error(`Ocorreu um erro ao buscar as imagens nesse id: ${this.solicitationId}`, error)
      }
    },
    handleViewImage(image) {
      this.image = image
      this.showModalViewImage = true
    },
    async downloadImage(fileId, fileName) {
      this.$set(this.loadingStates, fileId, true) // Define o estado de carregamento como true para este botão

      const body = { id: fileId }

      try {
        const response = await axiosFileApi.post(
          '/api/v1/solicitations/marketing_attachment/view_file',
          body,
          { responseType: 'arraybuffer' },
        )
        const contentType = response.headers['content-type']
        const file = new File([response.data], fileName, { type: contentType })
        saveAs(file)
      } catch (error) {
        console.error(error)
        this.$swal({
          icon: 'error',
          title: 'Erro ao buscar arquivo!',
          text: 'Ocorreu um erro ao buscar o seu arquivo, contate a equipe de desenvolvimento.',
        })
      } finally {
        this.$delete(this.loadingStates, fileId) // Remove o estado de carregamento após a conclusão
      }
    },
  },
}
</script>

<style>

</style>
