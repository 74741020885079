<template>
  <v-card style="padding: 0px 30px 30px 30px;">
    <div class="head-modal">
      <div></div>
      <span>Nova atividade</span>

      <v-icon
        medium
        class="me-1"
        @click="handleCloseModal"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </div>
    <v-form
      ref="form"
      class="d-flex flex-column mt-5"
      style="gap: 5px;"
    >
      <v-text-field
        v-model="name"
        :rules="[rules.required]"
        outlined
        dense
        label="Nome da atividade"
      />

      <div
        class="d-flex justify-end"
        style="width: 100%;"
      >
        <v-btn
          color="info"
          :disabled="isLoading"
          @click="handleCreateThirdParty()"
        >
          <span
            v-if="!isLoading"
          >Cadastrar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiClose } from '@mdi/js'

export default {

  name: 'NewActivityModal',
  mixins: [messages, formatters],
  data() {
    return {
      icons: {
        mdiClose,
      },
      isLoading: false,

      listCategoriesItem: [],
      name: '',

    }
  },
  methods: {

    handleCloseModal() {
      this.$emit('close')
    },
    async handleCreateThirdParty() {
      const isValidForm = this.$refs.form.validate()
      if (!isValidForm) {
        return
      }

      this.isLoading = true

      const body = {
        name: this.name.toUpperCase(),
      }
      try {
        await axiosIns.post('/api/v1/solicitations/marketing_third_parties_activity/store', body)
        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Atividade criada.',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        console.error('Erro na criação da atividade:', error)

        this.$swal({
          icon: 'error',
          title: 'Erro!',
          text: 'Ocorreu um erro ao criar atividade!',
          showConfirmButton: true,
          timer: 3000,
        })
      } finally {
        this.handleCloseModal()
        this.isLoading = false
        this.$emit('updatedTable')
        this.$refs.form.reset()
      }
    },
  },
}
</script>

<style scoped>
.head-modal{
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
