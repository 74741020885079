import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userProfile.access_systems.includes('Solicitações'))?_c(VCard,[_c('div',{staticClass:"d-flex align-center justify-space-between px-5 mb-8 pt-8",staticStyle:{"width":"100%"}},[_c('h3',{staticClass:"pl-0",staticStyle:{"font-weight":"600"}},[_vm._v(" Minhas Solicitações ")]),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"20px"}},[_c(VBtn,{attrs:{"color":"info","outlined":""},on:{"click":function($event){_vm.showModalNewSolicitationMKT = true}}},[_c(VIcon,{staticClass:"me-1"},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistPlus)+" ")]),_c('span',[_vm._v("NOVA SOLICITAÇÃO")])],1),_c(VBtn,{attrs:{"outlined":"","color":_vm.filterButtonColor},on:{"click":_vm.openModalFilterMySolicitations}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariant)+" ")]),_c('span',{staticClass:"button-text-actions"},[_vm._v("FILTRAR")])],1),(_vm.isFiltered)?_c(VBtn,{attrs:{"outlined":"","color":"error"},on:{"click":_vm.clearFilters}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariant)+" ")]),_c('span',{staticClass:"button-text-actions"},[_vm._v("LIMPAR")])],1):_vm._e()],1)]),_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.listOfFilteredItems,"loading":_vm.loadingTable,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"item.slm",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center","width":"100%","height":"100%"}},[_c('span',{staticStyle:{"color":"#9A62FD","font-weight":"500","font-size":"14px"}},[_vm._v(" 0"+_vm._s(item.slm ? item.slm : '')+" ")]),_c('div',{staticClass:"pb-2"},[(item.status === 'ENVIADA PARA VALIDAÇÃO' && !item.has_been_validated)?_c(VTooltip,{attrs:{"color":"orange","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"orange","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiExclamationThick)+" ")])]}}],null,true)},[_c('span',[_vm._v("Aguardando validação!")])]):_vm._e()],1)])]}},{key:"item.expected_delivery_date",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'toBeDefined': !item.expected_delivery_date }},[_vm._v(" "+_vm._s(item.expected_delivery_date ? _vm.formatDate(item.expected_delivery_date) : 'A definir')+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.created_at ? _vm.formatToDayMonthYear(item.created_at) : 'A definir')+" ")])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.category ? item.category : '-')+" ")])]}},{key:"item.assigned_to_user.name",fn:function(ref){
var item = ref.item;
return [(item && item.assigned_to_user && item.assigned_to_user.name)?_c(VTooltip,{attrs:{"top":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"v-avatar-light-bg success--text",attrs:{"color":"error","size":"30"}},'v-avatar',attrs,false),on),[(item && item.assigned_to_user && item.assigned_to_user.url_photo)?_c(VImg,{attrs:{"src":item.assigned_to_user.base64}}):_c('span',{staticClass:"v-avatar-light-bg success--text",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.getInitials(item.assigned_to_user))+" ")])],1)]}}],null,true)},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(item && item.assigned_to_user && item.assigned_to_user.name ? item.assigned_to_user.name : '-')+" ")])]):_c('span',[_vm._v("-")])]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center","gap":"10px"}},[_c(VIcon,{attrs:{"color":_vm.returnIconOrColorBasedOnSubject('color', item.subject)}},[_vm._v(" "+_vm._s(_vm.returnIconOrColorBasedOnSubject('icon', item.subject))+" ")]),_c('span',[_vm._v(" "+_vm._s(item.subject ? item.subject : '-')+" ")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VAlert,{staticClass:"badge",staticStyle:{"border-radius":"50px","padding":"0"},attrs:{"color":_vm.getStatusColor(item),"text":""}},[_vm._v(" "+_vm._s(item.status ? item.status : '')+" ")])]}},{key:"item.visualizar",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.openSolicitationMKTEditModal(item)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"my-10"},[_c('NoDataInterfaceEditable',{attrs:{"title":"Nenhum  registro encontrado!","subtitle":"Por favor, verifique os filtros de pesquisa / Contacte a equipe de desenvolvimento."}})],1)]},proxy:true}],null,false,196296256)}),_c(VDialog,{attrs:{"persistent":"","width":"850px","transition":"dialog-transition"},model:{value:(_vm.showModalNewSolicitationMKT),callback:function ($$v) {_vm.showModalNewSolicitationMKT=$$v},expression:"showModalNewSolicitationMKT"}},[_c('ModalNewSolicitationMKT',{on:{"close":function($event){_vm.showModalNewSolicitationMKT = false},"get-table-data":_vm.getTableData}})],1),_c(VDialog,{key:_vm.ModalEditSolicitationMKTKey,attrs:{"id":"ModalDoSolicitante","width":"1000px","transition":"dialog-transition"},model:{value:(_vm.showModalEditSolicitationMKT),callback:function ($$v) {_vm.showModalEditSolicitationMKT=$$v},expression:"showModalEditSolicitationMKT"}},[_c('ModalEditSolicitationMKT',{attrs:{"item":_vm.solicitation},on:{"close":function($event){_vm.showModalEditSolicitationMKT = false},"get-table-data":_vm.getTableData}})],1),_c(VDialog,{attrs:{"width":"600px","transition":"dialog-transition"},model:{value:(_vm.showModalFilterMySolicitations),callback:function ($$v) {_vm.showModalFilterMySolicitations=$$v},expression:"showModalFilterMySolicitations"}},[_c('ModalFilterMySolicitations',{ref:"ModalFilterMySolicitationsRef",on:{"close":function($event){_vm.showModalFilterMySolicitations = false},"filterData":_vm.handleFilterData}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }