<template>
  <v-card
    v-if="userProfile.access_systems.includes('Solicitações')"
  >
    <div
      class="d-flex align-center justify-space-between px-5 mb-8 pt-8"
      style=" width: 100%;"
    >
      <h3
        class="pl-0"
        style="font-weight: 600;"
      >
        Minhas Solicitações
      </h3>
      <div
        class="d-flex"
        style="gap: 20px;"
      >
        <v-btn
          color="info"
          outlined
          @click="showModalNewSolicitationMKT = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          <span>NOVA SOLICITAÇÃO</span>
        </v-btn>
        <v-btn
          class=""
          outlined
          :color="filterButtonColor"
          @click="openModalFilterMySolicitations"
        >
          <v-icon
            size="22"
            class="me-1"
          >
            {{ icons.mdiFilterVariant }}
          </v-icon>
          <span class="button-text-actions">FILTRAR</span>
        </v-btn>
        <v-btn
          v-if="isFiltered"
          outlined
          color="error"
          @click="clearFilters"
        >
          <v-icon
            size="22"
            class="me-1"
          >
            {{ icons.mdiFilterVariant }}
          </v-icon>
          <span class="button-text-actions">LIMPAR</span>
        </v-btn>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="listOfFilteredItems"
      :loading="loadingTable"
      loading-text="Carregando dados..."
      class="text-no-wrap"
    >
      <template v-slot:item.slm="{ item }">
        <div
          class="d-flex"
          style="align-items: center; width: 100%; height: 100%"
        >
          <span style="color: #9A62FD; font-weight: 500; font-size: 14px;">
            0{{ item.slm ? item.slm : '' }}
          </span>
          <div class="pb-2">
            <v-tooltip
              v-if="item.status === 'ENVIADA PARA VALIDAÇÃO' && !item.has_been_validated"
              color="orange"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  color="orange"
                  size="20"
                  v-on="on"
                >
                  {{ icons.mdiExclamationThick }}
                </v-icon>
              </template>
              <span>Aguardando validação!</span>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template v-slot:item.expected_delivery_date="{ item }">
        <span :class="{ 'toBeDefined': !item.expected_delivery_date }">
          {{ item.expected_delivery_date ? formatDate(item.expected_delivery_date) : 'A definir' }}
        </span>
      </template>
      <template v-slot:item.created_at="{ item }">
        <span>
          {{ item.created_at ? formatToDayMonthYear(item.created_at) : 'A definir' }}
        </span>
      </template>
      <template v-slot:item.category="{ item }">
        <span>
          {{ item.category ? item.category : '-' }}
        </span>
      </template>
      <template v-slot:item.assigned_to_user.name="{ item }">
        <v-tooltip
          v-if="item && item.assigned_to_user && item.assigned_to_user.name"
          top
          color="white"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-avatar
              v-bind="attrs"
              color="error"
              size="30"
              class="v-avatar-light-bg success--text"
              v-on="on"
            >
              <v-img
                v-if="item && item.assigned_to_user && item.assigned_to_user.url_photo"
                :src="item.assigned_to_user.base64"
              />
              <span
                v-else
                class="v-avatar-light-bg success--text"
                style="font-size: 13px;"
              >
                {{ getInitials(item.assigned_to_user) }}
              </span>
            </v-avatar>
          </template>
          <span style="color: black;">
            {{ item && item.assigned_to_user && item.assigned_to_user.name ? item.assigned_to_user.name : '-' }}
          </span>
        </v-tooltip>
        <span v-else>-</span>
      </template>
      <template v-slot:item.subject="{ item }">
        <div
          class="d-flex"
          style="align-items: center; gap: 10px;"
        >
          <v-icon :color="returnIconOrColorBasedOnSubject('color', item.subject)">
            {{ returnIconOrColorBasedOnSubject('icon', item.subject) }}
          </v-icon>
          <span>
            {{ item.subject ? item.subject : '-' }}
          </span>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-alert
          class="badge"
          :color="getStatusColor(item)"
          text
          style="border-radius: 50px; padding: 0"
        >
          {{ item.status ? item.status : '' }}
        </v-alert>
      </template>
      <template v-slot:item.visualizar="{ item }">
        <v-btn
          icon
          @click="openSolicitationMKTEditModal(item)"
        >
          <v-icon>
            {{ icons.mdiEyeOutline }}
          </v-icon>
        </v-btn>
      </template>
      <template
        v-slot:no-data
      >
        <div class="my-10">
          <NoDataInterfaceEditable
            title="Nenhum  registro encontrado!"
            subtitle="Por favor, verifique os filtros de pesquisa / Contacte a equipe de desenvolvimento."
          />
        </div>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showModalNewSolicitationMKT"
      persistent
      width="850px"
      transition="dialog-transition"
    >
      <ModalNewSolicitationMKT
        @close="showModalNewSolicitationMKT = false"
        @get-table-data="getTableData"
      />
    </v-dialog>
    <v-dialog
      id="ModalDoSolicitante"
      :key="ModalEditSolicitationMKTKey"
      v-model="showModalEditSolicitationMKT"
      width="1000px"
      transition="dialog-transition"
    >
      <ModalEditSolicitationMKT
        :item="solicitation"
        @close="showModalEditSolicitationMKT = false"
        @get-table-data="getTableData"
      />
    </v-dialog>
    <v-dialog
      v-model="showModalFilterMySolicitations"
      width="600px"
      transition="dialog-transition"
    >
      <ModalFilterMySolicitations
        ref="ModalFilterMySolicitationsRef"
        @close="showModalFilterMySolicitations = false"
        @filterData="handleFilterData"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { getVuetify } from '@/@core/utils'
import NoDataInterfaceEditable from '@/components/charts/NoDataInterfaceEditable.vue'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/plugins/axios'
import {
  mdiBullhornOutline,
  mdiClose,
  mdiCropSquare,
  mdiDraw,
  mdiExclamationThick,
  mdiEyeOutline,
  mdiFilterVariant,
  mdiImageOutline,
  mdiMagnify,
  mdiMessageOutline,
  mdiPlaylistPlus,
  mdiVideoOutline,
  mdiVoicemail,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import ModalEditSolicitationMKT from './components/modais/ModalEditSolicitationMKT.vue'
import ModalFilterMySolicitations from './components/modais/ModalFilterMySolicitations.vue'
import ModalNewSolicitationMKT from './components/modais/ModalNewSolicitationMKT.vue'

export default {
  components: {
    NoDataInterfaceEditable,
    ModalNewSolicitationMKT,
    ModalEditSolicitationMKT,
    ModalFilterMySolicitations,
  },
  data() {
    return {
      showModalFilterMySolicitations: false,
      showModalNewSolicitationMKT: false,
      showModalEditSolicitationMKT: false,
      icons: {
        mdiClose, mdiPlaylistPlus, mdiMagnify, mdiEyeOutline, mdiFilterVariant, mdiExclamationThick,
      },
      loadingTable: false,
      listOfFilteredItems: [],
      itemsTable: [],
      headers: [
        {
          text: 'N° SLM',
          value: 'slm',
          sortable: false,
          align: 'left',
        },
        {
          text: 'DATA DA SOLICITAÇÃO',
          value: 'created_at',
          sortable: false,
          align: 'left',
        },
        {
          text: 'ASSUNTO',
          value: 'subject',
          sortable: false,
          align: 'left',
        },
        {
          text: 'CATEGORIA',
          value: 'category',
          sortable: false,
          align: 'left',
        },
        {
          text: 'PREVISÃO DE ENTREGA',
          value: 'expected_delivery_date',
          sortable: false,
          align: 'left',
        },
        {
          text: 'RESPONSÁVEL',
          value: 'assigned_to_user.name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'STATUS',
          value: 'status',
          sortable: false,
          align: 'center',
        },
        {
          text: 'VISUALIZAR',
          value: 'visualizar',
          sortable: false,
          align: 'center',
        },
      ],
      filterInput: '',

      userProfile: {},

      ModalEditSolicitationMKTKey: false,
      isFiltered: false,
      solicitation: {},
    }
  },
  computed: {
    filterButtonColor() {
      if (!this.isDark) {
        return this.isFiltered ? 'success' : 'black'
      }

      return this.isFiltered ? 'success' : 'white'
    },
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },
  created() {
    this.getTableData()
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
  },
  methods: {
    clearFilters() {
      this.$refs.ModalFilterMySolicitationsRef.handleClearFilter()
    },
    handleFilterData(data) {
      this.listOfFilteredItems = data.data
      this.isFiltered = data.isFiltered
    },
    getInitials(user) {
      if (!user || !user.name) {
        return '-'
      }
      const names = user.name.split(' ')
      if (names.length === 1) {
        return names[0].charAt(0).toUpperCase()
      }
      const firstInitial = names[0].charAt(0).toUpperCase()
      const lastInitial = names[names.length - 1].charAt(0).toUpperCase()

      return firstInitial + lastInitial
    },
    async getTableData() {
      try {
        const res = await axiosIns.get('/api/v1/solicitations/marketing/get')
        this.itemsTable = res.data.data
        this.listOfFilteredItems = this.itemsTable
      } catch (error) {
        console.error('erro ao buscar os dados da tabela de solicitação de marketing:', error)
      }
    },
    returnIconOrColorBasedOnSubject(whichOne, subject) {
      if (whichOne === 'icon') {
        const subjectMap = {
          'AÇÃO DE VENDAS': mdiBullhornOutline,
          'BANNER/MATERIAL DIGITAL': mdiCropSquare,
          'PRODUÇÃO DE MATERIAL': mdiImageOutline,
          'PRODUÇÃO DE VÍDEO': mdiVideoOutline,
          'COMUNICAÇÃO INTERNA': mdiVoicemail,
          'MENSAGEM TEMPLATE': mdiMessageOutline,
          'ASSINATURA DE E-MAIL': mdiDraw,
        }

        const subjectData = subjectMap[subject]

        return subjectData || ''
      }

      if (whichOne === 'color') {
        const subjectColorMap = {
          'AÇÃO DE VENDAS': '#FFB400',
          'BANNER/MATERIAL DIGITAL': '#9A62FD',
          'PRODUÇÃO DE MATERIAL': '#56CA00',
          'PRODUÇÃO DE VÍDEO': '#FF4C51',
          'COMUNICAÇÃO INTERNA': '#EF70FF',
          'MENSAGEM TEMPLATE': '#FFE500',
          'ASSINATURA DE E-MAIL': '#16B1FF',
        }

        const subjectColor = subjectColorMap[subject]

        return subjectColor || '#000000'
      }

      console.error('Invalid value for whichOne:', whichOne)

      return null
    },
    getStatusColor(item = { status: '' }) {
      const { status } = item

      const statusColorMap = {
        'NÃO INICIADA': 'white',
        'EM ANDAMENTO': 'info',
        'EM EXECUÇÃO COM TERCEIRO': 'greenCyan',
        'ENVIADA PARA VALIDAÇÃO': 'warning',
        'ENVIADA AO SETOR DE COMPRAS': 'lightOrange',
        FINALIZADA: 'success',
      }

      return statusColorMap[status] ?? ''
    },
    formatToDayMonthYear(isoDate) {
      const date = new Date(isoDate)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()

      return `${day}/${month}/${year}`
    },
    formatDate(date) {
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
    openSolicitationMKTEditModal(solicitation) {
      this.generateNewModalEditKey()
      this.solicitation = solicitation
      this.showModalEditSolicitationMKT = true
    },
    generateNewModalEditKey() {
      this.ModalEditSolicitationMKTKey = Math.random()
    },
    openModalFilterMySolicitations() {
      this.showModalFilterMySolicitations = true
    },

  },
}
</script>

<style>
  .toBeDefined{
    font-weight: 400;
    font-style: italic;
  }
  .badge {
  margin-top: 16px;
  font-size: 13px;
  height: 40px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
