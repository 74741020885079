<template>
  <div class="container-main">
    <v-card class="banner">
      <v-img
        class="banner-img"
        src="@/assets/images/svg/shield-icon.svg"
        max-height="150"
        max-width="200"
        contain
      />

      <div class="container-title">
        <h1>Bem-vindo ao canal do diretor!</h1>
        <p>
          Aqui você pode registrar elogios, sugestões, reclamações ou denúncias. <br>
          <span style="display: flex; justify-content: center;">
            Mas não se preocupe, sua identidade será preservada.
          </span>
        </p>
      </div>

      <div class="pb-6">
        <v-img
          class="banner-img"
          src="@/assets/images/3d-characters/pose-fs-9.png"
          max-height="200"
          max-width="200"
          contain
        />
      </div>
    </v-card>

    <div class="w-full d-flex justify-center">
      <v-card
        class="container-message py-4"
      >
        <h2>Qual mensagem você quer enviar?</h2>
      </v-card>
    </div>

    <div class="w-full d-flex justify-center">
      <v-card
        class="card-form"
      >
        <v-form
          ref="form"
          class="container-form px-6"
          @submit.prevent
        >
          <div class="container-file">
            <div
              class="subject"
            >
              <v-select
                v-model="selectedSubject"
                :items="subject"
                :rules="[rules.required]"
                label="Assunto"
                outlined
                dense
              />
            </div>

            <v-file-input
              v-model="files"
              :prepend-inner-icon="icons.mdiPaperclip"
              label="Anexar arquivo (opcional)"
              prepend-icon=""
              clearable
              outlined
              multiple
              dense
            />
          </div>

          <v-textarea
            v-model="description"
            class="mt-2"
            label="Descrição"
            :rules="[rules.required]"
            height="200px"
            no-resize
            outlined
          />

          <div class="container-button">
            <v-btn
              color="info"
              width="120px"
              @click="sendFormData()"
            >
              <div
                v-if="!isLoading"
                class="container-inner-button"
              >
                <span class="text-sm">
                  Enviar
                </span>
                <v-img
                  :src="icons.arrowRight"
                  class="ml-2"
                />
              </div>

              <v-progress-circular
                v-else
                color="white"
                class="mx-6"
                indeterminate
              />
            </v-btn>

            <v-btn
              color="error"
              width="120px"
              @click="$router.push('/login'), resetForm()"
            >
              <span>
                Cancelar
              </span>
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </div>

    <v-card class="footer">
      <v-img
        src="@/assets/new-motoca-logo.png"
        max-height="25"
        max-width="83"
        contain
        class="mr-4"
      />

      <span></span>

      <v-img
        src="@/assets/images/svg/honda-fuji.svg"
        max-height="200"
        max-width="200"
        contain
      />
    </v-card>
  </div>
</template>

<script>
import arrowRight from '@/assets/images/svg/arrow-right.svg'
import iconCancel from '@/assets/images/svg/cancel-icon.svg'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiPaperclip } from '@mdi/js'

export default {
  mixins: [formatters, messages, utilsMixin],

  data() {
    return {
      files: [],
      subject: ['Elogio', 'Sugestão', 'Reclamação', 'Denúncia'],
      description: '',
      selectedSubject: '',

      isLoading: false,

      icons: {
        arrowRight,
        iconCancel,
        mdiPaperclip,
      },
    }
  },

  methods: {
    async sendFormData() {
      const urlFile = 'api/v1/director_channel/attachment/submit_files'
      const isValid = this.$refs.form.validate()

      if (!isValid) {
        this.showMessage({ title: 'Formulário inválido', text: 'Verifique os campos obrigatórios' })

        return
      }

      this.isLoading = true

      const body = {
        subject: this.selectedSubject,
        text: this.description,
        status: 'PENDENTE',
      }

      await axiosIns.post('api/v1/director_channel/director_channel/store', body).then(async response => {
        const { data } = response.data

        if (this.files.length > 0) {
          await this.sendFiles(urlFile, this.files, { id: data.id })
        }

        this.showMessage({ title: 'Sucesso', text: 'Mensagem enviada com sucesso', icon: 'success' })
        this.resetForm()
      })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    resetForm() {
      this.$refs.form.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
.container-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  h1, h2, p {
    font-weight: 500 !important;
    font-style: normal !important;
    line-height: normal !important;
  }

  p {
    font-weight: 500 !important;
    font-style: normal !important;
    line-height: normal !important;
  }
}

.banner {
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin-top: 80px;

  @media screen and (max-width: 885px) {
    justify-content: center;

    .banner-img {
      display: none;
    }
  }
}

.container-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  h1 {
    color: #BE77FF !important;
  }

  p {
    color: #D1CAE3 !important;
  }

  @media screen and (max-width: 560px) {
    h1 {
      text-align: center;
      font-size: 22px;
    }

    p, span {
      text-align: center;
      font-size: small;
      padding: 10px;
    }
  }
}

.container-message {
  display: flex;
  justify-content: center;
  width: 60%;

  h2 {
    color: #D1CAE3 !important;
    font-size: large;
  }

  @media screen and (max-width: 780px) {
    width: 100%;

    h2 {
      font-size: 16px;
    }
  }
}

.card-form {
  width: 60%;
  height: auto;
  padding-bottom: 10px;

  @media screen and (max-width: 780px) {
    width: 100%;
  }

  @media screen and (max-width: 400px) {
    padding-bottom: 20px;
  }
}

.container-form {
  margin: 10px;
  padding: 10px;

  @media screen and (max-width: 400px) {
    margin: 0px;
    padding: 0px;
  }
}

.container-file {
  display: flex;
  width: 100%;
  padding-top: 20px;
  gap: 20px;

  .subject {
    width: 30%;
  }

  @media screen and (max-width: 400px) {
    gap: 0px;
    flex-direction: column;

    .subject {
      width: 100%;
    }
  }
}

.container-button {
  display: flex;
  justify-content: flex-end;
  gap: 15px;

  @media screen and (max-width: 400px) {
    justify-content: center;
    align-items: center;
  }
}

.container-inner-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;

  span {
    border: solid 1px #ffff;
    height: 30px;
  }
}

</style>
