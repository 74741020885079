<template>
  <v-card class="px-6">
    <v-card-title class="d-flex justify-center">
      <v-spacer />

      <span>Editar estabilidade</span>

      <v-spacer />

      <v-icon @click="closeModal">
        {{ icons.mdiClose }}
      </v-icon>
    </v-card-title>

    <Separator label="Controle de estabilidade" />

    <div
      style="display: flex; flex-direction: column; margin-top: 12px;"
    >
      <div style="display: flex; gap: 10px; margin-top: 16px;">
        <v-autocomplete
          v-model="stability"
          :items="listStability"
          label="Tipo de estabilidade"
          class=""
          clearable
          outlined
          dense
        >
        </v-autocomplete>
        <v-menu
          ref="menuStart"
          v-model="menuStart"
          :close-on-content-click="false"
          :return-value.sync="startDate"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedStartDate"
              label="Data final"
              style="height: 62px"
              dense
              outlined
              readonly
              v-bind="attrs"
              :append-icon="icons.mdiCalendar"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            scrollable
            @change="(date) => { $refs.menuStart.save(date); startDate = date; }"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menuStart = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menuStart.save(startDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-menu
          ref="menuEnd"
          v-model="menuEnd"
          :close-on-content-click="false"
          :return-value.sync="endDate"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedEndDate"
              label="Data final"
              style="height: 62px"
              dense
              outlined
              readonly
              v-bind="attrs"
              :append-icon="icons.mdiCalendar"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            no-title
            scrollable
            @change="(date) => { $refs.menuEnd.save(date); endDate = date; }"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menuEnd = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menuEnd.save(endDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </div>

    <div style="display: flex; justify-content: flex-end; padding-bottom: 20px;">
      <v-btn
        outlined
        color="info"
        @click="handleEditStability()"
      >
        <v-progress-circular
          v-if="isLoadingEditStability"
          color="white"
          indeterminate
        >
        </v-progress-circular>
        <div v-else>
          <!-- <v-icon>
            {{ icons.mdiPlus }}
          </v-icon> -->
          Salvar
        </div>
      </v-btn>
    </div>
  </v-card>
</template>
<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiCalendar,
  mdiClockTimeEightOutline,
  mdiClose,
  mdiContentSave,
  mdiDeleteOutline,
  mdiHospital,
  mdiMotherHeart,
  mdiPencil,
  mdiPlus,
  mdiPlusCircleOutline,
} from '@mdi/js'
import moment from 'moment'
import 'moment/locale/pt-br'

export default {
  components: {
    Separator,
  },
  mixins: [formatters, messages],

  props: {
    dataId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoadingEditStability: false,
      icons: {
        mdiPencil,
        mdiClose,
        mdiPlus,
        mdiCalendar,
        mdiContentSave,
        mdiPlusCircleOutline,
        mdiMotherHeart,
        mdiHospital,
        mdiClockTimeEightOutline,
        mdiDeleteOutline,
      },
      userId: '',
      stability: '',
      listStability: ['CIPA', 'LICENÇA MATERNIDADE', 'ACIDENTE DE TRABALHO'],
      menuStart: false,
      menuEnd: false,
      startDate: '',
      endDate: '',

    }
  },
  computed: {
    formattedStartDate() {
      return this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : ''
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : ''
    },
  },
  created() {
    console.log(this.dataId)
    this.handleGetInfosStability()
  },
  methods: {
    async handleGetInfosStability() {
      const response = await axiosIns.get(`/api/v1/records/user_stability/edit/${this.dataId}`)

      this.stability = response.data.data.type
      this.startDate = response.data.data.start_date
      this.endDate = response.data.data.end_date
      this.userId = response.data.data.user_id
    },

    async handleEditStability() {
      this.isLoadingEditStability = true

      const body = {
        user_id: this.userId,
        type: this.stability,
        start_date: this.startDate,
        end_date: this.endDate,
      }

      try {
        await axiosIns.put(`/api/v1/records/user_stability/update/${this.dataId}`, body)

        this.showMessage({ title: 'Sucesso', text: 'Estabilidade editada', icon: 'success' })
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingEditStability = false

        this.updatedTable()
        this.closeModal()
      }
    },
    clearModal() {
      this.stability = ''
      this.startDate = ''
      this.endDate = ''

      // this.dataId = ''
    },
  },
}
</script>
