import {
  mdiAccountMultipleOutline,
  mdiArrowCollapseDown,
  mdiBallot,
  mdiBriefcaseAccount,
  mdiCardTextOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiFileDocumentEditOutline,
  mdiRocketLaunchOutline,
  mdiViewDashboardOutline,
} from '@mdi/js'

import localStorageSlim from 'localstorage-slim'

const userProfile = localStorageSlim.get('userProfile', { decrypt: true })
const hierarchicalProfile = userProfile?.hierarchical_profile

const personalDepartment = [
  {
    subheader: 'Departamento Pessoal',
  },

  // {
  //   title: 'Vales',
  //   icon: mdiTicketAccount,
  //   children: [
  //     {
  //       title: 'Registrar | Acompanhar',
  //       to: 'personal-department-home',
  //       ActiveTabColor: '#0BC000',
  //       ActiveTabColor2: '#42aa3c',
  //       hidden: false,
  //       icon: mdiTicketConfirmation,
  //     },
  //   ],
  // },
  {
    title: 'DP',
    icon: mdiBriefcaseAccount,
    children: [],
  },
]

if (userProfile) {
  const profile = userProfile.profile?.profile

  if (profile === 'Controladoria') {
    personalDepartment.push({
      title: 'Controladoria',
      icon: mdiBallot,
      children: [
        {
          title: 'Conferencia Folha',
          icon: mdiCardTextOutline,
          to: 'report-sheet',
          hidden: true,
          ActiveTabColor: '#0BC000',
          ActiveTabColor2: '#42aa3c',
        },
      ],
    })
  }

  if (profile === 'Diretor') {
    personalDepartment.unshift({
      title: 'Dashboard',
      to: 'personal-department-dash',
      icon: mdiViewDashboardOutline,
      ActiveTabColor: '#0BC000',
      ActiveTabColor2: '#42aa3c',
    })
  }

  if (hierarchicalProfile === 'nivel_2' && profile !== 'Departamento Pessoal') {
    // personalDepartment[2]?.children.unshift({
    //   title: 'Digitação Folha',
    //   to: 'personal-department-sheetTyping',
    //   icon: mdiFileDocumentEditOutline,
    //   ActiveTabColor: '#0BC000',
    //   ActiveTabColor2: '#42aa3c',
    // })

    personalDepartment.push({
      title: 'EXPORTAÇÕES',
      icon: mdiArrowCollapseDown,
      children: [
        {
          title: 'Exportação Folha',
          to: 'personal-department-export-commissions',
          icon: '',
          ActiveTabColor: '#0BC000',
          ActiveTabColor2: '#42aa3c',
        },
      ],
    })
  }

  if (hierarchicalProfile === 'nivel_2') {
    personalDepartment.push({
      title: 'DIGITAÇÃO FOLHA',
      to: 'personal-department-sheetTyping',
      icon: mdiFileDocumentEditOutline,
      ActiveTabColor: '#0BC000',
      ActiveTabColor2: '#42aa3c',
    })
  }

  if (profile === 'Departamento Pessoal') {
    personalDepartment.push(
      {
        title: 'EXPORTAÇÕES',
        icon: mdiArrowCollapseDown,
        children: [
          {
            title: 'Exportação Vales',
            to: 'personal-department-dp',
            icon: '',
            ActiveTabColor: '#0BC000',
            ActiveTabColor2: '#42aa3c',
          },
          {
            title: 'Exportação Folha',
            to: 'personal-department-export-commissions',
            icon: '',
            ActiveTabColor: '#0BC000',
            ActiveTabColor2: '#42aa3c',
          },
        ],
      },
    )
    personalDepartment.push(
      {
        title: 'FUNCIONÁRIOS',
        to: 'users-dp',
        icon: mdiAccountMultipleOutline,
        ActiveTabColor: '#0BC000',
        ActiveTabColor2: '#42aa3c',
      },
      {
        title: 'FICHA FINANCEIRA',
        to: 'financial-statement',
        icon: mdiCurrencyUsd,
        ActiveTabColor: '#0BC000',
        ActiveTabColor2: '#42aa3c',
      },
      {
        title: 'LANÇAMENTOS',
        to: 'personal-department-dp-now',
        icon: mdiRocketLaunchOutline,
        ActiveTabColor: '#0BC000',
        ActiveTabColor2: '#42aa3c',
      },
    )
    personalDepartment.push(
      {
        title: 'FERRAMENTAS',
        icon: mdiCogOutline,
        children: [
          {
            title: 'Eventos',
            to: 'personal-department-dp-events',
            icon: '',
            ActiveTabColor: '#0BC000',
            ActiveTabColor2: '#42aa3c',
          },
          {
            title: 'Vales',
            to: 'personal-department-dp-types',
            icon: '',
            ActiveTabColor: '#0BC000',
            ActiveTabColor2: '#42aa3c',
          },
        ],
      },
    )
  }

  if (profile === 'Recursos Humanos') {
    personalDepartment.push(
      {
        title: 'FICHA FINANCEIRA',
        to: 'financial-statement',
        icon: mdiCurrencyUsd,
        ActiveTabColor: '#0BC000',
        ActiveTabColor2: '#42aa3c',
      },
      {
        title: 'FUNCIONÁRIOS',
        to: 'users-dp',
        icon: mdiAccountMultipleOutline,
        ActiveTabColor: '#0BC000',
        ActiveTabColor2: '#42aa3c',
      },
    )
  }
}

export default personalDepartment
