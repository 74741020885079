<template>
  <v-card>
    <div
      class=" d-flex align-center justify-center"
    >
      <v-card-title
        primary-title
        class=""
        style=" margin-left: 6em;"
      >
        Novo Registro
      </v-card-title>
      <v-icon
        size="30"
        style=" margin-left: 3em;"
        @click="closeModal(), resetForm()"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </div>
    <v-card-text>
      <separator label="Registrar consultor"></separator>
      <v-form
        ref="newRegisterForm"
      >
        <v-text-field
          v-model="vendorCpf"
          :rules="[rules.required]"
          label="CPF do consultor"
          class=" mt-7"
          dense
          outlined
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="error"
        :disabled="isLoading"
        @click="closeModal(), resetForm()"
      >
        Cancelar
      </v-btn>

      <v-btn
        color="info"
        :disabled="isLoading"
        @click="addNewVendor"
      >
        <span v-if="!isLoading">Registrar</span>

        <v-progress-circular
          v-else
          color="success"
          indeterminate
        >
        </v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mdiClose,
} from '@mdi/js'

import formatters from '@/plugins/formattersMixin1'
import axiosNewInstance from '@/plugins/newAxiosInstance'
import messages from '@/plugins/showMessageMixin'
import Separator from '../../../../components/Separator.vue'

export default {
  name: 'ModalNewRegister',
  components: {
    Separator,
  },
  mixins: [messages, formatters],
  data() {
    return {
      icons: {
        mdiClose,
      },
      isLoading: false,

      vendorCpf: '',
    }
  },
  methods: {
    async addNewVendor() {
      this.isLoading = true
      const body = {
        vendor_cpf: this.vendorCpf,
      }
      try {
        await axiosNewInstance.post('/api/v1/integration/cnh_parameter/store/', body)
        this.isLoading = false
        this.showMessage({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Colaborador adicionado com sucesso!',
          timer: 2500,
        })

        this.clearModal()
      } catch (error) {
        this.isLoading = false
        if (error.message === 'Identificamos que este consultor já consta em nossa base de dados.') {
          const result = await this.$swal({
            icon: 'warning',
            title: 'Aviso!',
            text: 'Identificamos que este consultor já consta em nossa base de dados. Gostaria de prosseguir com a recuperação dos dados?',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não',
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#dc3545',
          })
          if (result.isConfirmed) {
            this.restoreVendor(result)
            this.clearModal()
          }
        } else {
          this.$swal({
            icon: 'error',
            title: 'Erro ao carregar os dados!',
            showConfirmButton: false,
            text: error,
            timer: 5000,
          })
          this.clearModal()
        }
      } finally {
        this.closeModal()
      }
    },
    async restoreVendor(result) {
      this.isLoading = true
      const body = {
        vendor_cpf: this.vendorCpf,
        restore: result,
      }
      try {
        await axiosNewInstance.post('/api/v1/integration/cnh_parameter/store/', body)
        this.isLoading = false
        this.showMessage({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Os dados do consultor foram recuperados.',
          timer: 2500,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao carregar os dados!',
          showConfirmButton: false,
          text: error,
          timer: 5000,
        })
      }
    },
    closeModal() {
      this.$emit('close')
    },
    clearModal() {
      this.vendorCpf = ''
      this.resetForm()
      this.closeModal()
      this.$emit('isFilterTrue', false)
    },

    resetForm() {
      this.$refs.newRegisterForm.reset()
    },
  },
}
</script>
