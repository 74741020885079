<template>
  <v-card class="px-12">
    <div style="width: 100%; position: relative;">
      <h3
        style="
          font-weight: 600;
          font-size: 18px;
          text-align: center;
          padding-top: 40px;
        "
      >
        👋 Olá, chegou a hora validar sua solicitação!
      </h3>
      <v-img
        style="position: absolute; left: 25px; top: 20px;"
        height="70"
        width="56"
        src="@/assets/images/svg/mao-na-lampada.svg"
      ></v-img>
      <v-btn
        icon
        style="position: absolute; right: -20px; top: 25px;"
        @click="closeModal"
      >
        <v-icon size="30">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>

    <div
      class="d-flex py-10 mt-10"
      :class="selectedButton === 'approved' ? 'validated' : 'not-validated'"
    >
      <div
        class="d-flex"
      >
        <v-img
          v-if="selectedButton === 'approved'"
          height="187"
          width="387"
          src="@/assets/images/svg/validation-answer-positive.png"
        ></v-img>
        <v-img
          v-else-if="selectedButton === 'change'"
          height="188"
          width="307"
          src="@/assets/images/svg/validation-answer-negative.png"
        ></v-img>
        <v-img
          v-else
          height="103"
          width="122"
          src="@/assets/images/svg/validation-element.png"
        ></v-img>
      </div>
    </div>

    <div
      style="width: 100%; justify-content: center; gap: 12px; align-items: center;"
      class="d-flex pb-8"
    >
      <v-tooltip
        top
        color="success"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            outlined
            :color="selectedButton === 'approved' ? 'success' : ''"
            v-bind="attrs"
            v-on="on"
            @click="handleClick('approved')"
          >
            <v-icon
              size="25"
              class="p-2"
              :style="selectedButton === 'approved' ? iconStyles.red : ''"
            >
              {{ selectedButton === 'approved' ? icons.mdiHeart : icons.mdiHeartOutline }}
            </v-icon>
          </v-btn>
        </template>
        <span
          style="color: white; font-weight: 600; font-size: 13px;"
        >Aprovado!</span>
      </v-tooltip>
      <v-tooltip
        top
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            outlined
            :color="selectedButton === 'change' ? 'primary' : ''"
            v-bind="attrs"
            v-on="on"
            @click="handleClick('change')"
          >
            <v-icon
              size="25"
              class="p-2"
              :style="selectedButton === 'change' ? iconStyles.blue : ''"
            >
              {{ selectedButton === 'change' ? icons.mdiPencil : icons.mdiPencilOutline }}
            </v-icon>
          </v-btn>
        </template>
        <span
          style="color: white; font-weight: 600; font-size: 13px;"
        >Quero alterações</span>
      </v-tooltip>
    </div>

    <!-- FEATURE DE COMENTÁRIO -->
    <div
      v-if="!!selectedButton"
    >
      <div
        class="d-flex"
        style="gap: 12px; width: 100%; justify-content: end;"
      >
        <div
          v-if="!showLinkInput"
          class="comment-actions"
          @click="showLinkInput = !showLinkInput"
        >
          <v-icon>
            {{ icons.mdiLink }}
          </v-icon>
          <span style="font-size: 13px;">Inserir link</span>
        </div>
        <div
          v-if="!showAttachmentInput"
          class="comment-actions"
          @click="showAttachmentInput = !showAttachmentInput"
        >
          <v-icon size="20">
            {{ icons.mdiPaperclip }}
          </v-icon>
          <span style="font-size: 13px;">Inserir anexo</span>
        </div>
      </div>
      <v-textarea
        v-model="comment"
        label="Adicionar comentário"
        rows="3"
        class="mt-3"
        dense
        outlined
      ></v-textarea>
      <div
        v-if="showLinkInput"
        class="d-flex"
      >
        <v-text-field
          v-model="link"
          label="Adicionar link (opcional)"
          outlined
          dense
        ></v-text-field>
        <v-btn
          icon
          color="error"
          @click="handleClearLink"
        >
          <v-icon>{{ icons.mdiTrashCan }}</v-icon>
        </v-btn>
      </div>
      <div
        v-if="showAttachmentInput"
        class="d-flex flex-column"
      >
        <div
          class="d-flex"
          style="justify-content: end; margin-right: 42px;"
        >
          <v-tooltip
            top
            :color="'#1E1E1EfD'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="16"
                v-bind="attrs"
                class="mb-2"
                v-on="on"
              >
                {{ icons.mdiHelpCircle }}
              </v-icon>
            </template>
            <span
              style="color: white; font-weight: 500; font-size: 13px;"
            >Arquivos aceitos somente nos formatos <br> JPEG, JPG, PNG, SVG e PDF, com <br> tamanho máximo de 15MB.</span>
          </v-tooltip>
        </div>
        <div
          class="d-flex"
          style="gap: 5px;"
        >
          <v-file-input
            v-model="attachment"
            :prepend-inner-icon="icons.mdiPaperclip"
            multiple
            small-chips
            clearable
            outlined
            dense
            prepend-icon=""
            label="Adicionar arquivo (opcional)"
            accept=".png, .jpg, .jpeg, .pdf"
          ></v-file-input>
          <v-btn
            icon
            color="error"
            @click="handleClearAttachmentInput"
          >
            <v-icon>{{ icons.mdiTrashCan }}</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        class="pb-7 d-flex"
      >
        <v-btn
          color="error"
          :disabled="isPending"
          @click="closeModal"
        >
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!comment || isPending"
          color="primary"
          @click="sendComment"
        >
          <span v-if="!isPending">ENVIAR</span>
          <v-progress-circular
            v-else
            indeterminate
            color="info"
          ></v-progress-circular>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import axiosFileApi from '@/plugins/axiosFileApi'
import {
  mdiClose,
  mdiHeart,
  mdiHeartOutline,
  mdiHelpCircle,
  mdiLink,
  mdiPaperclip,
  mdiPencil,
  mdiPencilOutline,
  mdiTrashCan,
} from '@mdi/js'

export default {
  name: 'ModalRequesterValidation',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiClose,
        mdiHeartOutline,
        mdiHeart,
        mdiPencilOutline,
        mdiPencil,
        mdiLink,
        mdiPaperclip,
        mdiTrashCan,
        mdiHelpCircle,
      },
      selectedButton: null,
      buttonStyles: {
        red: "background-color: '#56CA00';",
        blue: "background-color: '#0085FF';",
      },
      iconStyles: {
        white: 'color: white;',
        red: 'color: #56CA00;',
        blue: 'color: #0085FF;',
      },
      comment: '',
      link: '',
      attachment: null,

      isPending: false,
      showAttachmentInput: false,
      showLinkInput: false,
    }
  },
  methods: {
    handleClearAttachmentInput() {
      this.attachment = null
      this.showAttachmentInput = false
    },
    handleClearLink() {
      this.link = ''
      this.showLinkInput = false
    },
    handleClick(button) {
      this.selectedButton = button
    },
    async sendComment() {
      this.isPending = true
      try {
        const commentBody = {
          marketing_solicitations_id: this.item.marketing_solicitations_id,
          type: 'comment',
          comment: this.comment,
          link: this.link,
        }

        const { data } = await axiosIns.post(
          '/api/v1/solicitations/marketing_solicitation_history/store',
          commentBody,
        )

        const commentId = data.data.id

        if (this.attachment && this.attachment.length > 0) {
          const formData = new FormData()

          this.attachment.forEach(file => {
            formData.append('files[]', file)
          })

          formData.append('id', commentId)

          await axiosFileApi.post(
            '/api/v1/solicitations/marketing_comment_attachment/store',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          )
        }
      } catch (error) {
        console.error('Erro ao adicionar comentário ou anexos:', error)
      }
      try {
        this.isPending = true
        await axiosIns.put(
          `/api/v1/solicitations/marketing_solicitation_history/update/${this.item.id}`,
          {
            need_validation: false,
            validation: this.selectedButton === 'approved' ? 'APPROVED' : 'CHANGE',
          },
        )
      } catch (error) {
        console.error('Erro ao fazer update do campo "need_validation" no comentário que precisa de validação:', error)
      }
      try {
        const body = {
          has_been_validated: true,
        }
        await axiosIns.put(`/api/v1/solicitations/marketing/update/${this.item.marketing_solicitations_id}`, body)
      } catch (error) {
        console.error('Erro ao fazer update do campo "has_been_validated" no comentário que precisa de validação:', error)
      }
      this.isPending = false
      this.$emit('getHistory')
      this.closeModal()
    },
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.validated {
  justify-content: center;
  align-items: center;
}
.not-validated {
  justify-content: center;
  align-items: center;
}
.comment-actions {
  gap: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease;
}
.comment-actions:hover {
  opacity: 0.8;
}
</style>
